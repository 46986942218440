import { Box, Flex } from '@chakra-ui/react';
import {
	PathsApiV1AssignmentsGetParametersQueryReferenceType,
	PathsApiV1AssignmentsGetParametersQueryType,
	SchemaAssignedContentResponseDto,
} from '@jam/api-sdk/api';
import { ReactElement } from 'react';
import { StoryAssignment } from './StoryAssignment';
import { TrackAssignment } from './TrackAssignment';
import { Text } from '@jam/front-library';

const LoadingState = () => {
	return <div role="placeholder">Loading...</div>;
};

type VerticalAssignmentListProps = {
	contentAllocations?: SchemaAssignedContentResponseDto[];
	userId?: string;
	renderStoryCardButton?: (storyId: string) => ReactElement;
};

export const VerticalAssignmentList = ({
	contentAllocations,
	userId,
	renderStoryCardButton,
}: VerticalAssignmentListProps) => {
	if (!contentAllocations) {
		return <LoadingState />;
	}

	return (
		<>
			<Flex direction="column" gap="6">
				{contentAllocations.map((contentAllocation, index) => (
					<Flex key={contentAllocation.id} direction="row">
						<Flex direction="column" w="10%" maxW="120px">
							<Text variant="cards-h1">{index + 1}</Text>
							<Text variant="label-bold" color="gray.400">
								{contentAllocation.type ===
								PathsApiV1AssignmentsGetParametersQueryType.refresher
									? 'Refresher'
									: 'Assignment'}
							</Text>
							<Box
								position="relative"
								mt={1}
								ml={1}
								width="2px" // Divider width
								height="100%" // Stretches to fill the container height
								backgroundImage="repeating-linear-gradient(var(--chakra-colors-gray-400) 0, var(--chakra-colors-gray-400) 6px, transparent 6px, transparent 16px)" // Adjust dot and gap sizes
								backgroundSize="2px 16px" // Width of dots and spacing
								backgroundRepeat="repeat-y" // Repeat the pattern vertically
								color="red.100"
							/>
						</Flex>
						<Box w="full">
							{contentAllocation.reference.type ===
							PathsApiV1AssignmentsGetParametersQueryReferenceType.journey ? (
								<TrackAssignment
									key={contentAllocation.id}
									userId={userId}
									contentAllocation={contentAllocation}
									journeyId={contentAllocation.reference.id}
									renderStoryButton={renderStoryCardButton}
								/>
							) : (
								<StoryAssignment
									key={contentAllocation.id}
									dueDate={
										contentAllocation.dueDate
											? new Date(contentAllocation.dueDate)
											: undefined
									}
									storyId={contentAllocation.reference.id}
									userId={userId}
									renderStoryButton={renderStoryCardButton}
								/>
							)}
						</Box>
					</Flex>
				))}
			</Flex>
		</>
	);
};
