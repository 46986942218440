import { createApi } from '@reduxjs/toolkit/query/react';
import { buildBaseQuery } from './buildBaseQuery';
import {
	SchemaCommentPaginatedResponseDto,
	SchemaCommentResponseDto,
	SchemaCreateCommentRequestDto,
	SchemaUpdateCommentRequestBodyDto,
} from '@jam/api-sdk/api';

const api = createApi({
	reducerPath: 'comment-api',
	tagTypes: ['comments'],
	baseQuery: buildBaseQuery('/api/v1'),
	endpoints: (builder) => ({
		createComment: builder.mutation<
			SchemaCommentResponseDto,
			SchemaCreateCommentRequestDto
		>({
			query: (req) => ({
				url: 'comments',
				method: 'POST',
				body: req,
			}),
			invalidatesTags: [{ type: 'comments', id: 'LIST' }],
		}),
		getComment: builder.query<SchemaCommentResponseDto, { commentId: string }>({
			query: ({ commentId }) => `comments/${commentId}`,
			providesTags: (result) =>
				result ? [{ type: 'comments', id: result.id }] : [],
		}),
		findComments: builder.query<
			SchemaCommentPaginatedResponseDto,
			{ referenceId: string; page: number; limit: number }
		>({
			query: ({ referenceId, page, limit }) =>
				`comments?referenceId=${referenceId}&page=${page}&limit=${limit}`,
			providesTags: (result) =>
				result
					? [
							...result.data.map(({ id }) => ({
								type: 'comments' as const,
								id,
							})),
							{ type: 'comments', id: 'LIST' },
						]
					: [{ type: 'comments', id: 'LIST' }],
		}),
		editComment: builder.mutation<
			SchemaCommentResponseDto,
			{ commentId: string } & SchemaUpdateCommentRequestBodyDto
		>({
			query: ({ commentId, content }) => ({
				url: `comments/${commentId}`,
				method: 'PATCH',
				body: { content },
			}),
			invalidatesTags: (result, error, { commentId }) => [
				{ type: 'comments', id: commentId },
			],
		}),
		deleteComment: builder.mutation<void, { commentId: string }>({
			query: ({ commentId }) => ({
				url: `comments/${commentId}`,
				method: 'DELETE',
			}),
			invalidatesTags: (result, error, { commentId }) => [
				{ type: 'comments', id: commentId },
				{ type: 'comments', id: 'LIST' },
			],
		}),
	}),
});

export const {
	useCreateCommentMutation,
	useGetCommentQuery,
	useFindCommentsQuery,
	useEditCommentMutation,
	useDeleteCommentMutation,
} = api;
export default api;
