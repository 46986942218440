import { Box, Flex, Skeleton } from '@chakra-ui/react';
import { Section, SkeletonText } from '@jam/front-library';
import { useAuthInfo } from '@propelauth/react';
import { useTranslation } from 'react-i18next';
import { StartStoryButton } from '../../../common/components/story-detail/buttons/StartStoryButton';
import { StoryCard } from '../../../common/components/story-detail/StoryCard';
import { useGetNextStories } from '../hooks/useGetNextStories';
import { SeeAllButton } from '../../../common/components/buttons/SeeAllButton';

const LoadingState = () => {
	return (
		<Flex role="placeholder" direction={'column'} gap="4" h={'400px'}>
			<Box maxW="300px">
				<SkeletonText noOfLines={1} height={'30px'} />
			</Box>
			<Flex gap="4" alignItems={'center'}>
				<Skeleton borderRadius={'8px'} h="350px" flex={1} />
				<Skeleton borderRadius={'8px'} h="350px" flex={1} />
				<Skeleton borderRadius={'8px'} h="350px" flex={1} />
			</Flex>
		</Flex>
	);
};

export const KeepLearning = () => {
	const { t } = useTranslation();
	const { upcomingStories, isLoading } = useGetNextStories();
	const { user } = useAuthInfo();

	if (isLoading) {
		return <LoadingState />;
	}

	const keepLearningStories = (upcomingStories ?? []).slice(1, 4);

	if (keepLearningStories.length === 0) {
		return null;
	}

	//TODO: Migrate button
	return (
		<Box>
			<Section
				title={t('keep_learning').toString()}
				actions={<SeeAllButton href={'/progress?activeTab=1'} />}
			>
				<Flex
					direction="row"
					gap={4}
					justifyContent={
						(keepLearningStories?.length ?? 0) >= 3
							? 'space-between'
							: 'flex-start'
					}
				>
					{keepLearningStories.map((story) => (
						<StoryCard
							key={story.id}
							userId={user?.userId}
							story={story}
							renderStoryButton={(storyId) => (
								<StartStoryButton
									label="start_mission"
									storyId={storyId}
									origin="keep_learning"
								/>
							)}
						/>
					))}
				</Flex>
			</Section>
		</Box>
	);
};
