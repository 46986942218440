import { SchemaCreateVapiConfigRequestDto } from '@jam/api-sdk/api';
import { createApi } from '@reduxjs/toolkit/query/react';
import { buildBaseQuery } from './buildBaseQuery';

const api = createApi({
	reducerPath: 'conversation-api',
	tagTypes: ['conversation'],
	baseQuery: buildBaseQuery('/api/v1/conversation'),
	endpoints: (builder) => ({
		createVapiConfig: builder.mutation<
			object,
			SchemaCreateVapiConfigRequestDto
		>({
			query: (req) => ({
				url: 'vapi-config',
				method: 'POST',
				body: req,
			}),
		}),
	}),
});

export const { useCreateVapiConfigMutation } = api;
export default api;
