import { SchemaStoryResponseDto } from '@jam/api-sdk/api';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
	useCreateSessionMutation,
	useDeleteSessionMutation,
} from '../../../redux/api/sessionApi';
import { useAppSelector } from '../../../redux/hooks';
import {
	selectCurrentCallStatus,
	setCurrentCallStatus,
} from '../../../redux/slice';
import { CallStatus } from '../components/CallStatus';
import { SimulateSalesAgentUrlParameter } from '../types/SimulateSalesAgent';
import { Metadata } from '../types/VapiClientEventMessage';
import { useVapi } from './useVapi';

export const useRolePlay = ({ story }: { story: SchemaStoryResponseDto }) => {
	const {
		start: callStart,
		stop: callStop,
		error: callError,
		callDurationSec,
	} = useVapi();
	const dispatch = useDispatch();
	const [sessionId, setSessionId] = useState<string | null>(null);
	const [deleteSession] = useDeleteSessionMutation();
	const [createCall] = useCreateSessionMutation();
	const [error, setError] = useState<string | null>(null);
	const callStatus = useAppSelector(selectCurrentCallStatus);
	const urlParams = new URLSearchParams(window.location.search);
	const simulateSalesAgent =
		urlParams.get(SimulateSalesAgentUrlParameter) === 'true';

	const start = useCallback(async () => {
		try {
			dispatch(setCurrentCallStatus(CallStatus.loading));

			const payload = await createCall({
				storyId: story.id,
			}).unwrap();

			const assistantReferenceId: string = payload.call.assistantReferenceId;

			await callStart(assistantReferenceId, {
				metadata: {
					simulateSalesAgent,
				} as Metadata,
			});

			setSessionId(payload.id);
		} catch (err) {
			dispatch(setCurrentCallStatus(CallStatus.finished));
			setError((err as Error).message);
		}
	}, [story]);

	useEffect(() => {
		setError(callError);
		setSessionId(null);
	}, [callError]);

	const cleanup = async (): Promise<void> => {
		if (
			!sessionId ||
			callStatus === CallStatus.started ||
			callStatus === CallStatus.loading
		) {
			return;
		}
		await deleteSession(sessionId);
		setSessionId(null);
	};

	return {
		start,
		stop: callStop,
		deleteSession: cleanup,
		sessionId,
		error,
		callDurationSec,
	};
};
