import { Button, Tooltip } from '@jam/front-library';
import { Play } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useNextStep } from '../hooks/useNextStep';
import { Box } from '@chakra-ui/react';

type NextStoryProps = {
	isPrimary: boolean;
};

export const NextStory = ({ isPrimary }: NextStoryProps) => {
	const nextStep = useNextStep();
	const navigate = useNavigate();
	const { t } = useTranslation(['call', 'story', 'common']);

	const nextButtonTooltip = isPrimary
		? t('next_mission-tooltip-completed')
		: t('next_mission-tooltip');

	if (!nextStep) {
		return null;
	}

	const variant = isPrimary ? 'primary' : 'secondary';

	return (
		<Box>
			<Tooltip showArrow disabled={!nextStep} content={nextButtonTooltip}>
				<Button
					size="full"
					variant={variant}
					onClick={() => navigate(nextStep, { replace: true })}
					icon={nextStep ? <Play weight="bold" size={14} /> : undefined}
					iconPosition="end"
				>
					{t('next_mission')}
				</Button>
			</Tooltip>
		</Box>
	);
};
