import { Stack } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
	SchemaStoryResponseDto,
	SchemaUserResponseDto,
} from '@jam/api-sdk/api';
import {
	Button,
	DialogBackdrop,
	DialogBody,
	DialogCloseTrigger,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogRoot,
	DialogTitle,
	Field,
	Input,
	Select,
	Text,
	Textarea,
} from '@jam/front-library';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z, ZodType } from 'zod';
import { useGetStoriesQuery } from '../../redux/api/cmsApi';
import { useGetManageableUsersQuery } from '../../redux/api/crmApi';
import { useAuthInfo } from '@propelauth/react';

export type UploadTranscriptModalProps = {
	isOpen: boolean;
	onSubmit: (data: TranscriptData) => void | Promise<void>;
	onCancel: () => void;
};

export type TranscriptData = {
	transcript: string;
	userId: string;
	storyId: string;
	meetingName: string;
};

const TranscriptDataSchema: ZodType<TranscriptData> = z.object({
	transcript: z.string().min(10).max(100000),
	userId: z.string().uuid(),
	storyId: z.string(),
	meetingName: z.string().min(1),
});

const useScorecardSelection = () => {
	const [scorecards, setScorecards] = useState<
		{ value: string; label: string }[]
	>([]);
	const { data: stories } = useGetStoriesQuery();

	useEffect(() => {
		if (stories) {
			const uniqueScorecards = new Map<string, SchemaStoryResponseDto>();
			stories.forEach((story) => {
				uniqueScorecards.set(story.scorecard.id, story);
			});
			const options = Array.from(uniqueScorecards, ([, value]) => value)
				.map((story) => ({
					value: story.id,
					label: story.scorecard.title,
				}))
				.sort((a, b) => a.label.localeCompare(b.label));
			setScorecards(options);
		}
	}, [stories]);

	return scorecards;
};

export const UploadTranscriptModal = ({
	isOpen,
	onCancel,
	onSubmit,
}: UploadTranscriptModalProps) => {
	const contentRef = useRef<HTMLDivElement>(null);
	const { t } = useTranslation('real-call');
	const { data: users } = useGetManageableUsersQuery();
	const { user } = useAuthInfo();
	const scorecardSelection = useScorecardSelection();
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors, isSubmitting },
		setValue,
		control,
	} = useForm<TranscriptData>({
		resolver: zodResolver(TranscriptDataSchema),
	});

	const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		if (file) {
			const reader = new FileReader();
			reader.onload = (e) => {
				const content = e.target?.result as string;
				setValue('transcript', content, { shouldDirty: true });
			};
			reader.readAsText(file);
		}
	};

	const onSubmitHandler = async (data: TranscriptData) => {
		await onSubmit(data);
		reset();
	};

	if (!users || !user) return null;

	const usersHasCurrentUser =
		users.length > 0 &&
		users.find((manageableUser) => manageableUser.id === user.userId) !==
			undefined;

	const allUsers = usersHasCurrentUser
		? users
		: users.concat({
				id: user.userId,
				firstName: user.firstName,
				lastName: user.lastName,
				email: user.email,
			} as SchemaUserResponseDto);

	return (
		<DialogRoot
			size="lg"
			open={isOpen}
			onOpenChange={({ open }) => {
				if (!open) {
					reset();
					onCancel();
				}
			}}
		>
			<DialogBackdrop />
			<DialogContent ref={contentRef}>
				<DialogHeader>
					<DialogTitle textStyle="h2">
						{t('upload-transcript-title')}
					</DialogTitle>
					<DialogCloseTrigger />
				</DialogHeader>
				<DialogBody pb={6}>
					<Stack gap={4}>
						<Field label={t('upload-file-form')}>
							<Input
								data-testid="upload-file-form"
								type="file"
								accept=".txt,.json,.csv"
								onChange={handleFileUpload}
							/>
						</Field>
						<Field
							invalid={!!errors.transcript}
							label={t('transcript')}
							errorText={errors.transcript?.message}
						>
							<Textarea {...register('transcript')} lineClamp={10} />
						</Field>
						<Controller
							control={control}
							name="userId"
							render={({ field }) => (
								<Field
									label={t('select-user')}
									invalid={!!errors.userId}
									errorText={errors.userId?.message}
								>
									<Select
										portalRef={contentRef}
										name={field.name}
										value={field.value}
										onValueChange={(value) => field.onChange(value)}
										placeholder={t('select-user') ?? 'Select user'}
										items={allUsers.map((manageableUser) => ({
											value: manageableUser.id,
											label: `${manageableUser.firstName} ${manageableUser.lastName}`,
										}))}
									/>
								</Field>
							)}
						/>
						<Controller
							control={control}
							name="storyId"
							render={({ field }) => (
								<Field
									label={t('select-score-card')}
									invalid={!!errors.storyId}
									errorText={errors.storyId?.message}
								>
									<Select
										portalRef={contentRef}
										name={field.name}
										value={field.value}
										onValueChange={(value) => {
											const scorecard = scorecardSelection.find(
												(s) => s.value === value
											);

											field.onChange(value);

											if (scorecard) {
												setValue('meetingName', scorecard.label, {
													shouldDirty: true,
													shouldTouch: true,
													shouldValidate: true,
												});
											}
										}}
										placeholder={t('select-score-card') ?? 'Select score card'}
										items={scorecardSelection}
									/>
								</Field>
							)}
						/>
						<Field
							label={t('meeting-name')}
							invalid={!!errors.meetingName}
							errorText={errors.meetingName?.message}
						>
							<Input
								data-testid="meeting-name"
								{...register('meetingName')}
								placeholder={t('meeting-name-placeholder') ?? ''}
							/>
						</Field>
					</Stack>
				</DialogBody>
				<DialogFooter>
					<Button
						id="add-user-button"
						type="submit"
						loading={isSubmitting}
						// disabled={!isValid}
						onClick={() => {
							void handleSubmit(onSubmitHandler)();
						}}
					>
						{t('upload')}
					</Button>
					<Button
						variant="tertiary"
						onClick={() => {
							onCancel();
							reset();
						}}
					>
						<Text>{t('cancel')}</Text>
					</Button>
				</DialogFooter>
			</DialogContent>
		</DialogRoot>
	);
};
