import { Flex } from '@chakra-ui/react';
import { AppSelectProps, Select } from '@jam/front-library';

type CustomSelectProps = AppSelectProps & {
	limit?: number;
};

export const UserMultiSelect = ({ ...props }: CustomSelectProps) => {
	return (
		<Flex direction="row" gap={4}>
			<Select {...props} multiple closeOnSelect={false} minWidth={'350px'} />
		</Flex>
	);
};
