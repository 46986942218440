import { Flex } from '@chakra-ui/react';
import {
	CopilotSidebar,
	useCopilotChatSuggestions,
} from '@copilotkit/react-ui';
import {
	Button,
	PageContent,
	TabsContent,
	TabsIndicator,
	TabsList,
	TabsRoot,
	TabsTrigger,
} from '@jam/front-library';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	useCreateStoryFromDraftMutation,
	useUpdateStoryWithDraftMutation,
} from '../../../redux/api/cmsApi';
import { useDraftStory } from '../hooks/useDraftStory';
import { useMakerProgress } from '../hooks/useMakerProgress';
import { Highlight } from './Highlight';
import { MakerPersonaView } from './MakerPersonaView';
import { MakerScenarioView } from './MakerScenarioView';
import { MakerScorecardView } from './MakerScorecardView';
import { MakerTestButton } from './MakerTestButton';

type MakerPageContentProps = {
	baseStoryId: string;
	editMode: boolean;
};

export const MakerPageContent = ({
	baseStoryId,
	editMode,
}: MakerPageContentProps) => {
	const { draft } = useDraftStory(baseStoryId);
	const [createStoryFromDraft, { isLoading: isLoadingCreate }] =
		useCreateStoryFromDraftMutation();
	const [updateStoryWithDraft, { isLoading: isLoadingUpdate }] =
		useUpdateStoryWithDraftMutation();
	const navigate = useNavigate();

	useMakerProgress();

	useCopilotChatSuggestions(
		{
			instructions:
				'Suggest the user to ask for tool description. Suggest couple of examples.',
			minSuggestions: 1,
			maxSuggestions: 3,
		},
		[draft]
	);

	const onSaveHandler = useCallback(async () => {
		if (!draft) {
			return;
		}

		let storyId;
		if (editMode) {
			storyId = (
				await updateStoryWithDraft({
					storyId: baseStoryId,
					body: draft,
				}).unwrap()
			).id;
		} else {
			storyId = (await createStoryFromDraft(draft).unwrap()).id;
		}

		navigate(`/mission/${storyId}`);
	}, [draft]);

	if (!draft) {
		return null;
	}

	return (
		<CopilotSidebar
			labels={{
				title: 'Maker Assistant',
				initial: 'How would you like to change the mission?',
			}}
			clickOutsideToClose={false}
			hitEscapeToClose={false}
			defaultOpen={true}
		>
			<Flex direction={'column'} gap="10" width="100%">
				<Flex direction={'row'} gap="4" justifyContent={'end'}>
					<MakerTestButton
						draft={draft}
						onFinishCall={() => Promise.resolve()}
					/>
					<Button
						size="md"
						variant="secondary"
						disabled={isLoadingCreate || isLoadingUpdate}
						onClick={() => void onSaveHandler()}
					>
						{editMode ? 'Update' : 'Create'} Story
					</Button>
				</Flex>
				<TabsRoot defaultValue="persona" variant="line">
					<TabsList>
						<TabsTrigger value="persona">
							<Highlight trigger={draft.persona}>Persona</Highlight>
						</TabsTrigger>
						<TabsTrigger value="scenario">
							<Highlight trigger={draft.scenario}>Scenario</Highlight>
						</TabsTrigger>
						<TabsTrigger value="scorecard">
							<Highlight trigger={draft.scorecard}>Scorecard</Highlight>
						</TabsTrigger>
						<TabsIndicator />
					</TabsList>
					<TabsContent value="persona">
						<PageContent>
							<MakerPersonaView draft={draft} />
						</PageContent>
					</TabsContent>
					<TabsContent value="scenario">
						<PageContent>
							<MakerScenarioView draft={draft} />
						</PageContent>
					</TabsContent>
					<TabsContent value="scorecard">
						<PageContent>
							<MakerScorecardView draft={draft} />
						</PageContent>
					</TabsContent>
				</TabsRoot>
			</Flex>
		</CopilotSidebar>
	);
};
