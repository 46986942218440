// ContentAssignmentNameDateSelection.tsx
import React, { useEffect } from 'react';
import { DatePicker, Field, Input } from '@jam/front-library';
import { Flex } from '@chakra-ui/react';
import { Control, Controller, FieldErrors, useWatch } from 'react-hook-form';
import { CreateSprintScheduleRequestDTOSchemaFrontendDTO } from '../content-assignment/ContentAssignment';
import { useTranslation } from 'react-i18next';

interface Props {
	control: Control<CreateSprintScheduleRequestDTOSchemaFrontendDTO>;
	errors: FieldErrors<CreateSprintScheduleRequestDTOSchemaFrontendDTO>;
}

export const ContentAssignmentNameDateSelection: React.FC<Props> = ({
	control,
	errors,
}) => {
	const { t } = useTranslation('coaching');
	const [refreshKey, setRefreshKey] = React.useState(0);

	// Watch contentReference directly from the form
	const dueDate = useWatch({
		control,
		name: 'dueDate',
		defaultValue: undefined,
	});

	useEffect(() => {
		if (!dueDate) {
			setRefreshKey((prev) => prev + 1);
		}
	}, [dueDate]);

	return (
		<Flex w="100%">
			<Flex w="100%" gap={4} direction="column">
				<Controller
					name="title"
					control={control}
					render={({ field }) => (
						<Field
							invalid={!!errors?.title}
							errorText={t('due_date.name_error')}
							errorPosition="top"
						>
							<Input
								w={'col-4'}
								placeholder={t('coaching:due_date.name_placeholder').toString()}
								value={field.value}
								name={field.name}
								onChange={(e) => {
									const newValue = e.target.value;
									field.onChange(newValue);
								}}
							/>
						</Field>
					)}
				/>

				{/* DatePicker with Field wrapper */}
				<Controller
					data-testid="date-picker"
					name="dueDate"
					control={control}
					render={({ field }) => (
						<Field
							invalid={!!errors?.dueDate}
							errorPosition="top"
							errorText={t('due_date.date_error')}
						>
							<Flex w="col-4">
								<DatePicker
									key={refreshKey}
									inline
									selectsRange={true}
									minDate={new Date()}
									startDate={new Date()}
									endDate={field.value}
									onRangeChange={([, end]) => {
										field.onChange(end);
									}}
								/>
							</Flex>
						</Field>
					)}
				/>
			</Flex>
		</Flex>
	);
};
