import { Box, Image, VStack } from '@chakra-ui/react';
import {
	PathsApiV1SessionsGetParametersQueryState,
	SchemaSessionResponseDto,
	SessionResponseDTOCallEndedReason,
} from '@jam/api-sdk/api';
import {
	Button,
	DialogBackdrop,
	DialogBody,
	DialogContent,
	DialogRoot,
	Text,
} from '@jam/front-library';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type CallEndedReasonModalProps = {
	session: SchemaSessionResponseDto | undefined | null;
};

export const CallEndedReasonModal = ({
	session,
}: CallEndedReasonModalProps) => {
	const { t } = useTranslation('call', { keyPrefix: 'ended_reason_modal' });
	const [isOpen, setIsOpen] = useState<boolean>(false);

	useEffect(() => {
		if (
			!session ||
			!session.state ||
			session.state !== PathsApiV1SessionsGetParametersQueryState.DONE
		) {
			return;
		}

		switch (session.call.endedReason) {
			case SessionResponseDTOCallEndedReason.assistant_ended_call:
			case SessionResponseDTOCallEndedReason.assistant_said_end_call_phrase:
			case SessionResponseDTOCallEndedReason.customer_ended_call:
				return;
			case SessionResponseDTOCallEndedReason.exceeded_max_duration:
			case SessionResponseDTOCallEndedReason.silence_timed_out:
				setIsOpen(true);
				return;
			default:
				setIsOpen(true);
				return;
		}
	}, [session]);

	const renderContent = () => {
		switch (session?.call.endedReason) {
			case SessionResponseDTOCallEndedReason.exceeded_max_duration:
				return t('exceeded_max_duration');
			case SessionResponseDTOCallEndedReason.silence_timed_out:
				return t('silence_timed_out');
			default:
				return t('unexpected');
		}
	};

	return (
		<DialogRoot
			open={isOpen}
			onOpenChange={({ open }) => {
				if (!open) {
					setIsOpen(false);
				}
			}}
			placement="center"
		>
			<DialogBackdrop />
			<DialogContent maxW="450px" py={6} borderRadius={'24px'}>
				<DialogBody p={0}>
					<VStack gap={4} align="center">
						<Image src="/icons/mic-icon.svg" alt="Jam Logo" w="48px" h="48px" />
						<Text variant="cards-h1">{t('title')}</Text>
						<Box mx={6}>
							<Text textAlign="center">{renderContent()}</Text>
						</Box>
						<Box width={'full'} px={6}>
							<Button size="full" onClick={() => setIsOpen(false)}>
								{t('ok')}
							</Button>
						</Box>
					</VStack>
				</DialogBody>
			</DialogContent>
		</DialogRoot>
	);
};
