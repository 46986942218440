import { Box, Separator } from '@chakra-ui/react';
import {
	SchemaAssignedContentResponseDto,
	SchemaJourneyResponseDto,
} from '@jam/api-sdk/api';
import {
	AccordionItem,
	AccordionItemContent,
	AccordionItemTrigger,
	AccordionRoot,
	Text,
} from '@jam/front-library';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { StoryCardGrid } from '../story-detail/StoryCardGrid';
import { TrackDetailHeader } from './TrackDetailHeader';

type TrackAccordionProps = {
	// only used to calculate story status which I'd love to deprecate
	userId?: string;
	userName?: string;

	// story card action button
	renderStoryButton?: (storyId: string) => ReactElement;
	journey: SchemaJourneyResponseDto;
	contentAllocation?: SchemaAssignedContentResponseDto;
	defaultOpen?: boolean;
};

export const TrackAccordion = ({
	journey,
	contentAllocation,
	userId,
	renderStoryButton,
	defaultOpen,
	userName,
}: TrackAccordionProps) => {
	const { t } = useTranslation('common');

	return (
		<Box id={`track-accordion-${journey.id}`}>
			<AccordionRoot
				w={'100%'}
				borderWidth={'1.5px'}
				p={6}
				borderRadius={'12px'}
				collapsible
				defaultValue={defaultOpen ? ['missions'] : []}
			>
				<AccordionItem border={0} value="missions">
					<TrackDetailHeader
						journey={journey}
						contentAllocation={contentAllocation}
						userName={userName}
					/>
					<Separator />
					<AccordionItemTrigger
						indicatorPlacement="start"
						px={0}
						paddingBottom="0px"
						paddingTop="16px"
					>
						<Text variant="label-bold">
							{t('track_detail.accordion.view_missions')}
						</Text>
					</AccordionItemTrigger>
					<AccordionItemContent px={0} py={0}>
						<StoryCardGrid
							userId={userId}
							stories={journey.stories}
							renderStoryButton={renderStoryButton}
						/>
					</AccordionItemContent>
				</AccordionItem>
			</AccordionRoot>
		</Box>
	);
};
