import { useTour } from '@reactour/tour';
import { useLayoutEffect } from 'react';

export const useOnboardingStepFallback = () => {
	const { currentStep, steps, setSteps, isOpen } = useTour();

	useLayoutEffect(() => {
		if (
			!steps?.length ||
			currentStep === null ||
			currentStep === undefined ||
			!steps[currentStep] ||
			!isOpen
		) {
			return;
		}

		setTimeout(() => {
			const selector = steps[currentStep].selector as string;

			const exists = document.querySelector(selector);
			if (!exists) {
				const newSelector = 'body';
				setSteps?.((prevSteps) => {
					const newSteps = [...prevSteps];
					newSteps[currentStep].selector = newSelector;
					newSteps[currentStep].position = 'center';
					return newSteps;
				});
			}
		});
	}, [currentStep, steps, isOpen]);
};
