import { PathsApiV1UsersGetParametersQueryRole } from '@jam/api-sdk/api';
import { RouteObject } from 'react-router-dom';
import { AdminCheck } from './common/hoc/AdminCheck';
import { RoleCheck } from './common/hoc/RoleCheck';
import { OrganizationPage } from './pages/admin/OrganizationPage';
import AuthPage from './pages/AuthPage';
import { CallPage } from './pages/CallPage';
import { CourseCatalogPage } from './pages/CourseCatalogPage';
import { ManagerDashboard } from './pages/dashboard/ManagerDashboard';
import { ManagerDashboardUserDetails } from './pages/dashboard/ManagerDashboardUserDetails';
import { ViewSessionPage } from './pages/dashboard/ViewSessionPage';
import { DefaultPage } from './pages/DefaultPage';
import ErrorPage from './pages/ErrorPage';
import { HomePage } from './pages/HomePage';
import { ManagerJourneysPage } from './pages/journey-explorer/ManagerJourneysPage';
import { ManagerMissionDetailPage } from './pages/journey-explorer/ManagerMissionDetailPage';
import { LayoutPage } from './pages/LayoutPage';
import { MakerPage } from './pages/MakerPage';
import { PerformancePage } from './pages/PerformancePage';
import { ProgressPage } from './pages/ProgressPage';
import { ViewRealCallSessionPage } from './pages/real-call/ViewRealCallSessionPage';
import { RealCallPage } from './pages/RealCallPage';
import { SettingsPage } from './pages/SettingsPage';
import { TeamsPage } from './pages/TeamsPage';
import { CoachingPage } from './pages/dashboard/CoachingPage';

export const appRoutes = [
	{
		path: '/',
		element: <LayoutPage />,
		children: [
			{
				index: true,
				element: <DefaultPage />,
			},
			{
				path: '/admin/org',
				element: <AdminCheck WrappedComponent={<OrganizationPage />} />,
				errorElement: <ErrorPage />,
			},
			{
				path: '/settings',
				element: <SettingsPage />,
				errorElement: <ErrorPage />,
			},
			{
				path: '/users/home',
				element: <HomePage />,
				errorElement: <ErrorPage />,
			},
			{
				path: '/coaching',
				element: (
					<RoleCheck
						requiredRoles={[
							PathsApiV1UsersGetParametersQueryRole.Manager,
							PathsApiV1UsersGetParametersQueryRole.Owner,
						]}
						WrappedComponent={<CoachingPage />}
					/>
				),
				errorElement: <ErrorPage />,
			},
			{
				path: '/course-catalog',
				element: <CourseCatalogPage />,
				errorElement: <ErrorPage />,
			},
			{
				path: '/journeys',
				element: <ManagerJourneysPage />,
				errorElement: <ErrorPage />,
			},
			{
				path: '/mission/:missionId',
				element: <ManagerMissionDetailPage />,
				errorElement: <ErrorPage />,
			},
			{
				path: '/dashboard',
				element: (
					<RoleCheck
						requiredRoles={[
							PathsApiV1UsersGetParametersQueryRole.Manager,
							PathsApiV1UsersGetParametersQueryRole.Owner,
						]}
						WrappedComponent={<ManagerDashboard />}
					/>
				),
				errorElement: <ErrorPage />,
			},
			{
				path: '/teams',
				element: (
					<RoleCheck
						requiredRoles={[
							PathsApiV1UsersGetParametersQueryRole.Manager,
							PathsApiV1UsersGetParametersQueryRole.Owner,
						]}
						WrappedComponent={<TeamsPage />}
					/>
				),
				errorElement: <ErrorPage />,
			},
			{
				path: '/performance',
				element: (
					<RoleCheck
						requiredRoles={[
							PathsApiV1UsersGetParametersQueryRole.Manager,
							PathsApiV1UsersGetParametersQueryRole.Owner,
						]}
						WrappedComponent={<PerformancePage />}
					/>
				),
				errorElement: <ErrorPage />,
			},
			{
				path: '/dashboard',
				element: (
					<RoleCheck
						requiredRoles={[
							PathsApiV1UsersGetParametersQueryRole.Manager,
							PathsApiV1UsersGetParametersQueryRole.Owner,
						]}
						WrappedComponent={<ManagerDashboard />}
					/>
				),
				errorElement: <ErrorPage />,
			},
			{
				path: '/dashboard/user/:userId/session/:sessionId',
				element: (
					<RoleCheck
						requiredRoles={[
							PathsApiV1UsersGetParametersQueryRole.Manager,
							PathsApiV1UsersGetParametersQueryRole.Owner,
						]}
						WrappedComponent={<ViewSessionPage />}
					/>
				),
				errorElement: <ErrorPage />,
			},
			{
				path: '/user/self/session/:sessionId',
				element: <ViewSessionPage />,
				errorElement: <ErrorPage />,
			},
			{
				path: '/dashboard/user/:userId',
				element: (
					<RoleCheck
						requiredRoles={[
							PathsApiV1UsersGetParametersQueryRole.Manager,
							PathsApiV1UsersGetParametersQueryRole.Owner,
						]}
						WrappedComponent={<ManagerDashboardUserDetails />}
					/>
				),
				errorElement: <ErrorPage />,
			},
			{
				path: '/real-call/user/:userId/session/:sessionId',
				element: <ViewRealCallSessionPage />,
				errorElement: <ErrorPage />,
			},
			{
				path: '/real-call',
				element: <RealCallPage />,
				errorElement: <ErrorPage />,
			},
			{
				path: '/call',
				element: <CallPage />,
				errorElement: <ErrorPage />,
			},
			{
				path: '/progress',
				element: <ProgressPage />,
				errorElement: <ErrorPage />,
			},
			{
				path: '/maker-edit/:missionId',
				element: <MakerPage editMode={true} />,
				errorElement: <ErrorPage />,
			},
			{
				path: '/maker/:missionId',
				element: <MakerPage editMode={false} />,
				errorElement: <ErrorPage />,
			},
			{
				path: '/auth',
				element: <AuthPage loading={true} />,
				errorElement: <ErrorPage />,
			},
			{
				path: '*',
				element: <DefaultPage />,
				errorElement: <ErrorPage />,
			},
		],
	},
] satisfies RouteObject[];
