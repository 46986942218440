import { Button } from '@jam/front-library';
import {
	CreateSprintScheduleRequestDTOAssigneeType,
	SchemaCreateSprintScheduleRequestDto,
} from '../../../../../../../packages/api-sdk/src/api';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from '@chakra-ui/react';
import { PathsApiV1AssignmentsGetParametersQueryReferenceType } from '@jam/api-sdk/api';
import { useCreateSprintMutation } from '../../../../redux/api/sprintApi';
import { UseFormHandleSubmit } from 'react-hook-form';
import { CreateSprintScheduleRequestDTOSchemaFrontendDTO } from './ContentAssignment';

interface Props {
	handleSubmit: UseFormHandleSubmit<CreateSprintScheduleRequestDTOSchemaFrontendDTO>;
	onDataSubmit: () => void;
	isValid: boolean;
}

export const ContentAssignmentActions: React.FC<Props> = ({
	handleSubmit,
	onDataSubmit,
	isValid,
}) => {
	const { t } = useTranslation('coaching');
	const [createSprintMutation] = useCreateSprintMutation();

	const onSubmit = handleSubmit(
		async (data: CreateSprintScheduleRequestDTOSchemaFrontendDTO) => {
			if (!isValid) {
				return;
			}
			const mappedData: SchemaCreateSprintScheduleRequestDto = {
				title: data.title,
				assignee: data.assignee.map((assignee: string) => ({
					type: CreateSprintScheduleRequestDTOAssigneeType.team,
					id: assignee,
				})),
				dueDate: data.dueDate.toISOString(), // Use date from form
				contentReference: data.contentReference.map((assignee: string) => ({
					type: PathsApiV1AssignmentsGetParametersQueryReferenceType.journey,
					id: assignee,
				})),
			};

			await createSprintMutation(mappedData);

			onDataSubmit();
		}
	);

	return (
		<>
			<Flex width="col-4">
				<Button
					width="100%"
					onClick={() => {
						void onSubmit();
					}}
				>
					{t('start_sprint')}
				</Button>
			</Flex>
		</>
	);
};
