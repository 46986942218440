import { AccordionItemBody, Image } from '@chakra-ui/react';
import { SchemaGeneratorGetStoryDraftResponseBodyDto } from '@jam/api-sdk/api';
import {
	AccordionItem,
	AccordionItemContent,
	AccordionItemTrigger,
	AccordionRoot,
	Field,
	Input,
	Radio,
	RadioGroup,
	Textarea,
} from '@jam/front-library';
import { Highlight } from './Highlight';

type MakerPersonaViewProps = {
	draft: SchemaGeneratorGetStoryDraftResponseBodyDto;
};

export const MakerPersonaView = ({ draft }: MakerPersonaViewProps) => {
	const avatar = draft.persona.avatar_file.url;

	return (
		<AccordionRoot collapsible>
			<AccordionItem value="1">
				<AccordionItemTrigger>
					<Highlight trigger={draft.persona.description}>
						<Field>
							<Textarea
								autoresize
								maxH="50lh"
								value={draft.persona.description}
								size="sm"
							/>
						</Field>
					</Highlight>
				</AccordionItemTrigger>
				<AccordionItemContent>
					<AccordionItemBody>
						<Image
							alt="persona avatar"
							w="140px"
							h="100%"
							objectFit="cover"
							borderRadius={'12px'}
							src={avatar}
						/>

						<Highlight trigger={draft.persona.name}>
							<Field label="Name">
								<Input value={draft.persona.name} />
							</Field>
						</Highlight>

						<Highlight trigger={draft.persona.role}>
							<Field
								label="Role"
								helperText="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor."
							>
								<Input value={draft.persona.role} />
							</Field>
						</Highlight>

						<Highlight trigger={draft.persona.workplace_name}>
							<Field
								label="Workplace name"
								helperText="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor."
							>
								<Input value={draft.persona.workplace_name} />
							</Field>
						</Highlight>

						<Highlight trigger={draft.persona.workplace_description}>
							<Field
								label="Workplace Description"
								helperText="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor."
							>
								<Textarea
									autoresize
									maxH="50lh"
									value={draft.persona.workplace_description}
									size="sm"
								/>
							</Field>
						</Highlight>

						<Highlight trigger={draft.persona.gender}>
							<Field label="Gender">
								<RadioGroup value={draft.persona.gender}>
									<Radio value="female">Female</Radio>
									<Radio value="male">Male</Radio>
									<Radio value="neutral">Neutral</Radio>
								</RadioGroup>
							</Field>
						</Highlight>

						<Highlight trigger={draft.persona.ai_instruction}>
							<Field
								label="Additional instructions for AI"
								helperText="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor."
							>
								<Textarea
									autoresize
									maxH="50lh"
									value={draft.persona.ai_instruction}
									size="sm"
								/>
							</Field>
						</Highlight>
					</AccordionItemBody>
				</AccordionItemContent>
			</AccordionItem>
		</AccordionRoot>
	);
};
