import {
	BasePage,
	PageContent,
	TabsContent,
	TabsIndicator,
	TabsList,
	TabsRoot,
	TabsTrigger,
} from '@jam/front-library';
import { useTranslation } from 'react-i18next';
import { useActiveTab } from '../common/hooks/useActiveTab';
import { MissionList } from '../features/course-catalog/components/MissionList';
import { TrackList } from '../features/course-catalog/components/TrackList';

export const CourseCatalogPage = () => {
	const { t } = useTranslation('course-catalog');
	const { activeTab, setActiveTab } = useActiveTab();
	return (
		<BasePage
			title={t('course_catalog')}
			subtitle={t('course_catalog_description') ?? ''}
			trackingKey="course-catalog"
		>
			<TabsRoot
				lazyMount
				value={activeTab?.toString() ?? '0'}
				onValueChange={({ value }) => {
					setActiveTab(value === '0' ? null : parseInt(value), 'replaceIn');
				}}
			>
				<TabsList>
					<TabsTrigger value={'0'}>{t('tracks')}</TabsTrigger>
					<TabsTrigger value={'1'}>{t('missions')}</TabsTrigger>
					<TabsIndicator />
				</TabsList>
				<TabsContent value={'0'}>
					<PageContent>
						<TrackList />
					</PageContent>
				</TabsContent>
				<TabsContent value={'1'}>
					<PageContent>
						<MissionList />
					</PageContent>
				</TabsContent>
			</TabsRoot>
		</BasePage>
	);
};
