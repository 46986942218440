import { Skeleton, Stack } from '@chakra-ui/react';
import {
	SchemaSessionResponseDto,
	SessionResponseDTOAnalysisState,
} from '@jam/api-sdk/api';
import { BasePage, Layout, PageContent } from '@jam/front-library';
import { X } from '@phosphor-icons/react';
import { useAuthInfo } from '@propelauth/react';
import { TourProvider } from '@reactour/tour';
import React, { useEffect, useState } from 'react';
import {
	CallFeedbackSteps,
	CallPageSteps,
} from '../common/components/onboarding/OnboardinSteps';
import { OnboardingNavigation } from '../common/components/onboarding/OnboardingNavigation';
import { useGoBack } from '../common/hooks/useGoBack';
import { useIsOnboarding } from '../common/hooks/useIsOnboarding';
import { OnboardingFeature } from '../common/types/Onboarding';
import { CallDetails } from '../features/call/components/CallDetails';
import { CallEndedReasonModal } from '../features/call/components/CallEndedReasonModal';
import { Caller } from '../features/call/components/Caller';
import { MicrophonePermission } from '../features/call/components/MicrophonePermission';
import { useActiveSession } from '../features/call/hooks/useActiveSession';
import { useActiveStory } from '../features/home/hooks/useActiveStory';
import { useGetStoryQuery } from '../redux/api/cmsApi';
import {
	useGetSessionQuery,
	useGetStoryLastSessionQuery,
	useGetStorySessionCountQuery,
	useLazyGetUserTotalExperienceQuery,
} from '../redux/api/sessionApi';

const LoadingState = () => {
	return (
		<Layout hideSidebar fullWidth>
			<Stack direction="column" h="100%" gap={10} p={10}>
				<Skeleton borderRadius={'8px'} h="200px" flex={1} />
			</Stack>
		</Layout>
	);
};

const useFetchPageData = (
	activeStory?: string,
	activeSession?: string,
	userId?: string,
	polling = 0
) => {
	const { data: story, isLoading } = useGetStoryQuery(activeStory || '', {
		skip: !activeStory,
	});

	const { data: storySessionCount } = useGetStorySessionCountQuery(
		{ id: activeStory || '', userId: userId || '' },
		{
			skip: !activeStory || !userId || true,
		}
	);

	const { data: lastSession } = useGetStoryLastSessionQuery(
		{ id: activeStory || '', userId: userId || '' },
		{
			// skip the query if there is no active story or if there is an active session
			skip: !activeStory || !!activeSession || !userId,
			pollingInterval: polling,
		}
	);
	const { data: currentSession } = useGetSessionQuery(activeSession || '', {
		skip: !activeSession,
	});

	return {
		story,
		isLoading,
		storySessionCount: storySessionCount ?? 0,
		lastSession,
		currentSession,
	};
};

const useSessionPolling = (
	setPolling: React.Dispatch<React.SetStateAction<number>>,
	session?: SchemaSessionResponseDto
) => {
	const [getUserTotalExperience] = useLazyGetUserTotalExperienceQuery();

	// This useEffect is used to set the polling interval for the last session query based on the status of the last session analysis object.
	useEffect(() => {
		if (!session) {
			return;
		}

		// session is stuck in pending for more than 30 minutes give up
		if (new Date(session.createdAt).getTime() + 1000 * 60 * 30 < Date.now()) {
			setPolling(0);
			return;
		}

		if (
			session.analysis === null ||
			session.analysis.state !== SessionResponseDTOAnalysisState.DONE
		) {
			setPolling(3000);
		} else {
			setPolling(0);
			void getUserTotalExperience();
		}
	}, [setPolling, session]);

	return {};
};

const usePageData = () => {
	const { activeStory } = useActiveStory();
	const { activeSession } = useActiveSession();
	const { user } = useAuthInfo();
	const [polling, setPolling] = useState(0);

	const { story, isLoading, storySessionCount, lastSession, currentSession } =
		useFetchPageData(
			activeStory ?? undefined,
			activeSession ?? undefined,
			user?.userId,
			polling
		);
	const session = activeSession ? currentSession : lastSession;

	useSessionPolling(setPolling, session ?? undefined);

	return {
		story,
		isLoading,
		storySessionCount,
		session,
		dueDate: undefined,
	};
};

export const CallPage = () => {
	const { story, isLoading, storySessionCount, session, dueDate } =
		usePageData();
	const onBack = useGoBack();
	const [currentOnboarding, setCurrentOnboarding] = useState<
		OnboardingFeature.CALL_PAGE | OnboardingFeature.CALL_FEEDBACK_PAGE
	>(OnboardingFeature.CALL_PAGE);
	const isOnboardingCallPage = useIsOnboarding(OnboardingFeature.CALL_PAGE);
	if (isLoading) {
		return <LoadingState />;
	}

	const getOnboardingSteps = () => {
		switch (currentOnboarding) {
			case OnboardingFeature.CALL_FEEDBACK_PAGE:
				return CallFeedbackSteps;

			default:
				return CallPageSteps;
		}
	};

	const canShowMicPermission =
		!isOnboardingCallPage &&
		currentOnboarding !== OnboardingFeature.CALL_FEEDBACK_PAGE;

	return (
		<TourProvider
			key={currentOnboarding}
			steps={getOnboardingSteps()}
			components={{
				Navigation: () => <OnboardingNavigation feature={currentOnboarding} />,
			}}
		>
			<CallEndedReasonModal session={session} />
			{canShowMicPermission && <MicrophonePermission />}
			<BasePage
				onBack={onBack}
				onBackIcon={X}
				title={''}
				hideSidebar
				fullWidth
				trackingKey="call"
			>
				<PageContent>
					<Caller
						numberOfRounds={storySessionCount}
						story={story}
						dueDate={dueDate}
					/>
					<CallDetails
						view="learner"
						story={story}
						session={session}
						withOnboarding
						setOnboarding={setCurrentOnboarding}
					/>
				</PageContent>
			</BasePage>
		</TourProvider>
	);
};
