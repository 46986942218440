import { PathsApiV1SessionsGetParametersQueryDateFilter } from '@jam/api-sdk/api';
import {
	BasePage,
	PageContent,
	TabsContent,
	TabsIndicator,
	TabsList,
	TabsRoot,
	TabsTrigger,
} from '@jam/front-library';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useActiveTab } from '../../common/hooks/useActiveTab';
import { TeamsFilteringToolbar } from '../../features/dashboard/components/manager/common/TeamsFilteringToolbar';
import { RolePlayAcrossUsersTable } from '../../features/dashboard/components/manager/team-activity/RoleplayAcrossUsersTable';
import { TeamActivity } from '../../features/dashboard/components/manager/team-activity/TeamActivity';
import { useGetOrgTeamsQuery } from '../../redux/api/crmApi';

export const ManagerDashboard = () => {
	const { t } = useTranslation(['dashboard', 'common']);
	const [selectedTeamId, setSelectedTeamId] = useState<string | undefined>(
		undefined
	);
	const { activeTab, setActiveTab } = useActiveTab();
	const [selectedDateFilter, setSelectedDateFilter] = useState<
		PathsApiV1SessionsGetParametersQueryDateFilter | undefined
	>(undefined);
	const { data: teams } = useGetOrgTeamsQuery();

	return (
		<BasePage
			title={t('dashboard:activity_dashboard') ?? ''}
			subtitle={t('dashboard:activity_dashboard_description') ?? ''}
			trackingKey="manager_dashboard"
		>
			<TeamsFilteringToolbar
				selectedDateFilter={selectedDateFilter}
				setSelectedDateFilter={setSelectedDateFilter}
				selectedTeamId={selectedTeamId}
				setSelectedTeamId={setSelectedTeamId}
				teams={teams ?? []}
				showTimeFilter={activeTab === 1}
			/>
			<TabsRoot
				lazyMount
				value={activeTab === null ? 'overview' : `tab-${activeTab}`}
				onValueChange={(value) => {
					const index =
						value.value === 'overview'
							? null
							: parseInt(value.value?.split('-')[1]);
					setActiveTab(index, 'replaceIn');
				}}
			>
				<TabsList>
					<TabsTrigger value="overview">
						{t('dashboard:overview_tab_title')}
					</TabsTrigger>
					<TabsTrigger value="tab-1">
						{t('dashboard:all_rounds_played_tab_title')}
					</TabsTrigger>
					<TabsIndicator />
				</TabsList>
				<TabsContent value="overview">
					<PageContent>
						<TeamActivity selectedTeamId={selectedTeamId} />
					</PageContent>
				</TabsContent>
				<TabsContent value="tab-1">
					<PageContent>
						<RolePlayAcrossUsersTable
							selectedDateFilter={selectedDateFilter}
							teamId={selectedTeamId}
						/>
					</PageContent>
				</TabsContent>
			</TabsRoot>
		</BasePage>
	);
};
