import {
	SchemaFindSessionResponseDto,
	SchemaMessageResponseDto,
	SchemaSessionResponseDto,
	SchemaUploadTranscriptRequestDto,
} from '@jam/api-sdk/api';
import { createApi } from '@reduxjs/toolkit/query/react';
import { buildBaseQuery } from './buildBaseQuery';

const api = createApi({
	reducerPath: 'learner-api',
	tagTypes: ['story-sessions'],
	baseQuery: buildBaseQuery('/api/v1'),
	endpoints: (builder) => ({
		getStoryLastSession: builder.query<
			SchemaSessionResponseDto | null,
			{ id: string; userId: string }
		>({
			query: ({ id, userId }) =>
				`sessions?storyReferenceIds[]=${id}&userIds[]=${userId}&page=1&limit=1`,
			transformResponse: (response: SchemaFindSessionResponseDto) => {
				return response.data[0] ?? null;
			},
		}),
		getStorySessionCount: builder.query<number, { id: string; userId: string }>(
			{
				providesTags: ['story-sessions'],
				query: ({ id, userId }) =>
					`sessions?storyReferenceIds[]=${id}&state=DONE&userIds[]=${userId}&page=1&limit=1`,
				transformResponse: (response: SchemaFindSessionResponseDto) => {
					return response.meta.total;
				},
			}
		),
		getSessionsByUserId: builder.query<
			SchemaFindSessionResponseDto,
			{
				userId: string;
				page?: number;
				limit?: number;
				storyReferenceIds?: string[];
			}
		>({
			query: ({ userId, page, limit, storyReferenceIds }) => ({
				url: 'sessions',
				params: {
					page,
					limit,
					'userIds[]': [userId],
					state: 'DONE',
					...(storyReferenceIds?.length && {
						'storyReferenceIds[]': storyReferenceIds,
					}),
				},
			}),
		}),

		getSession: builder.query<SchemaSessionResponseDto, string>({
			query: (id: string) => `sessions/${id}`,
		}),
		createSession: builder.mutation<
			{ id: string; call: { assistantReferenceId: string } },
			{ storyId: string }
		>({
			query: (req) => ({
				url: 'sessions',
				method: 'POST',
				body: req,
			}),
		}),
		uploadTranscript: builder.mutation<
			SchemaSessionResponseDto,
			SchemaUploadTranscriptRequestDto
		>({
			query: (req) => ({
				url: `sessions/upload-transcript`,
				method: 'POST',
				body: req,
			}),
		}),
		deleteSession: builder.mutation<void, string>({
			query: (id: string) => ({
				url: `sessions/${id}`,
				method: 'DELETE',
			}),
		}),
		getUserTotalExperience: builder.query<number, void>({
			query: () => `experience/total`,
		}),
		getMessages: builder.query<SchemaMessageResponseDto[], string>({
			query: (callId: string) => `messages/all?callId=${callId}`,
		}),
	}),
});

export const {
	useGetStorySessionCountQuery,
	useLazyGetStorySessionCountQuery,
	useCreateSessionMutation,
	useGetSessionsByUserIdQuery,
	useGetSessionQuery,
	useDeleteSessionMutation,
	useGetStoryLastSessionQuery,
	useLazyGetStoryLastSessionQuery,
	useGetUserTotalExperienceQuery,
	useLazyGetUserTotalExperienceQuery,
	useUploadTranscriptMutation,
	useGetMessagesQuery,
} = api;
export default api;
