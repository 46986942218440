import { Flex } from '@chakra-ui/react';
import { BasePage, PageContent } from '@jam/front-library';
import { useAuthInfo } from '@propelauth/react';
import { TourProvider } from '@reactour/tour';
import { useTranslation } from 'react-i18next';
import { OnboardingSteps } from '../common/components/onboarding/OnboardinSteps';
import { OnboardingModal } from '../common/components/onboarding/OnboardingModal';
import { OnboardingNavigation } from '../common/components/onboarding/OnboardingNavigation';
import { useIsRecruiting } from '../common/hooks/useIsRecruiting';
import { OnboardingFeature } from '../common/types/Onboarding';
import { RecentActivity } from '../features/dashboard/components/user-details/overview/components/RecentActivity';
import { GetInspired } from '../features/home/components/GetInspired';
import { KeepLearning } from '../features/home/components/KeepLearning';
import { NextStory } from '../features/home/components/NextStory';
import { UserWeeklyProgress } from '../features/home/components/UserWeeklyProgress';
import { UsersLeaderboard } from '../features/home/components/UsersLeaderboard';
import { useAppSelector } from '../redux/hooks';
import { selectLanguage } from '../redux/slice';

export const HomePage = () => {
	const { user: authUser } = useAuthInfo();
	const { t } = useTranslation();
	const language = useAppSelector(selectLanguage);
	const day = new Date().toLocaleDateString(language, { weekday: 'long' });
	const isRecruiting = useIsRecruiting();

	return (
		<TourProvider
			steps={OnboardingSteps}
			components={{
				Navigation: () => (
					<OnboardingNavigation feature={OnboardingFeature.HOME_PAGE} />
				),
			}}
		>
			<BasePage
				trackingKey="home"
				title={t('home:salutation', { day, name: authUser?.firstName || '' })}
			>
				<PageContent>
					<OnboardingModal
						feature={OnboardingFeature.HOME_PAGE}
						title={t('common:onboarding.welcome')}
						description={t('common:onboarding.description')}
						cta={t('common:onboarding.getStarted')}
					/>
					<NextStory />
					<Flex gap={8}>
						<Flex
							direction={'column'}
							gap={8}
							w={isRecruiting ? '100%' : 'col-9'}
						>
							<KeepLearning />
							{Boolean(authUser?.userId && !isRecruiting) && (
								<RecentActivity
									userId={authUser?.userId as string}
									seeAllLink={'/progress?activeTab=2'}
									view="learner"
								/>
							)}
							{!isRecruiting && <GetInspired />}
						</Flex>
						{!isRecruiting && (
							<Flex direction={'column'} gap={6} flexGrow={1}>
								<UserWeeklyProgress />
								<UsersLeaderboard />
							</Flex>
						)}
					</Flex>
				</PageContent>
			</BasePage>
		</TourProvider>
	);
};
