import { Flex, Box } from '@chakra-ui/react';
import {
	PathsApiV1AssignmentsGetParametersQueryReferenceType,
	PathsApiV1AssignmentsGetParametersQueryType,
	SchemaAssignedContentResponseDto,
} from '@jam/api-sdk/api';
import {
	SkeletonText,
	Table,
	usePrepareTableServerSide,
	Text,
	TableCells,
} from '@jam/front-library';
import { useGetPaginatedCompletedAssignedContentAllocationQuery } from '../../../redux/api/contentAllocationApi';
import { useEffect } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import {
	useGetJourneysQuery,
	useGetStoriesQuery,
} from '../../../redux/api/cmsApi';

const typeTranslation: Record<
	PathsApiV1AssignmentsGetParametersQueryReferenceType,
	string
> = {
	journey: 'track',
	story: 'mission',
};

const assignerTranslation: Record<
	PathsApiV1AssignmentsGetParametersQueryType,
	string
> = {
	autoJourneyAllocation: 'assigner.system',
	managerAssigned: 'assigner.manager',
	refresher: 'assigner.refresher',
	sprint: 'assigner.sprint',
	journey: 'assigner.journey',
};

const assignerFilterTranslation: Record<
	PathsApiV1AssignmentsGetParametersQueryType,
	string
> = {
	autoJourneyAllocation: 'assigner.filter.system',
	managerAssigned: 'assigner.filter.manager',
	refresher: 'assigner.filter.refresher',
	sprint: 'assigner.filter.sprint',
	journey: 'assigner.filter.journey',
};

export type HistoryContentAllocationItem = {
	lastUpdatedAt: Date;
	title?: string;
	type: string;
	assigner?: string;
};

const LoadingState = () => {
	return (
		<Flex role="placeholder" mt="5" direction={'column'} gap="6">
			<Box maxW="300px">
				<SkeletonText noOfLines={1} height={'30px'} />
			</Box>
			<SkeletonText w={'full'} noOfLines={5} gap={'5'} />
		</Flex>
	);
};

interface Props {
	userId?: string;
}

export const HistoryContentAllocationTable: React.FC<Props> = ({
	userId,
}: Props) => {
	const { t } = useTranslation('history');

	const { page, limit, data, serverSideProps, onDataUpdated, query } =
		usePrepareTableServerSide<SchemaAssignedContentResponseDto>();

	const { data: missions } = useGetStoriesQuery();
	const { data: journeys } = useGetJourneysQuery();

	const { data: contentAllocationData, isLoading: contentAllocationsLoading } =
		useGetPaginatedCompletedAssignedContentAllocationQuery(
			{
				userId: userId ?? '',
				limit,
				page,
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				type: query.type ? query.type : undefined,
			},
			{
				skip: !userId,
				refetchOnMountOrArgChange: true,
			}
		);

	useEffect(() => {
		onDataUpdated(contentAllocationData);
	}, [contentAllocationData]);

	if (contentAllocationsLoading) return <LoadingState />;

	if (!data) return null;

	const contentAllocations: HistoryContentAllocationItem[] =
		contentAllocationData?.data.map((item) => ({
			lastUpdatedAt: new Date(item.lastUpdatedAt),
			type: typeTranslation[item.reference.type],
			title:
				item.reference.type ===
				PathsApiV1AssignmentsGetParametersQueryReferenceType.story
					? missions?.find((x) => x.id === item.reference.id)?.title
					: journeys?.find((x) => x.id === item.reference.id)?.title,
			assigner: assignerTranslation[item.type],
		})) ?? [];

	const columnHelper = createColumnHelper<HistoryContentAllocationItem>();
	const columns = [
		columnHelper.accessor('lastUpdatedAt', {
			header: t('content_allocation_table.last_updated_at').toString(),
			cell: ({ row }) => (
				<TableCells.DateCell date={row.original.lastUpdatedAt} withTime />
			),
		}),
		columnHelper.accessor('title', {
			header: t('content_allocation_table.to_do').toString(),
			cell: ({ row }) => (
				<TableCells.TextCell>{row.original.title}</TableCells.TextCell>
			),
		}),
		columnHelper.accessor('type', {
			header: t('content_allocation_table.type').toString(),
			cell: ({ row }) => (
				<TableCells.TextCell>
					{t(row.original.type).toString()}
				</TableCells.TextCell>
			),
		}),
		columnHelper.accessor('assigner', {
			header: t('content_allocation_table.assigner').toString(),
			cell: ({ row }) => (
				<TableCells.TextCell>
					{row.original.assigner ? t(row.original.assigner) : ''}
				</TableCells.TextCell>
			),
			meta: {
				name: 'assigner',
				filtering: {
					backendName: 'type',
					variant: 'select',
					placeholder: t('all_completed_to_dos').toString(),
					options: Object.entries(assignerFilterTranslation).map(
						([key, value]) => ({
							label: t(value).toString(),
							value: key,
						})
					),
				},
			},
		}),
	];

	return (
		<Flex direction="column" gap={6}>
			<Text variant="label" color="gray.400">
				{t('completed_to_dos_description')}
			</Text>
			<Table<HistoryContentAllocationItem>
				asServerSide
				serverSideProps={serverSideProps}
				data={contentAllocations}
				columns={columns}
				enablePagination
			/>
		</Flex>
	);
};
