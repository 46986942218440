import { VStack, Image, HStack, Box } from '@chakra-ui/react';
import {
	SchemaAssignedContentResponseDto,
	SchemaJourneyResponseDto,
} from '@jam/api-sdk/api';
import {
	Text,
	DialogBackdrop,
	DialogBody,
	DialogContent,
	DialogRoot,
	DialogCloseTrigger,
	DialogTitle,
	DialogHeader,
	DialogFooter,
	Button,
	toaster,
} from '@jam/front-library';
import { DownloadSimple, LinkedinLogo } from '@phosphor-icons/react';
import { format } from 'date-fns';
import { de } from 'date-fns/locale/de';
import { enGB } from 'date-fns/locale/en-GB';
import { useTranslation } from 'react-i18next';
import {
	submitDownloadCertificateClickEvent,
	submitShareCertificateClickEvent,
} from '../../../features/tracking/util/submitElementClickEvents';

const DATE_FORMATS = {
	en: 'MM/dd/yyyy',
	de: 'dd.MM.yyyy',
} as const;

interface Props {
	isOpen: boolean;
	setIsOpen: (open: boolean) => void;

	journey: SchemaJourneyResponseDto;
	contentAllocation?: SchemaAssignedContentResponseDto;
	userName?: string;
}

export const TrackCertificationModal: React.FC<Props> = ({
	isOpen,
	setIsOpen,
	contentAllocation,
	journey,
	userName,
}: Props) => {
	const { t } = useTranslation('dashboard');
	const { i18n } = useTranslation();
	const locale = i18n.language === 'en' ? enGB : de;
	const dateFormat = i18n.language === 'en' ? DATE_FORMATS.en : DATE_FORMATS.de;

	const handleFeatureClick = () => {
		toaster.create({
			title: t('user_details.certification.modal.certificate.toaster.title'),
			description: t(
				'user_details.certification.modal.certificate.toaster.description'
			),
			type: 'info',
		});
	};

	const handleDownloadClick = () => {
		handleFeatureClick();
		submitDownloadCertificateClickEvent();
	};

	const handleShareLinkedInClick = () => {
		handleFeatureClick();
		submitShareCertificateClickEvent();
	};

	const formattedDate = contentAllocation?.lastUpdatedAt
		? format(contentAllocation?.lastUpdatedAt, dateFormat, {
				locale,
			})
		: undefined;

	return (
		<DialogRoot
			size="lg"
			open={isOpen}
			onOpenChange={({ open }) => {
				if (!open) {
					setIsOpen(false);
				}
			}}
			placement="center"
		>
			<DialogBackdrop />
			<DialogContent>
				<DialogHeader>
					<DialogTitle textStyle="h4">
						{t('user_details.certification.modal.title')}
					</DialogTitle>
					<DialogCloseTrigger />
				</DialogHeader>
				<DialogBody>
					<HStack h="595px" gap={0}>
						<Box
							display="flex"
							w="190px"
							h="100%"
							justifyContent="center"
							alignItems="center"
							backgroundColor="#000040"
						>
							<Image
								src="/logo.svg"
								w="100px"
								h="32px"
								filter="brightness(0) invert(1)"
							/>
						</Box>
						<VStack
							w="100%"
							h="100%"
							backgroundColor="#EDEDFF"
							px={8}
							py={12}
							alignItems="flex-start"
						>
							<Text variant="cards-h1">
								{t(
									'user_details.certification.modal.certificate.title'
								).toUpperCase()}
							</Text>

							<VStack alignItems="flex-start" gap={8} mt={8}>
								<VStack alignItems="flex-start" gap={2} mt={8}>
									<Text variant="label" color="primary.500">
										{t(
											'user_details.certification.modal.certificate.awarded_to'
										).toUpperCase()}
									</Text>
									<Text variant="h3">{userName}</Text>
								</VStack>

								<VStack alignItems="flex-start" gap={2} mt={8}>
									<Text variant="label" color="primary.500">
										{t(
											'user_details.certification.modal.certificate.successfully_completed'
										).toUpperCase()}
									</Text>
									<Text variant="h3">{journey.title}</Text>
								</VStack>

								<VStack alignItems="flex-start" gap={2} mt={8}>
									<Text variant="label" color="primary.500">
										{t(
											'user_details.certification.modal.certificate.completed_on'
										).toUpperCase()}
									</Text>
									<Text variant="h3">{formattedDate}</Text>
								</VStack>
							</VStack>

							<HStack mt={12} w="100%" justifyContent="space-between">
								<VStack alignItems="flex-start" gap={1}>
									<Image src="/signature.svg" />
									<Text variant="label">Dr. Clemens Lechner</Text>
									<Text variant="label" color="gray.400">
										Co-Founder & CPO
									</Text>
								</VStack>
								<VStack alignItems="flex-end" gap={1} mt="auto">
									<Text variant="label">https://www.wejam.ai</Text>
									<Text variant="label" color="gray.400">
										Jam Technologies GmbH
									</Text>
								</VStack>
							</HStack>
						</VStack>
					</HStack>
				</DialogBody>
				<DialogFooter>
					<Text variant="label">
						{t(
							'user_details.certification.modal.certificate.share_or_download'
						)}
					</Text>
					<Button
						id="cert-modal-download"
						variant="secondary"
						icon={<DownloadSimple size={32} />}
						onClick={handleDownloadClick}
					>
						{t('user_details.certification.modal.certificate.download')}
					</Button>
					<Button
						id="cert-modal-share-on-linked-in"
						icon={<LinkedinLogo size={32} />}
						onClick={handleShareLinkedInClick}
					>
						{t(
							'user_details.certification.modal.certificate.share_on_linked_in'
						)}
					</Button>
				</DialogFooter>
			</DialogContent>
		</DialogRoot>
	);
};
