import { CopilotKit } from '@copilotkit/react-core';
import '@copilotkit/react-ui/styles.css';
import { useAuthInfo } from '@propelauth/react';
import React from 'react';

const apiUrl = import.meta.env
	.VITE_SALES_ENABLEMENT_PLATFORM_NEST_API_BASE_URL as string;

export const CopilotProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const { accessToken } = useAuthInfo();

	if (!accessToken) {
		return null;
	}

	const headers = {
		Authorization: `Bearer ${accessToken}`,
	};

	return (
		<CopilotKit
			runtimeUrl={`${apiUrl}/api/v1/copilotkit`}
			headers={headers}
			agent="contextualize"
		>
			{children}
		</CopilotKit>
	);
};
