import { useCoAgent } from '@copilotkit/react-core';
import { SchemaGeneratorGetStoryDraftResponseBodyDto } from '@jam/api-sdk/api';
import { useEffect, useState } from 'react';
import { useGetStoryDraftQuery } from '../../../redux/api/cmsApi';
import { IAgentState } from './IAgentState';
import { patchDraft } from './patchDraft';

export const useDraftStory = (storyId: string) => {
	const { data: initialData } = useGetStoryDraftQuery(storyId);
	const [draft, setDraft] =
		useState<SchemaGeneratorGetStoryDraftResponseBodyDto>();
	const { state: makerAgentState, setState: setMakerAgentState } =
		useCoAgent<IAgentState>({ name: 'contextualize' });

	useEffect(() => {
		setDraft(initialData);
	}, [initialData]);

	useEffect(() => {
		setMakerAgentState((prev) => ({
			...prev,
			draft: initialData,
			draftProgress: undefined,
			newDraft: undefined,
		}));
	}, [initialData]);

	useEffect(() => {
		setDraft((prev) => patchDraft(prev, makerAgentState.draftProgress));
	}, [makerAgentState.draftProgress]);

	useEffect(() => {
		setDraft((prev) => patchDraft(prev, makerAgentState.newDraft));
		setMakerAgentState((prev) => ({
			draft: patchDraft(prev?.draft, makerAgentState.newDraft),
			draftProgress: undefined,
			newDraft: undefined,
			logs: undefined,
		}));
	}, [makerAgentState.newDraft]);

	return { draft };
};
