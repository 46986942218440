interface Props {
	size?: number;
	color?: string;
}
export function PieChartIcon({ size, color, ...props }: Props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			fill="none"
			viewBox="0 0 24 24"
			{...props}
		>
			<path
				d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM1.8 12C1.8 17.6333 6.36669 22.2 12 22.2C17.6333 22.2 22.2 17.6333 22.2 12C22.2 6.36669 17.6333 1.8 12 1.8C6.36669 1.8 1.8 6.36669 1.8 12Z"
				fill="#018B02"
				fillOpacity="0.25"
			/>
			<path
				d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM1.8 12C1.8 17.6333 6.36669 22.2 12 22.2C17.6333 22.2 22.2 17.6333 22.2 12C22.2 6.36669 17.6333 1.8 12 1.8C6.36669 1.8 1.8 6.36669 1.8 12Z"
				fill="#018B02"
			/>
			<path
				d="M11.0749 15.0229C10.7625 15.3353 10.256 15.3353 9.94353 15.0229L7.7649 12.8443C7.45248 12.5318 7.45248 12.0253 7.7649 11.7129L7.91133 11.5665C8.22375 11.254 8.73028 11.254 9.0427 11.5665L9.94353 12.4673C10.256 12.7797 10.7625 12.7797 11.0749 12.4673L15.1757 8.36647C15.4882 8.05405 15.9947 8.05405 16.3071 8.36647L16.4535 8.5129C16.766 8.82532 16.766 9.33185 16.4535 9.64427L11.0749 15.0229Z"
				fill="#018B02"
			/>
		</svg>
	);
}
