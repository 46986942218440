import { Box, Image, VStack } from '@chakra-ui/react';
import {
	Button,
	DialogBackdrop,
	DialogBody,
	DialogContent,
	DialogRoot,
	Text,
} from '@jam/front-library';
import { useTranslation } from 'react-i18next';

type MicPermissionModalProps = {
	isOpen: boolean;
	onClose: () => void;
};

const MicPermissionModal = ({ isOpen, onClose }: MicPermissionModalProps) => {
	const { t } = useTranslation('call');

	return (
		<DialogRoot
			open={isOpen}
			onOpenChange={({ open }) => {
				if (!open) {
					onClose();
				}
			}}
			placement="center"
		>
			<DialogBackdrop />
			<DialogContent maxW="450px" py={6} borderRadius={'24px'}>
				<DialogBody p={0}>
					<VStack gap={4} align="center">
						<Image src="/icons/mic-icon.svg" alt="Jam Logo" w="48px" h="48px" />
						<Text variant="cards-h1">{t('mic_permissions_modal_title')}</Text>
						<Box mx={6}>
							<Text textAlign="center">{t('mic_permissions_modal_text')}</Text>
						</Box>
						<Box width={'full'} px={6}>
							<Button size="full" onClick={onClose}>
								{t('understood')}
							</Button>
						</Box>
					</VStack>
				</DialogBody>
			</DialogContent>
		</DialogRoot>
	);
};

export default MicPermissionModal;
