import {
	SchemaCreateSprintScheduleReponseDto,
	SchemaCreateSprintScheduleRequestDto,
} from '@jam/api-sdk/api';
import { createApi } from '@reduxjs/toolkit/query/react';
import { buildBaseQuery } from './buildBaseQuery';

const api = createApi({
	reducerPath: 'sprint-api',
	baseQuery: buildBaseQuery('/api/v1/sprint'),
	endpoints: (builder) => ({
		createSprint: builder.mutation<
			SchemaCreateSprintScheduleReponseDto,
			SchemaCreateSprintScheduleRequestDto
		>({
			query: (req) => ({
				url: ``,
				method: 'POST',
				body: req,
			}),
		}),
	}),
});

export const { useCreateSprintMutation } = api;
export default api;
