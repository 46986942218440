import React from 'react';
import {
	Button,
	DialogBackdrop,
	DialogBody,
	DialogCloseTrigger,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogRoot,
	DialogTitle,
	Text,
} from '@jam/front-library';
import { useTranslation } from 'react-i18next';

type ConfirmationDialogProps = {
	isOpen: boolean;
	onClose: () => void;
	onConfirm: () => void;
	title: string;
	message: string;
	confirmText?: string;
	cancelText?: string;
	isLoading?: boolean;
};

export const ConfirmationDialog = ({
	isOpen,
	onClose,
	onConfirm,
	title,
	message,
	confirmText,
	cancelText,
	isLoading = false,
}: ConfirmationDialogProps) => {
	const { t } = useTranslation();
	const contentRef = React.useRef<HTMLDivElement>(null);

	return (
		<DialogRoot
			size="md"
			open={isOpen}
			onOpenChange={({ open }) => {
				if (!open) {
					onClose();
				}
			}}
		>
			<DialogBackdrop />
			<DialogContent ref={contentRef}>
				<DialogHeader>
					<DialogTitle textStyle="h4">{title}</DialogTitle>
					<DialogCloseTrigger />
				</DialogHeader>
				{Boolean(message?.length) && (
					<DialogBody pb={6}>
						<Text>{message}</Text>
					</DialogBody>
				)}
				<DialogFooter>
					<Button
						data-testid="confirm-action-button"
						type="submit"
						loading={isLoading}
						onClick={onConfirm}
					>
						{confirmText || t('common:submit')}
					</Button>
					<Button
						variant={'tertiary'}
						onClick={onClose}
						data-testid="cancel-action-button"
					>
						<Text>{cancelText || t('common:cancel')}</Text>
					</Button>
				</DialogFooter>
			</DialogContent>
		</DialogRoot>
	);
};
