import { submitPostHog } from './submitPostHog';
import {
	MissionInteractionEvent,
	MissionInteractionType,
	TrackingEventType,
} from '../types/TrackingEvent';

export function submitMissionStarted(
	missionId: string,
	origin:
		| 'course_catalog_missions'
		| 'course_catalog_tracks'
		| 'next_story_start_button'
		| 'keep_learning'
) {
	if (!missionId) {
		return;
	}
	submitPostHog({
		key: TrackingEventType.MISSION_INTERACTION,
		meta: {
			name: MissionInteractionType.STARTED,
			missionId,
			origin,
		},
	} as MissionInteractionEvent);
}

export function submitSeeMissionScoreCards(
	missionId: string,
	origin: 'hints_tab' | 'track_accordion'
) {
	if (!missionId) {
		return;
	}
	submitPostHog({
		key: TrackingEventType.MISSION_INTERACTION,
		meta: {
			name: MissionInteractionType.SEE_SCORECARD,
			missionId,
			origin,
		},
	} as MissionInteractionEvent);
}

export function submitSeeMissionResults(
	missionId: string,
	origin: 'history_session_table'
) {
	if (!missionId) {
		return;
	}
	submitPostHog({
		key: TrackingEventType.MISSION_INTERACTION,
		meta: {
			name: MissionInteractionType.SEE_RESULTS,
			missionId,
			origin,
		},
	} as MissionInteractionEvent);
}
