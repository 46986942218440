import { Box, Flex } from '@chakra-ui/react';
import { SchemaSessionResponseDto } from '@jam/api-sdk/api';
import {
	SkeletonText,
	Table,
	TableCells,
	usePrepareTableServerSide,
	Text,
} from '@jam/front-library';
import { createColumnHelper } from '@tanstack/react-table';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SolvedItemDots } from '../../../common/components/SolvedItemDots';
import { useGetSessionsByUserIdQuery } from '../../../redux/api/sessionApi';
import { submitSeeMissionResults } from '../../tracking/util/submitMissionEvents';
import { useGetStoriesQuery } from '../../../redux/api/cmsApi';
import { useNavigate } from 'react-router-dom';

export type HistorySessionItem = {
	storyTitle: string;
	storyId: string;
	sessionId: string;
	journeyTitle: string;
	date: Date;
	status: SchemaSessionResponseDto['analysis']['items'] | null;
	score: number;
};

const LoadingState = () => {
	return (
		<Flex role="placeholder" mt="5" direction={'column'} gap="6">
			<Box maxW="300px">
				<SkeletonText noOfLines={1} height={'30px'} />
			</Box>
			<SkeletonText w={'full'} noOfLines={5} gap={'5'} />
		</Flex>
	);
};

interface Props {
	userId?: string;
	view?: 'learner' | 'manager';
}

export const HistorySessionTable: React.FC<Props> = ({
	userId,
	view,
}: Props) => {
	const { t } = useTranslation('history');
	const navigate = useNavigate();

	const { page, limit, data, serverSideProps, onDataUpdated, query } =
		usePrepareTableServerSide<SchemaSessionResponseDto>();
	const { data: missions } = useGetStoriesQuery();
	const { data: sessionData, isLoading: sessionsLoading } =
		useGetSessionsByUserIdQuery(
			{
				userId: userId ?? '',
				limit,
				page,
				storyReferenceIds: query.storyReferenceIds
					? [query.storyReferenceIds]
					: undefined,
			},
			{
				skip: !userId,
				refetchOnMountOrArgChange: true,
			}
		);

	useEffect(() => {
		onDataUpdated(sessionData);
	}, [sessionData]);

	if (sessionsLoading) return <LoadingState />;

	if (!data) return null;

	const sessions: HistorySessionItem[] = data
		.map((session) => {
			return {
				storyId: session.storyReferenceId,
				storyTitle: session.storyTitle,
				sessionId: session.id,
				journeyTitle: session.journeyTitle,
				date: new Date(session.createdAt),
				status: session.analysis.items,
				score: session.analysis.score,
			};
		})
		.filter((item) => item !== null) as HistorySessionItem[];

	const onRowClick = (row: HistorySessionItem) => {
		if (view === 'manager') {
			navigate(
				`/dashboard/user/${userId ?? ''}/session/${row.sessionId}?activeCallTabIndex=2`
			);
		} else {
			navigate(`/user/self/session/${row.sessionId}?activeCallTabIndex=2`);
		}
	};

	const columnHelper = createColumnHelper<HistorySessionItem>();
	const columns = [
		columnHelper.accessor('date', {
			header: t('date').toString(),
			cell: ({ row }) => (
				<TableCells.DateCell date={row.original.date} withTime />
			),
		}),
		columnHelper.accessor('journeyTitle', {
			header: t('track').toString(),
			cell: ({ row }) => (
				<TableCells.TextCell>{row.original.journeyTitle}</TableCells.TextCell>
			),
			enableSorting: false,
		}),
		columnHelper.accessor('storyTitle', {
			header: t('mission').toString(),
			cell: ({ row }) => (
				<TableCells.TextCell>{row.original.storyTitle}</TableCells.TextCell>
			),
			enableSorting: false,
			meta: {
				name: 'storyTitle',
				filtering: {
					backendName: 'storyReferenceIds',
					variant: 'select',
					placeholder: t('all_missions').toString(),
					options:
						missions?.map((track) => ({
							label: track.title,
							value: track.id,
						})) ?? [],
				},
			},
		}),
		columnHelper.accessor('score', {
			header: t('common:score').toString(),
			cell: ({ row }) => (
				<TableCells.NumberCell>
					{Math.round(row.original.score)}
				</TableCells.NumberCell>
			),
			meta: {
				alignHeader: 'end',
			},
		}),
		columnHelper.accessor('status', {
			header: t('feedback').toString(),
			enableSorting: false,
			cell: ({ row }) => {
				const status = row.original.status;
				if (status === null) return null;

				const results = status.map((x) => x.solved);

				return (
					<TableCells.BaseCell>
						<SolvedItemDots items={results} showMax={4} />
					</TableCells.BaseCell>
				);
			},
		}),
	];

	return (
		<Flex direction="column" gap={6}>
			<Text variant="label" color="gray.400">
				{t('all_rounds_played_description')}
			</Text>
			<Table<HistorySessionItem>
				asServerSide
				serverSideProps={serverSideProps}
				data={sessions}
				columns={columns}
				onRowClick={(item: HistorySessionItem) => {
					if (view === 'learner') {
						submitSeeMissionResults(item.storyId, 'history_session_table');
					}
					onRowClick(item);
				}}
				enablePagination
			/>
		</Flex>
	);
};
