import { Flex } from '@chakra-ui/react';
import { Button, Text } from '@jam/front-library';
import { ArrowRight } from '@phosphor-icons/react';
import { useAuthInfo } from '@propelauth/react';
import { StepType, useTour } from '@reactour/tour';
import { useTranslation } from 'react-i18next';
import { useCompleteOnboardingMutation } from '../../../redux/api/crmApi';
import { OnboardingFeature } from '../../types/Onboarding';
import { useEffect, useRef } from 'react';

type OnboardingNavigationProps = {
	feature: OnboardingFeature;
};

const STEP_CTA_PER_FEATURE: Record<
	OnboardingFeature,
	(
		steps: StepType[],
		currentStep: number,
		t: (key: string) => string
	) => {
		text: string;
		showArrow: boolean;
		timeout?: number;
	}
> = {
	[OnboardingFeature.HOME_PAGE]: () => {
		return {
			text: 'next',
			showArrow: true,
			timeout: undefined,
		};
	},
	[OnboardingFeature.CALL_PAGE]: (
		steps: StepType[],
		currentStep: number,
		t: (key: string) => string
	) => {
		const numberOfSteps = steps.length;
		const currentStepIndex = currentStep + 1;
		const isLastStep = currentStepIndex === numberOfSteps;
		return {
			text: t('common:next'),
			showArrow: !isLastStep,
			timeout: 400,
		};
	},
	[OnboardingFeature.CALL_FEEDBACK_PAGE]: (
		steps: StepType[],
		currentStep: number,
		t: (key: string) => string
	) => {
		const numberOfSteps = steps.length;
		const currentStepIndex = currentStep + 1;
		const isLastStep = currentStepIndex === numberOfSteps;
		return {
			text: isLastStep ? t('common:understood') : t('common:continue'),
			showArrow: !isLastStep,
			timeout: currentStepIndex === 1 ? 800 : 0,
		};
	},
	[OnboardingFeature.PROGRESS_PAGE]: (
		steps: StepType[],
		currentStep: number,
		t: (key: string) => string
	) => {
		const numberOfSteps = steps.length;
		const currentStepIndex = currentStep + 1;
		const isLastStep = currentStepIndex === numberOfSteps;
		return {
			text: isLastStep ? t('common:understood') : t('common:next'),
			showArrow: false,
			timeout: 400,
		};
	},
};

const STEP_ACTIONS_PER_FEATURE: Record<
	OnboardingFeature,
	((steps: StepType[], currentStep: number) => void) | undefined
> = {
	[OnboardingFeature.HOME_PAGE]: (steps: StepType[], currentStep: number) => {
		if (currentStep < 0) {
			return;
		}
		const selector = steps[currentStep].selector as string;
		const currentStepIndex = currentStep + 1;
		if (currentStepIndex === 1) {
			const element = document.querySelector(selector);
			if (element) {
				(element as HTMLElement).click();
			}
		}
	},
	[OnboardingFeature.CALL_PAGE]: (steps: StepType[], currentStep: number) => {
		if (currentStep < 0) {
			return;
		}
		const selector = steps[currentStep].selector as string;
		const currentStepIndex = currentStep + 1;
		if (currentStepIndex !== 2) {
			const element = document.querySelector(selector);
			if (element) {
				// click on the feedback tab
				(element as HTMLElement).click();
			}
		}
	},
	[OnboardingFeature.CALL_FEEDBACK_PAGE]: (
		steps: StepType[],
		currentStep: number
	) => {
		if (currentStep < 0) {
			return;
		}
		const selector = steps[currentStep].selector as string;
		const currentStepIndex = currentStep + 1;
		if (currentStepIndex !== 2) {
			const element = document.querySelector(selector);
			if (element) {
				// click on the feedback tab
				(element as HTMLElement).click();
			}
		}
	},
	[OnboardingFeature.PROGRESS_PAGE]: (
		steps: StepType[],
		currentStep: number
	) => {
		const nextStep = Math.min(currentStep + 1, steps.length - 1);
		if (nextStep === steps.length) {
			return;
		}
		const selector = steps[nextStep].selector as string;
		const element = document.querySelector(selector);
		if (element) {
			// click on the feedback tab
			(element as HTMLElement).click();
		}
	},
};

export const OnboardingNavigation = ({
	feature,
}: OnboardingNavigationProps) => {
	const { t } = useTranslation('common');
	const { refreshAuthInfo } = useAuthInfo();
	const { currentStep, setCurrentStep, steps } = useTour();
	const [completeOnboarding] = useCompleteOnboardingMutation();
	const numberOfSteps = steps.length;
	const currentStepIndex = currentStep + 1;
	const isLastStep = currentStepIndex === numberOfSteps;
	const initialLoaded = useRef<boolean>(false);

	useEffect(() => {
		if (
			!feature ||
			!steps ||
			currentStep === null ||
			currentStep === undefined
		) {
			return;
		}
		if (initialLoaded.current) {
			return;
		}
		// on initial render, click on the first step
		const action = STEP_ACTIONS_PER_FEATURE[feature];
		if (action) {
			action(steps, currentStep - 1);
		}

		initialLoaded.current = true;
	}, [currentStep, feature, steps]);

	const handleNextStep = async () => {
		const action = STEP_ACTIONS_PER_FEATURE[feature];
		if (action) {
			action(steps, currentStep);
		}
		const timeout =
			STEP_CTA_PER_FEATURE[feature](steps, currentStep, t).timeout ?? 0;

		setTimeout(() => {
			setCurrentStep(currentStep + 1);
		}, timeout);

		if (isLastStep) {
			await completeOnboarding({ feature });
			await refreshAuthInfo();
		}
	};

	const stepCta = STEP_CTA_PER_FEATURE[feature](steps, currentStep, t);

	return (
		<Flex mt={3} alignItems="center" justifyContent="space-between">
			<Text variant="label-bold" color="white">
				{numberOfSteps > 1 && `${currentStepIndex}/${numberOfSteps}`}
			</Text>
			<Button
				variant="secondary"
				size={'sm'}
				onClick={() => {
					void handleNextStep();
				}}
			>
				{t(stepCta.text)} {stepCta.showArrow ? <ArrowRight /> : undefined}
			</Button>
		</Flex>
	);
};
