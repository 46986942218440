import { Flex } from '@chakra-ui/react';
import {
	Chip,
	TabsContent,
	TabsIndicator,
	TabsList,
	TabsRoot,
	TabsTrigger,
	Text,
} from '@jam/front-library';
import { Lock } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { MissionTaskList } from '../../../common/components/MissionTaskList';
import { useGetStoryDetails } from '../../../common/hooks/useGetStoryDetails';

export const MissionDetail = () => {
	const { missionId } = useParams();
	const mission = useGetStoryDetails(missionId);
	const { t } = useTranslation('common');

	if (!mission) {
		return null;
	}

	const tasks = mission.scorecard.items;

	return (
		<Flex direction="column" gap="3">
			<Text variant="button">{mission.scenario.goal}</Text>
			<Text color={'#757575'} maxW={'700px'}>
				{mission.scenario.description}
			</Text>
			{mission.conversation_topic.length > 0 && (
				<Flex mt={3} gap={2}>
					{mission.conversation_topic.map((tag: string) => (
						<Chip key={tag} bg={'#FAFAFA'} color={'#757575'} text={tag} />
					))}
				</Flex>
			)}
			<TabsRoot defaultValue="tasks" lazyMount>
				<TabsList>
					<TabsTrigger value="tasks">{t('tasks')}</TabsTrigger>
					<TabsTrigger value="activity" disabled>
						<Flex align={'center'} gap={2}>
							<Text>{t('activity')}</Text>
							<Lock size={16} />
						</Flex>
					</TabsTrigger>
					<TabsIndicator />
				</TabsList>
				<TabsContent value="tasks">
					<MissionTaskList tasks={tasks} />
				</TabsContent>
			</TabsRoot>
		</Flex>
	);
};
