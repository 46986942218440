import { Skeleton, Stack } from '@chakra-ui/react';
import {
	SchemaSessionResponseDto,
	SessionResponseDTOAnalysisState,
} from '@jam/api-sdk/api';
import { BasePage, Layout, PageContent } from '@jam/front-library';
import { X } from '@phosphor-icons/react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGoBack } from '../../common/hooks/useGoBack';
import { RealCallDetails } from '../../features/real-call/RealCallDetails';
import { useGetStoryQuery } from '../../redux/api/cmsApi';
import { useGetSessionQuery } from '../../redux/api/sessionApi';

const LoadingState = () => {
	return (
		<Layout hideSidebar fullWidth>
			<Stack direction="column" h="100%" gap={10} p={10}>
				<Skeleton borderRadius={'8px'} h="200px" flex={1} />
			</Stack>
		</Layout>
	);
};

export const isSessionSettled = (session?: SchemaSessionResponseDto) => {
	if (!session) {
		return true;
	}

	// session is stuck in pending for more than 30 minutes give up
	if (new Date(session.createdAt).getTime() + 1000 * 60 * 30 < Date.now()) {
		return true;
	}

	if (
		session.analysis === null ||
		session.analysis.state !== SessionResponseDTOAnalysisState.DONE
	) {
		return false;
	}

	return true;
};

const useFetchPageData = (sessionId?: string) => {
	const [polling, setPolling] = useState(0);

	const { data: session, isLoading: isSessionLoading } = useGetSessionQuery(
		sessionId ?? '',
		{
			skip: !sessionId,
			pollingInterval: polling,
		}
	);
	const { data: story, isLoading } = useGetStoryQuery(
		session?.storyReferenceId ?? '',
		{
			skip: !session,
		}
	);

	if (isSessionSettled(session) && polling !== 0) {
		// set polling to 0 if the session is settled
		setPolling(0);
	} else if (!isSessionSettled(session) && polling !== 3000) {
		// set polling to 5000 if the session is not settled
		setPolling(3000);
	}

	return {
		session,
		story,
		isLoading,
		isSessionLoading,
	};
};

const usePageData = () => {
	const { userId, sessionId } = useParams<{
		userId: string;
		sessionId: string;
	}>();

	const { session, story, isLoading, isSessionLoading } =
		useFetchPageData(sessionId);

	return {
		userId,
		session,
		isSessionLoading,
		story,
		isLoading,
	};
};

export const ViewRealCallSessionPage = () => {
	const { userId, session, isSessionLoading, story, isLoading } = usePageData();
	const onBack = useGoBack();

	if (isLoading || isSessionLoading) {
		return <LoadingState />;
	}

	if (!session || !userId) {
		return null;
	}

	return (
		<BasePage
			onBack={onBack}
			onBackIcon={X}
			title={session.storyTitle}
			hideSidebar
			fullWidth
			trackingKey="real-calls_view"
		>
			<PageContent>
				<RealCallDetails scorecard={story?.scorecard} session={session} />
			</PageContent>
		</BasePage>
	);
};
