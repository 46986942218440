import { PathsApiV1SessionsGetParametersQueryType } from '@jam/api-sdk/api';
import { BasePage } from '@jam/front-library';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { UserSessionActivityTable } from '../common/components/UserSessionActivityTable';
import { UploadTranscript } from '../features/real-call/UploadTranscript';

export const RealCallPage = () => {
	const { t } = useTranslation('home');

	const navigate = useNavigate();

	return (
		<BasePage
			title={t('real-calls')}
			titleActions={
				<UploadTranscript
					onTranscriptUploaded={(session) => {
						navigate(`/real-call/user/${session.userId}/session/${session.id}`);
					}}
				/>
			}
			trackingKey="real-calls"
		>
			<UserSessionActivityTable
				initialQuery={{
					type: PathsApiV1SessionsGetParametersQueryType.REAL_CALL,
				}}
				onRowClick={(row) => {
					navigate(`/real-call/user/${row.userId}/session/${row.sessionId}`);
				}}
				allowDelete={true}
			/>
		</BasePage>
	);
};
