export interface TrackingEvent {
	key: TrackingEventType;
	meta: Record<string, unknown>;
}

export interface PageVisitedEvent extends TrackingEvent {
	key: TrackingEventType.PAGE_VISITED;
	meta: {
		name: string;
		activeTab?: string;
	};
}

export interface SidebarEntryClickedEvent extends TrackingEvent {
	key: TrackingEventType.SIDEBAR_ENTRY_CLICKED;
	meta: {
		name: string;
		section: 'learner' | 'manager' | 'insights' | 'help';
	};
}

export interface ElementClickedEvent extends TrackingEvent {
	key: TrackingEventType.ELEMENT_CLICKED;
	meta: {
		name: string;
		origin: 'sidebar' | 'topbar';
	};
}

export interface MissionInteractionEvent extends TrackingEvent {
	key: TrackingEventType.MISSION_INTERACTION;
	meta: {
		name: MissionInteractionType;
		origin: string;
		missionId: string;
	};
}

export enum MissionInteractionType {
	STARTED = 'started',
	SEE_RESULTS = 'see_results',
	SEE_SCORECARD = 'see_scorecard',
}

export enum TrackingEventType {
	PAGE_VISITED = 'page_visited',
	SIDEBAR_ENTRY_CLICKED = 'sidebar_entry_clicked',
	ELEMENT_CLICKED = 'element_clicked',
	MISSION_INTERACTION = 'mission_interaction',
}
