import {
	AccordionItem,
	AccordionItemContent,
	AccordionItemTrigger,
	Text,
} from '@jam/front-library';
import React from 'react';
import { Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export interface ContentAssignmentStepAccordionProps {
	titleKey: string;
	subtitleKey: string;
	stepIndex: number;
	children: React.ReactNode;
}
export const ContentAssignmentStepAccordion: React.FC<
	ContentAssignmentStepAccordionProps
> = ({ titleKey, subtitleKey, stepIndex, children }) => {
	const { t } = useTranslation('coaching');

	return (
		<>
			<AccordionItem
				key="step1"
				border={0}
				value={stepIndex.toString()}
				data-testid={`step-trigger-${stepIndex}`}
			>
				<AccordionItemTrigger width={'100%'}>
					<Flex w="100%" justifyContent="center" direction="column" gap={2}>
						<Text variant="h3">
							{t('step_title', {
								stepIndex,
								stepTitle: t(titleKey),
							})}
						</Text>
						<Text variant="summary">{t(subtitleKey)}</Text>
					</Flex>
				</AccordionItemTrigger>
				<AccordionItemContent>{children}</AccordionItemContent>
			</AccordionItem>
		</>
	);
};
