import { Flex, useDisclosure } from '@chakra-ui/react';
import { SchemaOrganizationResponseDto } from '@jam/api-sdk/api';
import { BasePage } from '@jam/front-library';
import { useState } from 'react';
import { OrganizationCreateModal } from '../../features/admin/OrganizationCreateModal';
import { OrganizationEditModal } from '../../features/admin/OrganizationEditModal';
import { OrganizationTable } from '../../features/admin/OrganizationTable';
import {
	useCreateOrganizationMutation,
	useUpdateOrganizationMutation,
} from '../../redux/api/adminApi';

export const OrganizationPage = () => {
	const [selectedOrg, setSelectedOrg] =
		useState<SchemaOrganizationResponseDto | null>(null);
	const [updateOrg] = useUpdateOrganizationMutation();
	const [createOrg] = useCreateOrganizationMutation();

	const { open: isOpen, onOpen, onClose } = useDisclosure();
	const {
		open: isOpenCreateModal,
		onOpen: onOpenCreateModal,
		onClose: onCloseCreateModal,
	} = useDisclosure();

	const onUpdateOrgHandler = async (org: SchemaOrganizationResponseDto) => {
		await updateOrg({
			id: org.id,
			body: {
				name: org.name,
				sagaIds: org.sagaIds,
				writeSagaIds: org.writeSagaIds,
			},
		});
	};

	const onCreateOrgHandler = async (org: { name: string }) => {
		await createOrg({ name: org.name });
	};

	return (
		<BasePage title={'Organizations'}>
			<OrganizationCreateModal
				isOpen={isOpenCreateModal}
				onClose={onCloseCreateModal}
				onSubmit={onCreateOrgHandler}
			/>
			{selectedOrg && (
				<OrganizationEditModal
					org={selectedOrg}
					isOpen={isOpen}
					onClose={onClose}
					onSubmit={onUpdateOrgHandler}
				/>
			)}
			<Flex my="10" direction={{ base: 'column' }} gap="5">
				<OrganizationTable
					onAddOrg={onOpenCreateModal}
					onRowClick={(item) => {
						setSelectedOrg(item);
						onOpen();
					}}
				/>
			</Flex>
		</BasePage>
	);
};
