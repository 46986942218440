/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Flex, Stack, useDisclosure } from '@chakra-ui/react';

import {
	SchemaStoryResponseDto,
	SchemaUserPerformanceByRoundResponseDto,
} from '@jam/api-sdk/api';
import { Select, Text, Tooltip as ChakraTooltip } from '@jam/front-library';
import { Circle, Info } from '@phosphor-icons/react';
import { format } from 'date-fns';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
	CartesianGrid,
	DotProps,
	Line,
	LineChart,
	ResponsiveContainer,
	XAxis,
	YAxis,
} from 'recharts';
import { useOutsideAlerter } from '../hooks/useOutsideClickAlerter';
import { UserMultiSelect } from './UserMultiSelect';

const COLORS = [
	'#4241E4',
	'#2B9A5E',
	'#F59E0B',
	'#1D4ED8',
	'#15803D',
	'#DC2626',
	'#7C3AED',
	'#DB2777',
	'#0891B2',
	'#CA8A04',
];

interface CustomDotProps extends DotProps {
	value?: number;
	dataKey?: string;
	payload?: SchemaUserPerformanceByRoundResponseDto;
	getSessionUrl: (userId: string, sessionId: string, storyId: string) => string;
}

enum CustomDotStatus {
	NoSession = 'no-session',
	Completed = 'completed',
	WIP = 'wip',
}
export const CustomDot = (props: CustomDotProps) => {
	const { t } = useTranslation('performance');
	const {
		cx,
		cy,
		stroke,
		value: originalValue,
		payload,
		dataKey,
		getSessionUrl,
	} = props;
	const navigate = useNavigate();
	const wrapperRef = useRef(null);
	const userId = dataKey?.split('.')[0];
	const { open: isOpen, onOpen, onClose } = useDisclosure();
	useOutsideAlerter(wrapperRef, onClose);
	if (!cx || !cy || !userId) return <></>;

	const sessionInfo = payload?.[userId];
	const hasSessionInfo = !!sessionInfo?.sessionId;

	const value = parseInt((originalValue ?? 0).toString(), 10);
	const completedCount = sessionInfo?.tasksCompleted ?? 0;
	const totalCount = sessionInfo?.tasksTotal ?? 0;
	const status =
		completedCount === 0
			? CustomDotStatus.NoSession
			: completedCount === totalCount
				? CustomDotStatus.Completed
				: CustomDotStatus.WIP;

	return (
		<ChakraTooltip
			positioning={{ placement: 'top-start' }}
			ref={wrapperRef}
			open={isOpen}
			interactive
			disabled={!isOpen}
			content={
				<Flex bg="transparent" direction="column">
					<Flex>
						{value === -1
							? t('performance_chart_graph.no_session_yet')
							: t('performance_chart_graph.score', {
									score: value,
								})}
					</Flex>
					{hasSessionInfo && (
						<Flex direction="column">
							{sessionInfo.date && (
								<>
									{t('performance_chart_graph.date', {
										date: format(sessionInfo.date, 'MMM dd, yyyy'),
									})}
								</>
							)}
							{typeof sessionInfo.tasksCompleted === 'number' &&
								typeof sessionInfo.tasksTotal === 'number' && (
									<Flex>
										{t('performance_chart_graph.tasks_solved', {
											completed: sessionInfo.tasksCompleted,
											total: sessionInfo.tasksTotal,
										})}
									</Flex>
								)}
							<Flex
								onClick={() => {
									navigate(
										getSessionUrl(
											userId,
											sessionInfo?.sessionId as string,
											sessionInfo.missionId as string
										)
									);
								}}
								mt={2}
								color="#4241E4"
								cursor={'pointer'}
								textStyle="small-print"
							>
								{t('performance_chart_graph.view_feedback')}
							</Flex>
						</Flex>
					)}
				</Flex>
			}
		>
			{status === CustomDotStatus.Completed ? (
				<svg
					cursor={'pointer'}
					onClick={onOpen}
					x={cx - 6}
					y={cy - 6}
					width={12}
					height={12}
					fill="#2B9A5E"
					viewBox="0 0 24 24"
					style={{ stroke: 'white', strokeWidth: 2 }}
				>
					<path d="M12 0a12 12 0 100 24 12 12 0 000-24zm6.7 8.7l-8 8c-.2.2-.4.3-.7.3s-.5-.1-.7-.3l-4-4c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l3.3 3.3 7.3-7.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4z" />
				</svg>
			) : value === -1 ? (
				<svg
					cursor={'pointer'}
					onClick={onOpen}
					x={cx - 6}
					y={cy - 6}
					width={12}
					height={12}
					fill="gray"
					viewBox="0 0 24 24"
					style={{ stroke: 'white', strokeWidth: 2 }}
					data-testid={'no-session-svg'}
				/>
			) : (
				<circle
					cursor={'pointer'}
					onClick={onOpen}
					cx={cx}
					cy={cy}
					r={5}
					style={{ stroke: 'white', strokeWidth: 2 }}
					fill={value === -1 ? 'gray' : stroke}
				/>
			)}
		</ChakraTooltip>
	);
};

export type PerformanceChartProps = {
	data: any[];
	alwaysShownUserId?: string;
	shownUsers: string[];
	allEmpty?: boolean;
	teamMembers: {
		id: string;
		firstName?: string | null;
		lastName?: string | null;
	}[];
	selectedMission?: SchemaStoryResponseDto;
	missions: SchemaStoryResponseDto[];
	onMissionChange: (value: string) => void;
	onUsersChange?: (users: string[]) => void;
	multiSelect?: boolean;
	getSessionUrl: (userId: string, sessionId: string, storyId: string) => string;
};

export const PerformanceChart = ({
	data,
	alwaysShownUserId,
	shownUsers,
	teamMembers,
	selectedMission,
	missions,
	allEmpty,
	onMissionChange,
	onUsersChange,
	multiSelect,
	getSessionUrl,
}: PerformanceChartProps) => {
	const { t } = useTranslation('performance');

	const userListOptions = teamMembers
		?.filter((user) => user.id !== alwaysShownUserId)
		.map((user) => ({
			label: `${user.firstName} ${user.lastName}`,
			value: user.id,
		}));

	return (
		<Flex direction={{ base: 'column' }} gap="6">
			<Flex gap="5">
				{missions?.length > 0 && (
					<Stack direction="row" gap={1} className="w-full" alignItems="center">
						<Select
							role="select-mission"
							placeholder={t('performance_chart_graph.all_missions') ?? ''}
							items={[
								...missions.map((mission) => ({
									label: mission.title,
									value: mission.id,
								})),
							]}
							onValueChange={(value) => onMissionChange(value as string)}
							value={selectedMission?.id}
							minWidth="340px"
						/>
					</Stack>
				)}

				{multiSelect && (
					<UserMultiSelect
						placeholder={t('performance_chart_graph.select_users').toString()}
						items={userListOptions}
						value={userListOptions
							.filter((user) => shownUsers.includes(user.value))
							.map((user) => user.value)}
						onValueChange={(value) => {
							if (value) {
								const selectedUsers = (value as string[]).map((v) => v);
								onUsersChange?.(selectedUsers);
							}
						}}
					/>
				)}
			</Flex>
			<Box
				h={'450px'}
				p="5"
				borderRadius="16px"
				border="1px solid #D9D9D9"
				position="relative"
			>
				{allEmpty && (
					<Flex
						position="absolute"
						top="0"
						left="0"
						right="0"
						bottom="0"
						bg="rgba(0, 0, 0, 0.05)"
						alignItems="center"
						justifyContent="center"
						borderRadius="16px"
					>
						<Text>{t('performance_chart_graph.no_missions_played')}</Text>
					</Flex>
				)}
				<Text variant="label">
					{selectedMission
						? selectedMission?.title
						: t('performance_chart_graph.all_missions_title')}
				</Text>
				<Flex justify={'space-between'} align={'center'}>
					<Flex gap="1" direction="column-reverse" alignItems="center">
						<Text
							style={{
								writingMode: 'vertical-rl',
								transform: 'rotate(180deg)',
							}}
							variant="small-print"
						>
							{t('performance_chart_graph.total_score')}
						</Text>
						<ChakraTooltip
							content={t('performance_chart_graph.total_score_helper')}
						>
							<Info color="#757575" size={16} />
						</ChakraTooltip>
					</Flex>
					<ResponsiveContainer width={'100%'} height={350}>
						<LineChart
							data={data}
							margin={{ top: 5, right: 30, left: 0, bottom: 5 }}
						>
							<CartesianGrid horizontal={false} />
							<XAxis
								dataKey="round"
								tickLine={false}
								axisLine={false}
								width={0}
							/>
							<YAxis
								axisLine={false}
								tickLine={false}
								domain={[0, 105]}
								ticks={[0, 20, 40, 60, 80, 100]}
							/>
							{shownUsers.map((uid, index) => (
								<Line
									key={uid}
									type={'linear'}
									dataKey={`${uid}.score`}
									dot={<CustomDot getSessionUrl={getSessionUrl} />}
									isAnimationActive={false}
									connectNulls={false}
									stroke={COLORS[index % COLORS.length]}
								/>
							))}
						</LineChart>
					</ResponsiveContainer>
					<Flex gap="1" direction="column" w="170px">
						{shownUsers.map((uid, index) => {
							const user = teamMembers?.find((u) => u.id === uid);
							return (
								<Flex key={uid} gap="1" alignItems="center">
									<Circle
										weight="fill"
										size="12px"
										color={COLORS[index % COLORS.length]}
									/>
									<Text variant="small-print">
										{`${user?.firstName} ${user?.lastName}`}
									</Text>
								</Flex>
							);
						})}
					</Flex>
				</Flex>
				<Flex
					mt="2"
					gap="1"
					pr="16"
					justifyContent="center"
					textStyle="small-print"
				>
					<Text variant="small-print">
						{t('performance_chart_graph.rounds_played')}
					</Text>
					<ChakraTooltip
						content={t('performance_chart_graph.rounds_played_helper')}
					>
						<Info color="#757575" size={16} />
					</ChakraTooltip>
				</Flex>
			</Box>
		</Flex>
	);
};
