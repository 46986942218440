import { BasePage } from '@jam/front-library';
import { X } from '@phosphor-icons/react';
import { useParams } from 'react-router-dom';
import { useGoBack } from '../common/hooks/useGoBack';
import { MakerPageContent } from '../features/maker/components/MakerPageContent';
import { CopilotProvider } from '../providers/CopilotProvider';

export const MakerPage = ({ editMode }: { editMode: boolean }) => {
	const { missionId } = useParams();

	const onBack = useGoBack();

	if (!missionId) {
		return null;
	}

	return (
		<BasePage hideSidebar title={''} onBack={onBack} onBackIcon={X}>
			<CopilotProvider>
				<MakerPageContent baseStoryId={missionId} editMode={editMode} />
			</CopilotProvider>
		</BasePage>
	);
};
