import { VStack } from '@chakra-ui/react';
import {
	Button,
	DialogBackdrop,
	DialogBody,
	DialogCloseTrigger,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogRoot,
	DialogTitle,
	Text,
	Textarea,
} from '@jam/front-library';
import { useAuthInfo } from '@propelauth/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	RequestTypeId,
	useReportIssueMutation,
} from '../../../redux/api/commonApi';

interface RequestEditsModalProps {
	isOpen: boolean;
	onClose: () => void;
}

export const RequestEditsModal = ({
	isOpen,
	onClose,
}: RequestEditsModalProps) => {
	const { t } = useTranslation('common');
	const { user } = useAuthInfo();
	const [reportIssue] = useReportIssueMutation();

	const [request, setRequest] = useState('');

	const handleSubmit = async () => {
		if (!user) return;
		const currentUrl = window.location.href;

		const summary = `${user.firstName ?? ''} ${user.lastName ?? ''} requested edits`;
		const description = ` 
		\n ${request} 
		\n User email: ${user.email} 
		\n User first name: ${user.firstName ?? ''} 
		\n User last name: ${user.lastName ?? ''}   
		\n Current URL: ${currentUrl}`;

		await reportIssue({
			summary,
			description,
			requestTypeId: RequestTypeId.CONTENT_EDIT,
		});
		onClose();
		setRequest('');
	};

	return (
		<DialogRoot
			open={isOpen}
			onOpenChange={({ open }) => {
				if (!open) {
					onClose();
				}
			}}
		>
			<DialogBackdrop />
			<DialogContent>
				<DialogHeader>
					<DialogTitle textStyle="h4">
						{t('whatWouldYouLikeToChange')}
					</DialogTitle>
					<DialogCloseTrigger />
				</DialogHeader>
				<DialogBody>
					<VStack gap={4} align="stretch">
						<Textarea
							value={request}
							onChange={(e) => setRequest(e.target.value)}
							placeholder={t('enterYourRequestHere') ?? ''}
							minH="100px"
							resize="vertical"
						/>
						<Text variant="small-print">
							{t('requestsAreTypicallyImplementedWithin3WorkingDays')}
						</Text>
					</VStack>
				</DialogBody>
				<DialogFooter gap={2}>
					<Button variant="tertiary" onClick={onClose}>
						{t('cancel')}
					</Button>
					<Button
						onClick={() => {
							void handleSubmit();
						}}
						disabled={!request.trim()}
					>
						{t('submitRequest')}
					</Button>
				</DialogFooter>
			</DialogContent>
		</DialogRoot>
	);
};
