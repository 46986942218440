import { Box, Flex, SimpleGrid, Skeleton } from '@chakra-ui/react';
import { WeeklyCompletedRoundsChart } from './WeeklyCompletedRoundsChart';
import { WeeklyUsersChart } from './WeeklyUsersChart';
import { useGetWeeklyActivityQuery } from '../../../../../redux/api/dashboardApi';
import { useTranslation } from 'react-i18next';
import { Section } from '@jam/front-library';

const LoadingState = () => {
	return (
		<Flex direction={'column'} gap="4">
			<Box maxW="300px">
				<Skeleton borderRadius={'8px'} h="30px" flex={1} />
			</Box>
			<Flex gap="4" alignItems={'center'}>
				<Skeleton borderRadius={'8px'} h="350px" flex={1} />
				<Skeleton borderRadius={'8px'} h="350px" flex={1} />
			</Flex>
		</Flex>
	);
};

export const WeeklyActivity = ({ teamId }: { teamId: string | undefined }) => {
	const { t } = useTranslation('dashboard');
	const { data, isLoading } = useGetWeeklyActivityQuery(teamId);
	if (isLoading) return <LoadingState />;
	if (!data) return null;

	return (
		<Section title={t('team_activity_section.week_activity_title').toString()}>
			<SimpleGrid columns={2} columnGap={6}>
				<WeeklyCompletedRoundsChart
					completedSessions={data.completedSessions}
				/>
				<WeeklyUsersChart userActivity={data.activeUsers} />
			</SimpleGrid>
		</Section>
	);
};
