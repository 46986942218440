import { Box, Flex, Image, Separator, Stack } from '@chakra-ui/react';
import { SchemaStoryResponseDto } from '@jam/api-sdk/api';
import { CallBarFact, Text } from '@jam/front-library';
import { Clock, Star } from '@phosphor-icons/react';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetLearnerLastSessionPerStory } from '../../features/call/hooks/useGetLearnerLastSessionPerStory';
import { useDifficultyIcon } from '../../features/home/hooks/useDifficultyIcon';
import {
	getFutureRelativeDueDate,
	getFutureRelativeDueDateAsText,
} from '../utils/dateUtil';

const FALLBACK_AVATAR_URL = '/avatars/avatar.png';

type CallBarProps = {
	story?: SchemaStoryResponseDto;
	dueDate?: Date;
	userId: string | null;
	renderStoryButton?: (storyId: string) => ReactElement;
};

const difficultyLangProps: Record<string, string> = {
	easy: 'story:easy',
	intermediate: 'story:intermediate',
	medium: 'story:medium',
	hard: 'story:hard',
};

export const CallBar = ({
	story,
	userId,
	renderStoryButton,
	dueDate,
}: CallBarProps) => {
	const { t } = useTranslation(['story', 'common']);
	const lastSession = useGetLearnerLastSessionPerStory(story?.id, userId);
	const difficultyIcon = useDifficultyIcon(story?.difficulty);
	const persona = story?.persona;
	const avatar = persona?.avatar_file.url ?? FALLBACK_AVATAR_URL;

	const relativeDueDate = dueDate ? getFutureRelativeDueDate(dueDate) : null;

	const dueDateText = relativeDueDate
		? getFutureRelativeDueDateAsText(relativeDueDate, t)
		: null;
	return (
		<Flex
			direction="row"
			align="center"
			justify="space-between"
			borderWidth="1px"
			borderRadius="lg"
			p={4}
			bg="white"
		>
			<Flex direction="column" align="center" flexShrink={0} width="140px">
				<Image
					alt="persona avatar"
					w="140px"
					h="100%"
					objectFit="cover"
					borderRadius={'12px'}
					src={avatar}
				/>
			</Flex>
			<Flex direction="column" ml={4} flex={1} gap={5}>
				<Flex
					direction="row"
					justify={'space-between'}
					align={'center'}
					gap={3}
				>
					<Box fontWeight={'bold'} flexShrink={1}>
						<Flex gap={1} alignItems={'center'}>
							<Text variant="h2" bold>
								{story?.title ?? t('story_deleted')}
							</Text>
							{dueDateText && (
								<Text
									variant="label-bold"
									color={
										dueDate && dueDate <= new Date() ? 'red.500' : 'primary.500'
									}
								>
									{dueDateText}
								</Text>
							)}
						</Flex>
					</Box>
					{story && renderStoryButton ? (
						<Box height={'fit-content'} flexShrink={0}>
							{renderStoryButton(story.id)}
						</Box>
					) : null}
				</Flex>
				<Stack
					flex={2}
					direction="row"
					alignItems="center"
					gap={4}
					separator={<Separator orientation="vertical" height="50px" />}
				>
					{persona && (
						<CallBarFact
							label={`${persona.role} ${persona.workplace_name ? '@' : ''}${persona.workplace_name}`}
							value={persona.name}
						/>
					)}
					<CallBarFact
						icon={
							difficultyIcon && <Image src={difficultyIcon} width={'24px'} />
						}
						label={t('difficulty_label')}
						value={t(difficultyLangProps[story?.difficulty ?? 'easy'])}
					/>
					<CallBarFact
						icon={<Clock size={24} />}
						label={t('duration_label')}
						value={t('duration_value', {
							duration: story?.scenario.duration ?? 0,
						})}
					/>
					<CallBarFact
						icon={<Star size={24} />}
						label={t('common:last_round_result')}
						value={lastSession?.analysis.score}
					/>
				</Stack>
			</Flex>
		</Flex>
	);
};
