import { Box, Flex, Stack } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
	Button,
	Chip,
	DialogBackdrop,
	DialogBody,
	DialogCloseTrigger,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogRoot,
	DialogTitle,
	Field,
	Text,
	Textarea,
} from '@jam/front-library';
import { Flag } from '@phosphor-icons/react';
import { useAuthInfo } from '@propelauth/react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { z } from 'zod';
import {
	RequestTypeId,
	useReportIssueMutation,
} from '../../redux/api/commonApi';
import {
	selectReportIssueModalIsOpen,
	setReportIssueModalIsOpen,
} from '../../redux/slice';
import { AppDispatch } from '../../redux/store';

const ISSUE_ELEMENTS: Record<string, string> = {
	app_keeps_crashing: 'app_keeps_crashing',
	cannot_start_roleplay: 'cannot_start_roleplay',
	button_is_broken: 'button_is_broken',
	audio_not_working: 'audio_not_working',
	app_is_confusing: 'app_is_confusing',
};

type ReportIssueForm = {
	issues: string[];
	feedback: string;
};

const ReportIssueSchema = z.object({
	issues: z.array(
		z.enum(Object.values(ISSUE_ELEMENTS) as [string, ...string[]])
	),
	feedback: z.string().min(1),
});

const reportIssuesLangProps: Record<string, string> = {
	app_keeps_crashing: 'report_issue_modal.issues.app_keeps_crashing',
	cannot_start_roleplay: 'report_issue_modal.issues.cannot_start_roleplay',
	button_is_broken: 'report_issue_modal.issues.button_is_broken',
	audio_not_working: 'report_issue_modal.issues.audio_not_working',
	app_is_confusing: 'report_issue_modal.issues.app_is_confusing',
};

export const ReportIssueModal = () => {
	const { t } = useTranslation('common');
	const { user } = useAuthInfo();
	const [reportIssue] = useReportIssueMutation();
	const reportIssueModalIsOpen = useSelector(selectReportIssueModalIsOpen);
	const dispatch = useDispatch<AppDispatch>();

	const { register, handleSubmit, watch, getValues, setValue } =
		useForm<ReportIssueForm>({
			resolver: zodResolver(ReportIssueSchema),
			defaultValues: {
				issues: [],
				feedback: '',
			},
		});

	const onClose = () => {
		setValue('issues', []);
		setValue('feedback', '');
		dispatch(setReportIssueModalIsOpen(false));
	};

	const onItemClicked = (issue: string) => {
		const issues = getValues('issues');
		if (issues.includes(issue)) {
			setValue(
				'issues',
				issues.filter((i) => i !== issue)
			);
		} else {
			setValue('issues', [...issues, issue]);
		}
	};

	const onSubmit = async (data: ReportIssueForm) => {
		if (!user) return;
		const issues = data.issues.join(',');
		const feedback = data.feedback;
		const currentUrl = window.location.href;

		const summary = `User reported an issue: ${issues}`;
		const description = `User reported an issue: 
		\n ${feedback} 
		\n User email: ${user.email} 
		\n User first name: ${user.firstName ?? ''} 
		\n User last name: ${user.lastName ?? ''} 
		\n Current URL: ${currentUrl}`;

		await reportIssue({
			summary,
			description,
			requestTypeId: RequestTypeId.BUG_REPORT,
		});
		onClose();
	};

	const feedback = watch('feedback');
	const issues = watch('issues');

	return (
		<DialogRoot
			size="lg"
			open={reportIssueModalIsOpen}
			onOpenChange={({ open }) => {
				if (!open) {
					onClose();
				}
			}}
		>
			<DialogBackdrop />
			<DialogContent>
				<DialogHeader>
					<DialogTitle textStyle="h4">
						<Flex alignItems={'center'} gap="2">
							<Flag weight="bold" size={24} />
							{t('report_issue_modal.title')}
						</Flex>
						<Box mt={2}>
							<Text variant="small-print">
								{t('report_issue_modal.subtitle')}
							</Text>
						</Box>
					</DialogTitle>
					<DialogCloseTrigger />
				</DialogHeader>
				<DialogBody pb={5}>
					<Stack gap={3}>
						<Flex flexWrap={'wrap'} gap="3">
							{Object.entries(ISSUE_ELEMENTS).map((element) => (
								<Chip
									onClick={() => onItemClicked(element[0])}
									key={element[0]}
									cursor={'pointer'}
									textColor={
										issues.includes(element[0]) ? '#4241E4' : '#757575'
									}
									border={'1px solid'}
									borderColor={
										issues.includes(element[0]) ? '#4241E4' : '#D9D9D9'
									}
									px="2"
									bg={
										issues.includes(element[0])
											? 'rgba(66, 65, 228, 1, 0.08)'
											: 'transparent'
									}
									borderRadius={'24px'}
									text={t(reportIssuesLangProps[element[1]])}
								/>
							))}
						</Flex>
						<Field as="fieldset">
							<Textarea
								{...register('feedback')}
								placeholder={t('report_issue_modal.placeholder') ?? ''}
								size="sm"
							/>
						</Field>
					</Stack>
				</DialogBody>
				<DialogFooter>
					<Button
						id="submit-negative-feedback-button"
						type="submit"
						onClick={() => {
							if (feedback.length > 0) void handleSubmit(onSubmit)();
						}}
						disabled={!feedback?.length}
					>
						{t('submit')}
					</Button>
				</DialogFooter>
			</DialogContent>
		</DialogRoot>
	);
};
