import { Box, Flex, SimpleGrid, Skeleton } from '@chakra-ui/react';
import { SchemaCompletionsWithTrendsResponseDto } from '@jam/api-sdk/api';
import { CompletionStat, Section } from '@jam/front-library';
import { useTranslation } from 'react-i18next';

const LoadingState = () => {
	return (
		<Flex role="placeholder" direction={'column'} gap="4">
			<Box maxW="300px">
				<Skeleton borderRadius={'8px'} h="30px" flex={1} />
			</Box>
			<Flex gap="4" alignItems={'center'}>
				<Skeleton borderRadius={'8px'} h="150px" flex={1} />
				<Skeleton borderRadius={'8px'} h="150px" flex={1} />
				<Skeleton borderRadius={'8px'} h="150px" flex={1} />
				<Skeleton borderRadius={'8px'} h="150px" flex={1} />
			</Flex>
		</Flex>
	);
};

type CompletionStatsProps = {
	title: string;
	data: SchemaCompletionsWithTrendsResponseDto | undefined;
	isLoading: boolean;
};

export const CompletionStats = ({
	title,
	data,
	isLoading,
}: CompletionStatsProps) => {
	const { t } = useTranslation('dashboard');

	if (isLoading) return <LoadingState />;
	if (!data) return null;

	return (
		<Section title={title}>
			<SimpleGrid columns={3} gap={5}>
				<CompletionStat
					title={t('team_activity_section.rounds_completed')}
					hint={t('team_activity_section.rounds_completed_hint')}
					completions={data.overall.sessionsCompleted}
					delta={data.trends.sessionsCompleted}
					deltaText={t('team_activity_section.last_week_trend', {
						delta: data.trends.sessionsCompleted,
					})}
				/>
				<CompletionStat
					title={t('team_activity_section.missions_completed')}
					hint={t('team_activity_section.missions_completed_hint')}
					completions={data.overall.storiesCompleted}
					delta={data.trends.storiesCompleted}
					deltaText={t('team_activity_section.last_week_trend', {
						delta: data.trends.storiesCompleted,
					})}
				/>
				<CompletionStat
					title={t('team_activity_section.average_score')}
					hint={t('team_activity_section.average_score_hint')}
					completions={
						data.overall.averageScore
							? Math.round(data.overall.averageScore)
							: 0
					}
					delta={
						(data.trends.averageScore ?? 0) - (data.overall.averageScore ?? 0)
					}
					deltaText={t('team_activity_section.last_week_trend', {
						delta: data.trends.averageScore
							? Math.round(data.trends.averageScore)
							: 0,
					})}
				/>
			</SimpleGrid>
		</Section>
	);
};
