import { Box, Flex, Separator } from '@chakra-ui/react';
import {
	Button,
	DialogBackdrop,
	DialogBody,
	DialogContent,
	DialogFooter,
	DialogRoot,
	Text,
} from '@jam/front-library';
import { useTranslation } from 'react-i18next';

type LeaveWarningModalProps = {
	isOpen: boolean;
	onExit: () => void;
	onContinue: () => void;
};

export const LeaveWarningModal = ({
	onExit,
	onContinue,
	isOpen,
}: LeaveWarningModalProps) => {
	const { t } = useTranslation('call', { keyPrefix: 'leave_warning_modal' });
	return (
		<DialogRoot
			size={'md'}
			open={isOpen}
			onOpenChange={({ open }) => {
				if (!open) {
					onContinue();
				}
			}}
		>
			<DialogBackdrop />
			<DialogContent>
				<DialogBody>
					<Flex
						py="4"
						gap={3}
						justifyContent={'center'}
						alignContent={'center'}
						direction={'column'}
						textAlign={'center'}
					>
						<Text variant="h3">{t('title')}</Text>
						<Text>{t('text')}</Text>
					</Flex>
				</DialogBody>
				<Separator />
				<DialogFooter>
					<Flex w="100%" gap="3">
						<Box flex={1}>
							<Button
								variant="secondary"
								size={'full'}
								id="exit-button"
								type="submit"
								onClick={onExit}
							>
								{t('exit_mission')}
							</Button>
						</Box>
						<Box flex={1}>
							<Button
								size={'full'}
								id="keep-practicing-button"
								onClick={onContinue}
							>
								{t('keep_practicing')}
							</Button>
						</Box>
					</Flex>
				</DialogFooter>
			</DialogContent>
		</DialogRoot>
	);
};
