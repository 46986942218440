import { useCoAgentStateRender } from '@copilotkit/react-core';
import { IAgentState } from './IAgentState';

export const renderProgress = ({
	state,
	status,
}: {
	state: IAgentState;
	status: 'inProgress' | 'complete';
}) => {
	if (status === 'complete') {
		return null;
	}

	const logs = state.logs || [];
	if (logs.length === 0) {
		return null;
	}

	const logStatus = logs
		.map((logLine) => {
			return `${logLine.done ? '✅' : '❌'} ${logLine.message}`;
		})
		.join('\n\n');

	return 'Working on it...\n\n' + logStatus;
};

export const useMakerProgress = () => {
	useCoAgentStateRender<IAgentState>({
		name: 'contextualize',
		render: renderProgress,
	});
};
