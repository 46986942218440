import { Flex } from '@chakra-ui/react';
import {
	SchemaSessionResponseDto,
	SchemaStoryResponseDto,
} from '@jam/api-sdk/api';
import {
	PageContent,
	TabsContent,
	TabsIndicator,
	TabsList,
	TabsRoot,
	TabsTrigger,
} from '@jam/front-library';
import { useTranslation } from 'react-i18next';
import { MoreInfoTab } from '../call/components/more-info/MoreInfoTab';
import { SessionFeedback } from '../call/components/SessionFeedback';
import { Transcript } from '../call/components/Transcript';

type RealCallDetailsProps = {
	scorecard?: SchemaStoryResponseDto['scorecard'];
	session?: SchemaSessionResponseDto | null;
};

export const RealCallDetails = ({
	scorecard,
	session,
}: RealCallDetailsProps) => {
	const { t } = useTranslation('call');

	return (
		<TabsRoot defaultValue="feedback" lazyMount>
			<TabsList minW={'300px'}>
				<TabsTrigger value="feedback">{t('feedback')}</TabsTrigger>
				<TabsTrigger value="scorecard">{t('scorecard')}</TabsTrigger>
				<TabsIndicator />
			</TabsList>
			<Flex w="100%" gap={4}>
				<TabsContent value="feedback" w="100%">
					<PageContent>
						<SessionFeedback session={session} />
					</PageContent>
				</TabsContent>
				<TabsContent value="scorecard" w="100%">
					<PageContent>
						<MoreInfoTab scorecard={scorecard} />
					</PageContent>
				</TabsContent>
				<Transcript session={session} />
			</Flex>
		</TabsRoot>
	);
};
