import { Flex, IconButton, List, Stack } from '@chakra-ui/react';
import { SchemaJourneyResponseDto } from '@jam/api-sdk/api';
import {
	Button,
	DialogBackdrop,
	DialogBody,
	DialogCloseTrigger,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogRoot,
	DialogTitle,
	Text,
} from '@jam/front-library';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowSquareOut } from '@phosphor-icons/react';

type AssignContentModalProps = {
	journey: SchemaJourneyResponseDto;
	isOpen: boolean;
	onClose: (assign?: boolean) => void;
};

export const JourneyDetailsModal = ({
	journey,
	isOpen,
	onClose,
}: AssignContentModalProps) => {
	const { t } = useTranslation('coaching');
	const contentRef = React.useRef<HTMLDivElement>(null);

	const onGoToJourney = (journeyId: string) => {
		window.open(`/journeys?openJourneyId=${journeyId}`, '_blank');
	};
	if (!journey) return null;

	return (
		<DialogRoot
			size="sm"
			open={isOpen}
			onOpenChange={({ open }) => {
				if (!open) {
					onClose();
				}
			}}
		>
			<DialogBackdrop />
			<DialogContent ref={contentRef}>
				<DialogHeader>
					<DialogTitle textStyle="h2">{t('tracks.dialog.title')}</DialogTitle>
					<DialogCloseTrigger />
				</DialogHeader>
				<DialogBody>
					<Stack gap={4}>
						<Stack gap={2}>
							<Flex
								direction="row"
								w="100%"
								alignItems="center"
								justifyContent="space-between"
							>
								<Text variant="label-bold">{journey.title}</Text>
								<IconButton
									borderRadius="full"
									bg="white"
									color="gray.400"
									onClick={() => onGoToJourney(journey.id)}
								>
									<ArrowSquareOut width={24} height={24} />
								</IconButton>
							</Flex>
							<Text variant="small-print">{journey.description}</Text>
						</Stack>
						<Text variant="label-bold">
							{t('tracks.dialog.missions_included')}
						</Text>
						<List.Root gap={4}>
							{journey.stories?.map((story) => {
								return (
									<List.Item key={story.id} textStyle="label">
										{story.title}
									</List.Item>
								);
							})}
						</List.Root>
					</Stack>
				</DialogBody>
				<DialogFooter>
					<Button
						width="100%"
						id="assign-content-button"
						type="submit"
						onClick={() => {
							onClose(true);
						}}
					>
						{t('tracks.dialog.assign_content')}
					</Button>
				</DialogFooter>
			</DialogContent>
		</DialogRoot>
	);
};
