import { Box, Flex, IconButton, Image } from '@chakra-ui/react';
import { Button, Section, Text } from '@jam/front-library';
import { CaretLeft, CaretRight } from '@phosphor-icons/react/dist/ssr';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../../redux/slice';
import { submitGetInspiredClickEvents } from '../../tracking/util/submitElementClickEvents';

type InspiringNews = {
	title: string;
	tag: string;
	image: string;
	url: string;
	readTime: string;
};

const inspiringNewsListDe: InspiringNews[] = [
	{
		title: '6 Tipps für Strategien bei der Verkaufsverhandlung',
		tag: 'Vertriebszeitung',
		url: 'https://vertriebszeitung.de/6-tipps-fuer-strategien-bei-der-verkaufsverhandlung/',
		image: '/images/ai2.png',
		readTime: '4 min',
	},
	{
		title: 'Telefonakquise – Die ersten 10 Worte sind entscheidend',
		tag: 'Alexander Verweyen',
		url: 'https://www.alexanderverweyen.com/news/telefonakquise-die-ersten-10-worte-sind-entscheidend/',
		image: '/images/ai2.png',
		readTime: '4 min',
	},
	{
		url: 'https://www.salesforce.com/de/blog/verkaufsabschluss/',
		title: 'Verkaufsabschlüsse erzielen – Sechs Methoden, die funktionieren',
		tag: 'Salesforce',
		image: '/images/ai2.png',
		readTime: '4 min',
	},
	{
		url: 'https://www.pipedrive.com/de/blog/sales-skills',
		title:
			'13 wichtige Sales Skills für Vertriebler (und wie Sie sie erlernen)',
		tag: 'Pipedrive',
		image: '/images/ai2.png',
		readTime: '4 min',
	},
	{
		url: 'https://blog.hubspot.de/sales/einwandbehandlung-preis',
		title: '„Zu teuer!“: Wie Vertriebsmitarbeiter Einwände entkräften können',
		tag: 'Hubspot',
		image: '/images/ai2.png',
		readTime: '4 min',
	},
];

const inspiringNewsListEn: InspiringNews[] = [
	{
		title:
			'10 Common Cold Calling Blunders You Need to Avoid at All Costs, According to Experts',
		tag: 'Hubspot',
		url: 'https://blog.hubspot.com/sales/cold-call-blunders',
		image: '/images/ai2.png',
		readTime: '4 min',
	},
	{
		title: '28 Questions to Ask on a Discovery Call During the Sales Process',
		tag: 'Hubspot',
		url: 'https://blog.hubspot.com/sales/discovery-call-questions',
		image: '/images/ai2.png',
		readTime: '4 min',
	},
	{
		url: 'https://www.salesforce.com/eu/blog/sales-closing-techniques/',
		title: 'How to Close a Sale (6 Sales Closing Techniques That Work)',
		tag: 'Salesforce',
		image: '/images/ai2.png',
		readTime: '4 min',
	},
	{
		url: 'https://www.zendesk.de/blog/sales-negotiation/',
		title: 'Sales negotiation skills and strategies to win more deals',
		tag: 'Zendesk',
		image: '/images/ai2.png',
		readTime: '4 min',
	},
	{
		url: 'https://www.cognism.com/blog/sales-objections',
		title: '20 Examples of Common Sales Objections & Responses (2024)',
		tag: 'Cognism',
		image: '/images/ai2.png',
		readTime: '4 min',
	},
];

export const GetInspired = () => {
	const { t } = useTranslation('home');
	const [scrollPosition, setScrollPosition] = useState(0);
	const language = useSelector(selectLanguage);
	const inspiringNews =
		language === 'en' ? inspiringNewsListEn : inspiringNewsListDe;

	return (
		<Box>
			<Section title={t('get_inspired').toString()}>
				<Flex gap={4} maxH={'160px'}>
					<Flex
						direction="column"
						flexShrink={0}
						gap={1}
						color="white"
						py={3}
						px={2}
						borderRadius="lg"
						maxW="112px"
						css={{
							background: 'linear-gradient(to bottom, black, #4241E4)',
						}}
					>
						<Image
							w={'36px'}
							h={'36px'}
							src="/icons/documents.svg"
							alt="documents"
							mb={1}
						/>
						<Text variant="x-small-print" color="white">
							{t('discover_more')}
						</Text>
						<Text variant="x-small-print" color="white">
							{t('discover_more_subtitle')}
						</Text>
					</Flex>

					<Flex position={'relative'} flexGrow={1} overflow={'hidden'}>
						<IconButton
							position={'absolute'}
							top={'50%'}
							border={'1px solid'}
							borderColor={'gray.300'}
							right={'10px'}
							size="xs"
							transform={'translateY(-50%)'}
							borderRadius={'full'}
							bg={'gray.50'}
							_hover={{ bg: '#EDF2F7' }}
							onClick={(e) => {
								e.preventDefault();
								document.getElementById('scroll-container')?.scrollBy({
									left: 300,
									behavior: 'smooth',
								});
								setScrollPosition(scrollPosition + 300);
							}}
							aria-label={''}
						>
							<CaretRight color="black" />
						</IconButton>
						<IconButton
							position={'absolute'}
							top={'50%'}
							border={'1px solid'}
							borderColor={'gray.300'}
							left={'10px'}
							size="xs"
							borderRadius={'full'}
							aria-label={''}
							transform={'translateY(-50%)'}
							bg={'gray.50'}
							_hover={{ bg: '#EDF2F7' }}
							onClick={(e) => {
								e.preventDefault();
								// scroll div by id scroll-container
								document.getElementById('scroll-container')?.scrollBy({
									left: -300,
									behavior: 'smooth',
								});
								setScrollPosition(scrollPosition - 300);
							}}
						>
							<CaretLeft color="black" />
						</IconButton>
						<Flex
							id="scroll-container"
							overflowX="auto"
							css={{
								'&::-webkit-scrollbar': { display: 'none' },
								scrollbarWidth: 'none',
							}}
						>
							<Flex gap={4} flexGrow={1}>
								{inspiringNews.map((news, index) => (
									<Flex
										as="a"
										{...({
											href: news.url,
											target: '_blank',
											rel: 'noopener noreferrer',
										} as any)}
										rel="noopener noreferrer"
										cursor="pointer"
										key={index}
										minW="300px"
										bg="white"
										borderRadius="lg"
										border="1px solid"
										borderColor="gray.300"
										overflow="hidden"
										height={'100%'}
										onClick={() => {
											submitGetInspiredClickEvents(news);
										}}
									>
										<Flex
											p={4}
											gap={3}
											w="100%"
											justifyContent={'space-between'}
											height={'100%'}
										>
											<Flex direction={'column'} gap="2" height={'100%'}>
												<Flex direction={'column'} gap="2" flexGrow={1}>
													<Text lineClamp={2} variant="label">
														{news.title}
													</Text>
													<Flex gap={3} align="center">
														<Text variant="small-print">{news.tag}</Text>
														<Text variant="small-print">•</Text>
														<Text variant="small-print">{news.readTime}</Text>
													</Flex>
												</Flex>

												<Button variant="tertiary" size="sm">
													{t('read_more')}
												</Button>
											</Flex>
											<Image
												src={news.image}
												alt={news.title}
												h="56px"
												w="56px"
												borderRadius="lg"
												objectFit="cover"
											/>
										</Flex>
									</Flex>
								))}
							</Flex>
						</Flex>
					</Flex>
				</Flex>
			</Section>
		</Box>
	);
};
