import { TFunction } from 'i18next';

export type TimeUnit =
	| 'year'
	| 'month'
	| 'week'
	| 'day'
	| 'hour'
	| 'minute'
	| 'second';

// Not using dynamic keys for static analysis tools
const datesLangDict: Record<TimeUnit, string> = {
	year: 'common:dates.years',
	month: 'common:dates.months',
	week: 'common:dates.weeks',
	day: 'common:dates.days',
	hour: 'common:dates.hours',
	minute: 'common:dates.minutes',
	second: 'common:dates.seconds',
};
export function dateAsNaturalLanguage(date: string): {
	unit: TimeUnit;
	value: number;
} {
	const diffInSeconds = Math.floor(
		(Date.now() - new Date(date).getTime()) / 1000
	);

	// Define the units using the TimeUnit type
	const units: { unit: TimeUnit; seconds: number }[] = [
		{ unit: 'year', seconds: 31536000 },
		{ unit: 'month', seconds: 2592000 },
		{ unit: 'week', seconds: 604800 },
		{ unit: 'day', seconds: 86400 },
		{ unit: 'hour', seconds: 3600 },
		{ unit: 'minute', seconds: 60 },
		{ unit: 'second', seconds: 1 }, // Added seconds for completeness
	];

	for (const { unit, seconds } of units) {
		const count = Math.floor(diffInSeconds / seconds);
		if (count >= 1) {
			return { unit, value: count };
		}
	}

	// Fallback to 'second' if the difference is less than a second
	return { unit: 'second', value: diffInSeconds };
}

export function dateAgoAsString(t: TFunction, date: string): string {
	// if future -> return '', should not be used for future dates
	if (new Date(date) > new Date()) {
		return '';
	}
	const { unit, value } = dateAsNaturalLanguage(date);

	const unitTranslation = t(datesLangDict[unit]);

	return t('common:dates.units_ago', { count: value, unit: unitTranslation });
}

/**
 * Represents a relative due date that can be easily internationalized
 */
interface RelativeDueDate {
	value: number; // The numeric value (e.g., 3 for "3 weeks")
	unit: 'year' | 'month' | 'week' | 'day' | 'hour' | 'minute' | 'second';
	isPast: boolean; // Whether the date is in the past
}

/**
 * Calculates how far in the future (or past) a due date is,
 * returning a data structure suitable for internationalization.
 * Uses the largest appropriate time unit following standard practices.
 *
 * @param dueDate The due date to compare against current time
 * @param now Optional reference date (defaults to current time)
 * @returns A structure representing the relative time
 */
export function getFutureRelativeDueDate(
	dueDate: Date,
	now: Date = new Date()
): RelativeDueDate {
	const diffMs = dueDate.getTime() - now.getTime();
	const isPast = diffMs < 0;
	const absDiffMs = Math.abs(diffMs);

	// Time constants in milliseconds
	const SECOND = 1000;
	const MINUTE = 60 * SECOND;
	const HOUR = 60 * MINUTE;
	const DAY = 24 * HOUR;
	const WEEK = 7 * DAY;
	const MONTH = 30 * DAY; // Approximate
	const YEAR = 365 * DAY; // Approximate

	// Determine the appropriate unit
	if (absDiffMs >= YEAR) {
		return {
			value: Math.floor(absDiffMs / YEAR),
			unit: 'year',
			isPast,
		};
	} else if (absDiffMs >= MONTH) {
		return {
			value: Math.floor(absDiffMs / MONTH),
			unit: 'month',
			isPast,
		};
	} else if (absDiffMs >= WEEK) {
		return {
			value: Math.floor(absDiffMs / WEEK),
			unit: 'week',
			isPast,
		};
	} else if (absDiffMs >= DAY) {
		return {
			value: Math.floor(absDiffMs / DAY),
			unit: 'day',
			isPast,
		};
	} else if (absDiffMs >= HOUR) {
		return {
			value: Math.floor(absDiffMs / HOUR),
			unit: 'hour',
			isPast,
		};
	} else if (absDiffMs >= MINUTE) {
		return {
			value: Math.floor(absDiffMs / MINUTE),
			unit: 'minute',
			isPast,
		};
	} else {
		return {
			value: Math.floor(absDiffMs / SECOND),
			unit: 'second',
			isPast,
		};
	}
}

export function getFutureRelativeDueDateAsText(
	relativeDueDate: RelativeDueDate,
	t: TFunction
): string {
	const { value, unit, isPast } = relativeDueDate;

	// Get the localized unit string using the dictionary
	const unitTranslation = t(datesLangDict[unit]);

	if (isPast) {
		// Format for past dates (e.g., "3 weeks ago")
		return t('common:dates.units_ago', { count: value, unit: unitTranslation });
	} else {
		// Format for future dates (e.g., "due in 3 weeks")
		return t('common:dates.units_in', { count: value, unit: unitTranslation });
	}
}
