import { Separator } from '@chakra-ui/react';
import { useAuthInfo } from '@propelauth/react';
import { AccountDataForm } from './AccountDataForm';
import { LanguageChangeForm } from './LanguageChangeForm';
import { PasswordChangeForm } from './PasswordChangeForm';

export const PersonalSettingsTab = () => {
	const { user } = useAuthInfo();
	if (!user) return null;
	const { firstName, lastName, email, pictureUrl } = user;
	const language = user.properties?.language === 'English' ? 'en' : 'de';

	return (
		<>
			<AccountDataForm
				pictureUrl={pictureUrl ?? ''}
				firstName={firstName}
				lastName={lastName}
				email={email}
			/>
			<Separator />
			<PasswordChangeForm />
			<Separator />
			<LanguageChangeForm language={language} />
		</>
	);
};
