import { Link } from 'react-router-dom';
import { Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface Props {
	href: string;
	text?: string;
}
export function SeeAllButton({ href, text }: Props) {
	const { t } = useTranslation();
	return (
		<Button
			variant="secondary"
			as={Link}
			{...({
				to: href,
			} as any)}
			bg={'#EDF2F7'}
			size="sm"
			color={'#0b1215'}
			border={'1px solid'}
			borderColor={'gray.300'}
		>
			{text ?? t('home:see_all')}
		</Button>
	);
}
