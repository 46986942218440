import { TableCells } from '@jam/front-library';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { dateAgoAsString } from '../../../../utils/dateUtil';

export const CreatedAtCell: React.FC<{
	row: {
		createdAt: string;
	};
}> = ({ row }) => {
	const { t } = useTranslation();
	if (!row.createdAt) return null;
	return (
		<TableCells.BaseCell>
			{dateAgoAsString(t, row.createdAt)}
		</TableCells.BaseCell>
	);
};
