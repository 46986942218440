import { Box, Flex } from '@chakra-ui/react';
import {
	Button,
	DatePicker,
	DialogBackdrop,
	DialogBody,
	DialogCloseTrigger,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogRoot,
	DialogTitle,
	Text,
} from '@jam/front-library';
import { ArrowsCounterClockwise } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { z, ZodType } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';

type AssignMissionForm = {
	date: Date;
	userId: string;
	missionId: string;
};

const AssignMissionSchema: ZodType<AssignMissionForm> = z.object({
	date: z.date(),
	userId: z.string().min(1),
	missionId: z.string().min(1),
});

interface Props {
	open?: boolean;
	setOpen?: (open: boolean) => void;
	userId: string;
	missionId: string;
	onSubmission?: (date: Date) => Promise<void>;
}

const nextWeek = () => {
	const d = new Date();
	d.setDate(d.getDate() + 7);
	return d;
};

export const AssignMissionsDialog: React.FC<Props> = ({
	open,
	setOpen,
	userId,
	missionId,
	onSubmission,
}) => {
	const { t } = useTranslation('common');
	//by default a week from now
	const [date, setDate] = React.useState<Date | null>(nextWeek());
	const [today] = React.useState(new Date());

	const { handleSubmit, formState } = useForm<AssignMissionForm>({
		resolver: zodResolver(AssignMissionSchema),
		defaultValues: {
			date: date ?? nextWeek(),
			userId,
			missionId,
		},
	});
	const onSubmit = async () => {
		if (!date) return;
		await onSubmission?.(date);
		setOpen?.(false);
	};

	return (
		<DialogRoot
			size="xs"
			open={open}
			onOpenChange={({ open: dialogOpen }) => {
				setOpen?.(dialogOpen);
			}}
		>
			<DialogBackdrop />
			<DialogContent>
				<DialogHeader>
					<DialogTitle textStyle="h4">
						<Flex alignItems={'center'} gap="2">
							{t('common:assign_content_dialog.title')}
						</Flex>
						<Box mt={2}>
							<Text variant="small-print">
								{t('common:assign_content_dialog.description')}
							</Text>
						</Box>
					</DialogTitle>
					<DialogCloseTrigger />
				</DialogHeader>
				<DialogBody>
					<Flex
						alignItems="center"
						justifyContent="center"
						direction="column"
						gap={4}
					>
						<DatePicker
							minDate={today}
							selectedDate={date}
							onChange={(newDate: Date | null) => setDate(newDate)}
							inline
							highlightToday
						/>
						<Flex
							textStyle="label"
							direction="row"
							justifyContent="start"
							w="100%"
							color="#757575"
							cursor="pointer"
							gap={1}
							onClick={() => setDate(new Date())}
						>
							<ArrowsCounterClockwise />
							{t('common:assign_content_dialog.reset_to_today')}
						</Flex>
					</Flex>
				</DialogBody>
				<DialogFooter>
					<Button
						id="submit-negative-feedback-button"
						type="submit"
						onClick={() => {
							void handleSubmit(onSubmit)();
						}}
						disabled={formState.isSubmitting || !formState.isValid}
						width="100%"
					>
						{t('common:assign_content_dialog.cta')}
					</Button>
				</DialogFooter>
			</DialogContent>
		</DialogRoot>
	);
};
