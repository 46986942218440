import { ReactElement } from 'react';
import { TrackAccordion } from '../../../../../../common/components/track-detail/TrackAccordion';
import { useGetJourneyQuery } from '../../../../../../redux/api/cmsApi';
import { SchemaAssignedContentResponseDto } from '@jam/api-sdk/api';

type TrackAssignmentProps = {
	journeyId: string;
	contentAllocation?: SchemaAssignedContentResponseDto;

	userName?: string;

	userId?: string;
	renderStoryButton?: (storyId: string) => ReactElement;
};

export const TrackAssignment = ({
	journeyId,
	userId,
	userName,
	contentAllocation,
	renderStoryButton,
}: TrackAssignmentProps) => {
	const { data: journey, isLoading } = useGetJourneyQuery(journeyId, {
		skip: !journeyId,
	});

	if (isLoading) {
		return <div>Loading...</div>;
	}

	if (!journey) {
		return null;
	}

	return (
		<TrackAccordion
			userName={userName}
			userId={userId}
			journey={journey}
			contentAllocation={contentAllocation}
			renderStoryButton={renderStoryButton}
		/>
	);
};
