import { toaster } from '@jam/front-library';
import Vapi from '@vapi-ai/web';
import { AssistantOverrides, CreateAssistantDTO } from '@vapi-ai/web/dist/api';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../redux/hooks';
import {
	selectCurrentCallStatus,
	setBotIsSpeaking,
	setCurrentCallStatus,
} from '../../../redux/slice';
import { CallStatus } from '../components/CallStatus';
import {
	ClientEventMessage,
	ClientEventMessageToolCalls,
	ToolCallEndConversationFunctionNameSchema,
	VapiEventMessageType,
} from '../types/VapiClientEventMessage';

const vapiToken = import.meta.env.VITE_VAPI_PUBLIC_KEY as string;

export const useVapi = () => {
	const [vapi] = useState(new Vapi(vapiToken));
	const dispatch = useDispatch();
	const [error, setError] = useState<string | null>(null);
	const [startTime, setStartTime] = useState<number | null>(null);
	const [callDurationSec, setCallDurationSec] = useState<number | null>(null);
	const callStatus = useAppSelector(selectCurrentCallStatus);

	useEffect(() => {
		if (callStatus !== CallStatus.finished) return;

		setCallDurationSec(
			startTime ? Math.floor((Date.now() - startTime) / 1000) : null
		);
		setStartTime(null);
	}, [callStatus, startTime]);

	const start = useCallback(
		async (
			assistant: CreateAssistantDTO | string,
			assistantOverrides?: AssistantOverrides
		) => {
			try {
				dispatch(setCurrentCallStatus(CallStatus.loading));

				await vapi.start(assistant, assistantOverrides);
			} catch (err) {
				dispatch(setCurrentCallStatus(CallStatus.finished));
				setError((err as Error).message);
			}
		},
		[vapi]
	);

	const stop = () => {
		vapi.stop();
		dispatch(setCurrentCallStatus(CallStatus.finished));

		setError(null);
		dispatch(setBotIsSpeaking(false));
	};

	const checkMicrophoneStatus = () => {
		if (vapi.isMuted()) {
			toaster.create({
				title: 'Microphone is muted',
				type: 'warning',
				duration: 5000,
				meta: {
					closable: true,
				},
			});
		}
	};

	const handleToolCall = (clientEventMessage: ClientEventMessageToolCalls) => {
		const functionName = clientEventMessage.toolCalls[0]?.function.name;
		if (
			ToolCallEndConversationFunctionNameSchema.safeParse(functionName).success
		) {
			stop();
		}
	};

	// hook into Vapi events
	useEffect(() => {
		dispatch(setCurrentCallStatus(CallStatus.notStarted));

		vapi.on('call-start', () => {
			checkMicrophoneStatus();
			setStartTime(Date.now());
			setCallDurationSec(null);
			dispatch(setCurrentCallStatus(CallStatus.started));
			dispatch(setBotIsSpeaking(false));
			setError(null);
		});

		vapi.on('call-end', () => {
			dispatch(setBotIsSpeaking(false));
			dispatch(setCurrentCallStatus(CallStatus.finished));
		});

		vapi.on('speech-start', () => {
			dispatch(setBotIsSpeaking(true));
		});

		vapi.on('speech-end', () => {
			dispatch(setBotIsSpeaking(false));
		});

		vapi.on('message', (clientEventMessage: ClientEventMessage) => {
			if (clientEventMessage?.type === VapiEventMessageType.TOOL_CALLS) {
				handleToolCall(clientEventMessage as ClientEventMessageToolCalls);
			}
		});

		vapi.on('error', (err: Error) => {
			setError(err.message);
			dispatch(setCurrentCallStatus(CallStatus.finished));
			dispatch(setBotIsSpeaking(false));
		});

		// we only want this to fire on mount
		// eslint-disable-next-line react-hooks/exhaustive-deps
		return () => {
			dispatch(setCurrentCallStatus(null));
		};
	}, [vapi]);

	return {
		start,
		stop,
		error,
		callDurationSec,
	};
};
