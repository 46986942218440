import { Flex, useDisclosure } from '@chakra-ui/react';
import {
	SchemaLearnerStoryItemFeedbackResponseDto,
	SchemaSessionResponseDto,
} from '@jam/api-sdk/api';
import { ThumbsDown, ThumbsUp } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { useCreateItemUserFeedbackMutation } from '../../../redux/api/userFeedbackApi';
import { NegativeUserFeedbackModal } from './NegativeUserFeedbackModal';
import { Text } from '@jam/front-library';
interface SessionUserFeedbackProps {
	sessionId: string;
	item: SchemaSessionResponseDto['analysis']['items'][0];
	userFeedback: SchemaLearnerStoryItemFeedbackResponseDto | null | undefined;
}

export const SessionUserFeedback = ({
	sessionId,
	item,
	userFeedback,
}: SessionUserFeedbackProps) => {
	const { t } = useTranslation('call');
	const { open: isOpen, onOpen, onClose } = useDisclosure();
	const [addUserItemFeedback] = useCreateItemUserFeedbackMutation();

	if (userFeedback === undefined) {
		return null;
	}
	const hasUserFeedback = !!userFeedback;

	if (!hasUserFeedback) {
		return (
			<Flex
				className="thumbs-container"
				gap="2"
				mt="4"
				alignItems={'center'}
				w="fit-content"
				ml="auto"
			>
				<NegativeUserFeedbackModal
					sessionId={sessionId}
					itemId={item.id}
					isOpen={isOpen}
					onClose={onClose}
				/>
				<Text variant="small-print">{t('feedback_not_given')}</Text>
				<ThumbsUp
					aria-label="thumbs up"
					cursor={'pointer'}
					size={15}
					color="#757575"
					onClick={() => {
						void addUserItemFeedback({
							sessionId,
							storyItemReferenceId: item.id,
							positive: true,
						});
					}}
				/>
				<ThumbsDown
					aria-label="thumbs down"
					cursor={'pointer'}
					size={15}
					color="#757575"
					onClick={onOpen}
				/>
			</Flex>
		);
	}

	if (userFeedback.positive) {
		return (
			<Flex gap="2" mt="4" alignItems={'center'} w="fit-content" ml="auto">
				<ThumbsUp
					aria-label="thumbs up"
					weight="fill"
					color="#757575"
					size={15}
				/>
			</Flex>
		);
	}

	if (!userFeedback.positive) {
		return (
			<Flex gap="2" mt="4" alignItems={'center'} w="fit-content" ml="auto">
				<ThumbsDown
					aria-label="thumbs down"
					weight="fill"
					color="#757575"
					size={15}
				/>
			</Flex>
		);
	}
};
