import { ButtonProps } from '@chakra-ui/react';
import {
	MenuContent,
	MenuItem,
	MenuRoot,
	MenuSeparator,
	MenuTrigger,
} from '@jam/front-library';
import { SignOut, User } from '@phosphor-icons/react';
import { useLogoutFunction } from '@propelauth/react';
import React, { cloneElement, isValidElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

type UserContextMenuProps = {
	ml?: string;
	placement:
		| 'top'
		| 'top-end'
		| 'top-start'
		| 'left'
		| 'bottom'
		| 'right'
		| 'bottom-end'
		| 'bottom-start'
		| 'left-end'
		| 'left-start'
		| 'right-end'
		| 'right-start';
	children: React.ReactNode;
	onElementClick?: (element: string) => void;
};

export const UserContextMenu = ({
	children,
	ml,
	placement,
	onElementClick,
}: UserContextMenuProps) => {
	const [open, setOpen] = useState<boolean>(false);
	const navigate = useNavigate();
	const { t } = useTranslation('common');
	const logoutFunction = useLogoutFunction();

	const enhancedChildren = useMemo(() => {
		return isValidElement<ButtonProps>(children)
			? cloneElement(children, {
					borderColor: open ? 'brand.500' : '#D9D9D9',
				})
			: children;
	}, [open]);
	return (
		<MenuRoot
			open={open}
			onOpenChange={(details) => setOpen(details.open)}
			positioning={{ placement }}
		>
			<>
				<MenuTrigger>{enhancedChildren}</MenuTrigger>
				<MenuContent px="1" ml={ml ?? '2'} mb="6">
					<MenuItem
						value={'settings'}
						onClick={() => {
							onElementClick?.('settings');
							navigate('/settings');
						}}
						borderRadius={'8px'}
						_hover={{
							bgColor: 'rgba(66, 65, 228, 0.08)',
							color: 'brand.500',
						}}
					>
						<User size={16} />
						{t('personal_settings')}
					</MenuItem>
					<MenuSeparator />
					<MenuItem
						value={'sign_out'}
						onClick={() => {
							onElementClick?.('sign_out');
							void logoutFunction(true);
						}}
						borderRadius={'8px'}
						_hover={{
							bgColor: 'rgba(66, 65, 228, 0.08)',
							color: 'brand.500',
						}}
					>
						<SignOut size={16} />
						{t('sign_out')}
					</MenuItem>
				</MenuContent>
			</>
		</MenuRoot>
	);
};
