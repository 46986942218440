import { PathsApiV1UsersGetParametersQueryRole } from '@jam/api-sdk/api';
import { TableCells } from '@jam/front-library';
import React from 'react';

export const UserRoleCell: React.FC<{
	row: {
		id?: string;
		role?: PathsApiV1UsersGetParametersQueryRole;
	};
	onRoleChange?: (
		userId: string,
		role: PathsApiV1UsersGetParametersQueryRole
	) => Promise<void>;
}> = ({ row, onRoleChange }) => {
	const supportsClick = !!onRoleChange;

	return (
		<TableCells.SelectionCell
			value={row.role}
			values={[
				{ value: 'Member', label: 'Member' },
				{ value: 'Manager', label: 'Manager' },
				{ value: 'Owner', label: 'Owner' },
			]}
			disabled={!supportsClick}
			onChange={(value) => {
				if (!row.id) return;
				void onRoleChange?.(
					row.id,
					value as PathsApiV1UsersGetParametersQueryRole
				);
			}}
		/>
	);
};
