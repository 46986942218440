import { BasePage, Input, PageContent, Section } from '@jam/front-library';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ViewStoryButton } from '../../common/components/story-detail/buttons/ViewStoryButton';
import { TrackAccordion } from '../../common/components/track-detail/TrackAccordion';
import { useGetJourneysQuery } from '../../redux/api/cmsApi';
import { submitSeeMissionScoreCards } from '../../features/tracking/util/submitMissionEvents';
import { useLocation } from 'react-router-dom';

export const ManagerJourneysPage = () => {
	const { t } = useTranslation('common');
	const [search, setSearch] = useState('');
	const { data: journeys, isLoading } = useGetJourneysQuery();
	const location = useLocation();
	// Get openJourneyId from URL
	const [openJourneyId, setOpenJourneyId] = useState<string | null>(null);

	const filtered = (journeys ?? []).filter((journey) => {
		const title = journey.title.toLowerCase();
		return title.includes(search.toLowerCase());
	});

	useEffect(() => {
		// Parse the URL to get the openJourneyId parameter
		const searchParams = new URLSearchParams(location.search);
		const journeyIdFromUrl = searchParams.get('openJourneyId');
		if (journeyIdFromUrl) {
			setOpenJourneyId(journeyIdFromUrl);
		}
	}, [location]);

	// Scroll to the open journey
	useLayoutEffect(() => {
		if (isLoading) {
			return;
		}
		if (openJourneyId) {
			const element = document.getElementById(
				`track-accordion-${openJourneyId}`
			);
			if (element) {
				element.scrollIntoView({ behavior: 'smooth' });
			}
		}
	}, [openJourneyId, isLoading]);

	return (
		<BasePage
			title={t('playbooks')}
			subtitle={t('tracks_description') ?? ''}
			trackingKey="playbooks"
		>
			<Input
				maxWidth={'400px'}
				value={search}
				onChange={(e) => setSearch(e.target.value)}
				borderRadius={'8px'}
				placeholder={t('search_tracks') ?? ''}
			/>

			<PageContent>
				<Section title={t('tracks').toString()}>
					{filtered.map((journey) => (
						<TrackAccordion
							defaultOpen={journey.id === openJourneyId}
							key={journey.id}
							journey={journey}
							renderStoryButton={(storyId) => (
								<ViewStoryButton
									storyId={storyId}
									onClick={() => {
										submitSeeMissionScoreCards(storyId, 'track_accordion');
									}}
								/>
							)}
						/>
					))}
				</Section>
			</PageContent>
		</BasePage>
	);
};
