import { Box, Menu, Portal, SimpleGrid, Skeleton } from '@chakra-ui/react';
import { Button } from '@jam/front-library';
import { CaretDown } from '@phosphor-icons/react';
import { useAuthInfo } from '@propelauth/react';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { StoryCard } from '../../../common/components/story-detail/StoryCard';
import { StartStoryButton } from '../../../common/components/story-detail/buttons/StartStoryButton';
import { useUserOrg } from '../../../common/hooks/useUserOrg';
import { useGetStoriesQuery } from '../../../redux/api/cmsApi';

const LoadingState = () => {
	return (
		<SimpleGrid
			role="loading-state"
			gap={4}
			columns={[1, 1, 2, 2, 3]}
			w="fit-content"
		>
			{Array.from({ length: 6 }).map((_, index) => (
				<Skeleton borderRadius="10px" key={index} height="420px" w="284px" />
			))}
		</SimpleGrid>
	);
};

export const MissionList = () => {
	const { data: missions, isLoading } = useGetStoriesQuery();
	const { user } = useAuthInfo();
	const isContextualizerEnabled = useFeatureFlagEnabled(
		'is-contextualizer-enabled'
	);
	const org = useUserOrg();
	const navigate = useNavigate();

	const renderStoryButton = useCallback(
		(storyId: string, sagaId: string) => {
			if (isContextualizerEnabled) {
				return (
					<Menu.Root onSelect={({ value }) => navigate(value)}>
						<Menu.Trigger asChild>
							<Button
								size="full"
								variant="secondary"
								icon={<CaretDown />}
								iconPosition={'end'}
							>
								Open
							</Button>
						</Menu.Trigger>
						<Portal>
							<Menu.Positioner>
								<Menu.Content>
									<Menu.Item value={`/call?storyId=${storyId}`}>
										Start
									</Menu.Item>
									<Menu.Item value={`/maker/${storyId}`}>
										Create similar story
									</Menu.Item>
									{org?.writeSagaIds?.includes(sagaId) && (
										<Menu.Item value={`/maker-edit/${storyId}`}>
											Edit mission
										</Menu.Item>
									)}
								</Menu.Content>
							</Menu.Positioner>
						</Portal>
					</Menu.Root>
				);
			}

			return (
				<StartStoryButton
					label="start_mission"
					origin="course_catalog_missions"
					storyId={storyId}
				/>
			);
		},
		[isContextualizerEnabled]
	);

	if (isLoading) {
		return <LoadingState />;
	}

	if (!missions || missions.length === 0) {
		return <Box role="no-missions-found">No missions found</Box>;
	}

	return (
		<SimpleGrid role="mission-list" gap={6} columns={[2, 3, 4]} w="fit-content">
			{missions.map((mission) => (
				<StoryCard
					key={mission.id}
					userId={user?.userId}
					story={mission}
					renderStoryButton={renderStoryButton}
				/>
			))}
		</SimpleGrid>
	);
};
