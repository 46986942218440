// ContentAssignmentTeamSelect.tsx
import React, { useEffect } from 'react';
import { Field, Select } from '@jam/front-library';
import { Flex } from '@chakra-ui/react';
import { SchemaTeamResponseDto } from '@jam/api-sdk/api';
import { useTranslation } from 'react-i18next';
import { Control, Controller, FieldErrors, useWatch } from 'react-hook-form';
import { CreateSprintScheduleRequestDTOSchemaFrontendDTO } from '../content-assignment/ContentAssignment';

interface Props {
	teams: SchemaTeamResponseDto[];
	control: Control<CreateSprintScheduleRequestDTOSchemaFrontendDTO>;
	errors: FieldErrors<CreateSprintScheduleRequestDTOSchemaFrontendDTO>;
}
export const ContentAssignmentTeamSelect: React.FC<Props> = ({
	teams,
	control,
	errors,
}) => {
	const { t } = useTranslation('coaching');
	const [refreshKey, setRefreshKey] = React.useState(0);

	// Watch contentReference directly from the form
	const assignees = useWatch({
		control,
		name: 'assignee',
		defaultValue: undefined,
	});

	useEffect(() => {
		if (!assignees?.length) {
			setRefreshKey((prev) => prev + 1);
		}
	}, [assignees]);

	return (
		<Flex w="100%" key={refreshKey}>
			<Controller
				name="assignee"
				control={control}
				render={({ field }) => (
					<Field
						invalid={!!errors?.assignee}
						errorText={t('teams.error')}
						errorPosition="top"
					>
						<Flex w="col-4">
							<Select
								placeholder={t('coaching:teams.placeholder').toString()}
								items={
									teams?.map((te) => ({ label: te.name, value: te.id })) ?? []
								}
								name={field.name}
								value={field.value?.[0]}
								onValueChange={(value) => {
									field.onChange([value]);
								}}
							/>
						</Flex>
					</Field>
				)}
			/>
		</Flex>
	);
};
