import { JamFrontLibraryProvider } from '@jam/front-library';
import { ReactNode, useEffect } from 'react';
import {
	SidebarData,
	useAppSidebarData,
} from '../common/hooks/useAppSidebarData';
import { useAuthInfo } from '@propelauth/react';
import { useTranslation } from 'react-i18next';
import { useIsAdmin } from '../common/hooks/useIsAdmin';
import { useDispatch, useSelector } from 'react-redux';
import {
	selectLanguage,
	selectMenuIsOpen,
	setReportIssueModalIsOpen,
	toggleMenu,
} from '../redux/slice';
import { useIsMobile } from '../common/hooks/useIsMobile';
import { useAppSelector } from '../redux/hooks';
import { AdminHeader } from '../common/components/admin/AdminHeader';
import { ProfileButton } from '../common/components/side-bar/ProfileButton';
import { sidebarEntries } from '../common/config/sidebarEntries';
import { setUser } from '@sentry/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserWidgets } from '../common/components/UserWidgets';

interface AppLayoutProps {
	children: ReactNode;
}

const presetOrder = {
	default: ['learner', 'manager', 'shared', 'help'],
	manager: ['manager', 'learner', 'shared', 'help'],
};

export const FrontLibraryProvider = ({ children }: AppLayoutProps) => {
	const { t } = useTranslation();
	const location = useLocation();
	const navigate = useNavigate();
	// This should be moved to separate: useSetSentryEmail.ts hook
	const { user } = useAuthInfo();
	useEffect(() => {
		if (user) {
			setUser({
				email: user.email,
			});
		}
	}, [user]);
	// Sidebar
	const sidebarData = useAppSidebarData();
	const isOpen = useAppSelector(selectMenuIsOpen);
	const dispatch = useDispatch();
	const onToggle = () => {
		dispatch(toggleMenu());
	};

	// Layout related
	const isOnMobile = useIsMobile();
	const { i18n } = useTranslation('common');
	const isAdmin = useIsAdmin();
	const language = useSelector(selectLanguage);

	const onReportIssue = () => {
		dispatch(setReportIssueModalIsOpen(true));
	};

	const order = sidebarData.isOwnerOrManager ? 'manager' : 'default';

	return (
		<JamFrontLibraryProvider<SidebarData>
			sidebarProps={{
				isOpen,
				onToggle,
				events: { onReportIssue },
				order: presetOrder[order],
				data: sidebarData,
				entries: sidebarEntries,
				footer: <ProfileButton isOpen={isOpen} />,
			}}
			adminHeader={AdminHeader}
			isAdmin={isAdmin}
			show={i18n.language === language}
			isOnMobile={isOnMobile}
			location={location}
			navigate={navigate}
			translate={t}
			userWidgets={UserWidgets}
		>
			{children}
		</JamFrontLibraryProvider>
	);
};
