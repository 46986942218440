interface Props {
	size: number;
	color: string;
}
export function PersonSquare({ size, color, ...props }: Props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			fill="none"
			viewBox="0 0 16 16"
			{...props}
		>
			<path
				d="M11.3332 12C11.3332 11.6464 11.1927 11.3072 10.9426 11.0572C10.6926 10.8071 10.3535 10.6667 9.99984 10.6667H5.99984C5.64622 10.6667 5.30708 10.8071 5.05703 11.0572C4.80698 11.3072 4.6665 11.6464 4.6665 12"
				stroke="#0B1215"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12.6667 2.66666H3.33333C2.59695 2.66666 2 3.26362 2 4V13.3333C2 14.0697 2.59695 14.6667 3.33333 14.6667H12.6667C13.403 14.6667 14 14.0697 14 13.3333V4C14 3.26362 13.403 2.66666 12.6667 2.66666Z"
				stroke="#0B1215"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M7.99984 8C8.73622 8 9.33317 7.40305 9.33317 6.66667C9.33317 5.93029 8.73622 5.33334 7.99984 5.33334C7.26346 5.33334 6.6665 5.93029 6.6665 6.66667C6.6665 7.40305 7.26346 8 7.99984 8Z"
				stroke="#0B1215"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5.3335 1.33334V2.66667"
				stroke="#0B1215"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10.6665 1.33334V2.66667"
				stroke="#0B1215"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
