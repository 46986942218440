import { Button, TableCells } from '@jam/front-library';
import {
	PathsApiV1UsersGetParametersQueryRole,
	PendingInviteResponseDTOStatus,
} from '@jam/api-sdk/api';
import { ArrowCounterClockwise } from '@phosphor-icons/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const InviteSendReminderCell: React.FC<{
	row: {
		email: string;
		role?: PathsApiV1UsersGetParametersQueryRole;
		status: PendingInviteResponseDTOStatus;
	};
	onClick: (
		email: string,
		role?: PathsApiV1UsersGetParametersQueryRole
	) => void;
}> = ({ row, onClick }) => {
	const { t } = useTranslation();
	if (row.status !== PendingInviteResponseDTOStatus.expired) {
		return null;
	}
	return (
		<TableCells.BaseCell>
			<Button
				variant="tertiary"
				size="sm"
				onClick={() => {
					onClick(row.email, row.role);
				}}
				icon={<ArrowCounterClockwise />}
			>
				{t('common:resend_invitation')}
			</Button>
		</TableCells.BaseCell>
	);
};
