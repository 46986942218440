import { Skeleton, Stack } from '@chakra-ui/react';
import { X } from '@phosphor-icons/react';
import { useParams } from 'react-router-dom';
import { CallDetails } from '../../features/call/components/CallDetails';
import { ManagerStoryHeader } from '../../features/call/components/ManagerStoryHeader';
import { useGetStoryQuery } from '../../redux/api/cmsApi';
import {
	useGetSessionQuery,
	useGetStorySessionCountQuery,
} from '../../redux/api/sessionApi';
import { BasePage, Layout, PageContent } from '@jam/front-library';
import { useGoBack } from '../../common/hooks/useGoBack';
import { useAuthInfo } from '@propelauth/react';
import { Caller } from '../../features/call/components/Caller';

const LoadingState = () => {
	return (
		<Layout hideSidebar fullWidth>
			<Stack direction="column" h="100%" gap={10} p={10}>
				<Skeleton borderRadius={'8px'} h="200px" flex={1} />
			</Stack>
		</Layout>
	);
};

const useFetchPageData = (sessionId?: string) => {
	const { data: session, isLoading: isSessionLoading } = useGetSessionQuery(
		sessionId ?? '',
		{
			skip: !sessionId,
		}
	);
	const { data: story, isLoading } = useGetStoryQuery(
		session?.storyReferenceId ?? '',
		{
			skip: !session,
		}
	);

	return {
		session,
		story,
		isLoading,
		isSessionLoading,
	};
};

const usePageData = () => {
	const { userId, sessionId } = useParams<{
		userId: string;
		sessionId: string;
	}>();

	const { user: authUser } = useAuthInfo();
	const isSelfRoute = window.location.pathname.includes('/user/self/session/');

	const { session, story, isLoading, isSessionLoading } =
		useFetchPageData(sessionId);

	const { data: storySessionCount } = useGetStorySessionCountQuery(
		{ id: story?.id || '', userId: userId || '' },
		{
			skip: !story?.id || !userId || true,
		}
	);

	return {
		userId: isSelfRoute ? authUser?.userId : userId,
		session,
		isSessionLoading,
		story,
		isLoading,
		isSelfRoute,
		storySessionCount,
	};
};

export const ViewSessionPage = () => {
	const {
		userId,
		session,
		isSessionLoading,
		story,
		isLoading,
		isSelfRoute,
		storySessionCount,
	} = usePageData();
	const onBack = useGoBack();

	if (isLoading || isSessionLoading) {
		return <LoadingState />;
	}

	if (!session || !userId) {
		return null;
	}

	return (
		<BasePage
			onBack={onBack}
			onBackIcon={X}
			title={''}
			hideSidebar
			fullWidth
			trackingKey="view_session"
		>
			<PageContent>
				{isSelfRoute ? (
					<Caller
						numberOfRounds={storySessionCount ?? 0}
						story={story}
						dueDate={undefined}
					/>
				) : (
					<ManagerStoryHeader story={story} userId={userId} />
				)}

				<CallDetails story={story} session={session} />
			</PageContent>
		</BasePage>
	);
};
