import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { StoryCardButton } from '../StoryCardButton';

export interface ViewStoryButtonProps {
	storyId: string;
	onClick?: () => void;
}

export const ViewStoryButton = ({ storyId, onClick }: ViewStoryButtonProps) => {
	const { t } = useTranslation('mission');
	const navigate = useNavigate();

	return (
		<StoryCardButton
			label={t('view_mission')}
			onClick={() => {
				onClick?.();
				navigate(`/mission/${storyId}`);
			}}
		/>
	);
};
