import {
	PathsApiV1SessionsGetParametersQueryDateFilter,
	SchemaTeamResponseDto,
} from '@jam/api-sdk/api';
import { Select } from '@jam/front-library';
import { Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export interface TeamsFilteringToolbarProps {
	selectedTeamId: string | undefined;
	selectedDateFilter?: PathsApiV1SessionsGetParametersQueryDateFilter;
	setSelectedTeamId: (teamId: string | undefined) => void;
	setSelectedDateFilter?: (
		dateFilter: PathsApiV1SessionsGetParametersQueryDateFilter | undefined
	) => void;
	teams: SchemaTeamResponseDto[];
	showTimeFilter?: boolean;
}
export const TeamsFilteringToolbar = ({
	selectedTeamId,
	selectedDateFilter,
	setSelectedTeamId,
	setSelectedDateFilter,
	teams,
	showTimeFilter = false,
}: TeamsFilteringToolbarProps) => {
	const { t } = useTranslation('team');

	return (
		<Stack direction="row" gap={1} className="w-full" alignItems="center">
			<Stack width="320px">
				<Select
					placeholder={t('all_teams') ?? ''}
					items={teams?.map((team) => ({
						label: team.name,
						value: team.id,
					}))}
					onValueChange={(value) => setSelectedTeamId(value as string)}
					value={selectedTeamId}
					clearable
				/>
			</Stack>
			{showTimeFilter && (
				<Stack width="320px">
					<Select
						placeholder={t('all_time') ?? ''}
						items={[
							{
								value: 'current_week',
								label: t('current_week').toString(),
							},
							{
								value: 'current_month',
								label: t('current_month').toString(),
							},
						]}
						onValueChange={(value) =>
							setSelectedDateFilter?.(
								value as PathsApiV1SessionsGetParametersQueryDateFilter
							)
						}
						value={selectedDateFilter}
						clearable
					/>
				</Stack>
			)}
		</Stack>
	);
};
