import { Flex } from '@chakra-ui/react';
import {
	SchemaSessionResponseDto,
	SchemaStoryResponseDto,
	SessionResponseDTOAnalysisState,
} from '@jam/api-sdk/api';
import {
	PageContent,
	TabsContent,
	TabsIndicator,
	TabsList,
	TabsRoot,
	TabsTrigger,
	Tooltip,
} from '@jam/front-library';
import { Lock } from '@phosphor-icons/react';
import { useTour } from '@reactour/tour';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsOnboarding } from '../../../common/hooks/useIsOnboarding';
import { useIsRecruiting } from '../../../common/hooks/useIsRecruiting';
import { useOnboardingStepFallback } from '../../../common/hooks/useOnboardingStepFallback';
import { OnboardingFeature } from '../../../common/types/Onboarding';
import { useActiveCallTabIndex } from '../hooks/useActiveCallTabIndex';
import { HintsAndGoals } from './HintsAndGoals';
import { SessionFeedback } from './SessionFeedback';
import { Transcript } from './Transcript';
import { MoreInfoTab } from './more-info/MoreInfoTab';
import { submitSeeMissionScoreCards } from '../../tracking/util/submitMissionEvents';

type CallDetailsProps = {
	story?: SchemaStoryResponseDto;
	session: SchemaSessionResponseDto | undefined | null;
	withOnboarding?: boolean;
	setOnboarding?: (
		value: OnboardingFeature.CALL_PAGE | OnboardingFeature.CALL_FEEDBACK_PAGE
	) => void;
	view?: 'learner' | 'manager';
};

export const CallDetails = ({
	story,
	session,
	withOnboarding,
	setOnboarding,
	view,
}: CallDetailsProps) => {
	const { t } = useTranslation('call');
	const { activeCallTabIndex, setActiveCallTabIndex } = useActiveCallTabIndex();
	const { setIsOpen } = useTour();
	const isOnboardingCallPage = useIsOnboarding(OnboardingFeature.CALL_PAGE);
	const isOnboardingCallPageFeedback = useIsOnboarding(
		OnboardingFeature.CALL_FEEDBACK_PAGE
	);
	const isRecruiting = useIsRecruiting();

	useOnboardingStepFallback();

	useEffect(() => {
		if (!withOnboarding) {
			return;
		}

		if (isOnboardingCallPage) {
			setIsOpen(true);
			setOnboarding?.(OnboardingFeature.CALL_PAGE);
			return;
		}

		if (
			isOnboardingCallPageFeedback &&
			story &&
			session &&
			session.analysis &&
			session.analysis.items.length > 0 &&
			session.analysis.state === SessionResponseDTOAnalysisState.DONE
		) {
			setIsOpen(true);
			setOnboarding?.(OnboardingFeature.CALL_FEEDBACK_PAGE);
		}
	}, [isOnboardingCallPage, setIsOpen, story, session, withOnboarding]);

	return (
		<>
			<TabsRoot
				lazyMount
				value={
					activeCallTabIndex === null ? 'scenario' : `tab-${activeCallTabIndex}`
				}
				onValueChange={({ value }) => {
					const index =
						value === 'scenario' ? null : parseInt(value.split('-')[1]);
					setActiveCallTabIndex(index, 'replaceIn');
				}}
			>
				<TabsList minW={'300px'}>
					<TabsTrigger
						value="scenario"
						disabled={!story}
						className="scenario-tab"
					>
						{t('briefing')}
					</TabsTrigger>
					{!isRecruiting && (
						<TabsTrigger
							value="tab-1"
							disabled={!story}
							onClick={() => {
								if (!story) return;
								if (view === 'learner') {
									submitSeeMissionScoreCards(story.id, 'hints_tab');
								}
							}}
						>
							{t('hints')}
						</TabsTrigger>
					)}
					<TabsTrigger
						value={`tab-${isRecruiting ? 1 : 2}`}
						className="feedback-tab"
						disabled={!session}
					>
						{!session ? (
							<Tooltip
								showArrow
								positioning={{ placement: 'top' }}
								content={t('complete_one_attempt_feedback')}
							>
								<Flex alignItems={'center'} gap="1">
									{t('feedback')}
									<Lock size={16} />
								</Flex>
							</Tooltip>
						) : (
							t('feedback')
						)}
					</TabsTrigger>
					<TabsIndicator />
				</TabsList>
				<Flex w="100%" gap={4}>
					<TabsContent value="scenario" w="100%">
						<PageContent gap={6}>
							<HintsAndGoals story={story} />
						</PageContent>
					</TabsContent>
					{!isRecruiting && (
						<TabsContent value="tab-1" w="100%">
							<PageContent>
								<MoreInfoTab story={story} scorecard={story?.scorecard} />
							</PageContent>
						</TabsContent>
					)}
					<TabsContent value={`tab-${isRecruiting ? 1 : 2}`} w="100%">
						<PageContent>
							<SessionFeedback session={session} />
						</PageContent>
					</TabsContent>
					<Transcript session={session} persona={story?.persona} />
				</Flex>
			</TabsRoot>
		</>
	);
};
