import { SchemaGeneratorGetStoryDraftResponseBodyDto } from '@jam/api-sdk/api';
import { PatchStory } from './IAgentState';

export function patchDraft(
	prev: SchemaGeneratorGetStoryDraftResponseBodyDto | undefined,
	patch: PatchStory | undefined
): SchemaGeneratorGetStoryDraftResponseBodyDto | undefined {
	if (!prev || !patch) {
		return prev;
	}

	return {
		...prev,
		persona: {
			...prev.persona,
			...patch.persona,
		},
		scenario: {
			...prev.scenario,
			...patch.scenario,
		},
		scorecard: {
			...prev.scorecard,
			...patch.scorecard,
		},
	};
}
