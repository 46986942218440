import '@copilotkit/react-ui/styles.css';
import { useAuthInfo } from '@propelauth/react';
import posthog from 'posthog-js';
import { PostHogProvider as Provider } from 'posthog-js/react';
import React, { useEffect } from 'react';

export const PostHogProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const { user } = useAuthInfo();

	useEffect(() => {
		const apiKey = import.meta.env.VITE_POSTHOG_API_KEY as string;
		const posthogUrl = import.meta.env.VITE_POSTHOG_URL as string;

		if (!apiKey || !posthogUrl) {
			console.warn(
				'PostHog API key or URL is not set. PostHog will not be initialized.'
			);
			return;
		}

		posthog.init(apiKey, {
			api_host: posthogUrl,
			autocapture: false,
			capture_pageview: false,
		});
	}, []);

	useEffect(() => {
		if (user) {
			posthog?.identify(user.userId, {
				email: user.email,
			});
		}
	}, [user]);

	return <Provider client={posthog}>{children}</Provider>;
};
