import { AccordionItemBody } from '@chakra-ui/react';
import { SchemaGeneratorGetStoryDraftResponseBodyDto } from '@jam/api-sdk/api';
import {
	AccordionItem,
	AccordionItemContent,
	AccordionItemTrigger,
	AccordionRoot,
	Field,
	Input,
	Textarea,
} from '@jam/front-library';
import { Highlight } from './Highlight';

type MakerScorecardViewProps = {
	draft: SchemaGeneratorGetStoryDraftResponseBodyDto;
};

export const MakerScorecardView = ({ draft }: MakerScorecardViewProps) => {
	return (
		<>
			<Highlight trigger={draft.scorecard.title}>
				<Field label="Title">
					<Input value={draft.scorecard.title} />
				</Field>
			</Highlight>

			<AccordionRoot collapsible>
				{draft.scorecard.items.map((item, index) => (
					<AccordionItem value={index.toString()} key={index}>
						<AccordionItemTrigger>
							<Field>
								<Input value={item.scorecard_title} />
							</Field>
						</AccordionItemTrigger>
						<AccordionItemContent>
							<AccordionItemBody>
								<Field label={`Description ${index + 1}`}>
									<Textarea
										autoresize
										maxH="50lh"
										value={item.description}
										size="sm"
									/>
								</Field>

								<Field
									label={`Type ${index + 1}`}
									helperText="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor."
								>
									<Input value={item.type} />
								</Field>

								<Field label={`Goal ${index + 1}`}>
									<Input value={item.item_goal} />
								</Field>

								{item.desired_behaviour?.map((behaviour, behaviourIndex) => (
									<>
										<Field
											key={behaviourIndex}
											label={`Desired Behaviour ${behaviourIndex + 1}`}
										>
											<Input value={behaviour.behaviour} />
										</Field>
										{behaviour.examples?.map((example, exampleIndex) => (
											<Field
												key={exampleIndex}
												label={`Desired Example ${exampleIndex + 1}`}
											>
												<Input value={example.example} />
											</Field>
										))}
									</>
								))}

								{item.undesired_behaviour?.map((behaviour, behaviourIndex) => (
									<>
										<Field
											key={behaviourIndex}
											label={`Undesired Behaviour ${behaviourIndex + 1}`}
										>
											<Input value={behaviour.behaviour} />
										</Field>
										{behaviour.examples?.map((example, exampleIndex) => (
											<Field
												key={exampleIndex}
												label={`Undesired Example ${exampleIndex + 1}`}
											>
												<Input value={example.example} />
											</Field>
										))}
									</>
								))}
							</AccordionItemBody>
						</AccordionItemContent>
					</AccordionItem>
				))}
			</AccordionRoot>
		</>
	);
};
