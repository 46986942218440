import { useAuthInfo } from '@propelauth/react';
import { useTranslation } from 'react-i18next';
import { PersonalSettingsTab } from '../features/settings/components/PersonalSettingsTab';
import { BasePage, PageContent } from '@jam/front-library';

export const SettingsPage = () => {
	const { t } = useTranslation('settings');
	const { user } = useAuthInfo();

	if (!user) return null;

	return (
		<BasePage
			subtitle={t('account-description') ?? ''}
			title={`${user.firstName ?? ''} ${user.lastName ?? ''}`}
			trackingKey="settings"
		>
			<PageContent>
				<PersonalSettingsTab />
			</PageContent>
		</BasePage>
	);
};
