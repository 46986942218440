import React from 'react';
import {
	Button,
	DialogBackdrop,
	DialogBody,
	DialogCloseTrigger,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogRoot,
	DialogTitle,
	Text,
} from '@jam/front-library';
import { useTranslation } from 'react-i18next';
import { Flex } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { PieChartIcon } from '../../../../common/components/icons/PieChartIcon';

type ConfirmationDialogProps = {
	isOpen: boolean;
	onClose: () => void;
};

export const ContentAssignmentSuccessDialog = ({
	isOpen,
	onClose,
}: ConfirmationDialogProps) => {
	const { t } = useTranslation('coaching');
	const contentRef = React.useRef<HTMLDivElement>(null);
	const navigate = useNavigate();

	const onGoToDashboard = () => {
		onClose();
		navigate('/users/home');
	};

	const onReset = () => {
		onClose();
	};

	return (
		<DialogRoot
			size="xs"
			open={isOpen}
			onOpenChange={({ open }) => {
				if (!open) {
					onClose();
				}
			}}
		>
			<DialogBackdrop />
			<DialogContent ref={contentRef}>
				<DialogHeader>
					<DialogTitle>
						<PieChartIcon size={24} />
					</DialogTitle>
					<DialogCloseTrigger />
				</DialogHeader>
				<DialogBody>
					<Flex gap={2} direction="column">
						<Text textStyle="h2">{t('confirmation_dialog.title')}</Text>
						<Text variant="small-print">
							{t('confirmation_dialog.message')}
						</Text>
					</Flex>
				</DialogBody>
				<DialogFooter>
					<Flex direction="column" w="100%" gap={2}>
						<Button
							data-testid="confirm-action-button"
							type="submit"
							onClick={onGoToDashboard}
							variant="secondary"
						>
							{t('confirmation_dialog.to_dashboard')}
						</Button>
						<Button
							variant={'primary'}
							onClick={onReset}
							data-testid="cancel-action-button"
						>
							{t('confirmation_dialog.start_new_sprint')}
						</Button>
					</Flex>
				</DialogFooter>
			</DialogContent>
		</DialogRoot>
	);
};
