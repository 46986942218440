import { Box, Flex, Separator, SimpleGrid } from '@chakra-ui/react';
import { SchemaJourneyResponseDto } from '@jam/api-sdk/api';
import {
	AccordionItem,
	AccordionItemContent,
	AccordionItemTrigger,
	AccordionRoot,
	Text,
} from '@jam/front-library';
import { Circle, ThumbsDown, ThumbsUp } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';

type MissionTaskListProps = {
	tasks: SchemaJourneyResponseDto['stories'][0]['scorecard']['items'];
};

export const MissionTaskList = ({ tasks }: MissionTaskListProps) => {
	const { t } = useTranslation('common');
	return (
		<Flex direction="column" gap="4">
			{tasks.map((task, index) => (
				<AccordionRoot
					w={'100%'}
					borderWidth={'1.5px'}
					p={6}
					borderRadius={'12px'}
					borderColor={'#d9d9d9'}
					key={task.id}
					multiple
				>
					<AccordionItem border={0} value={task.id}>
						<AccordionItemTrigger
							px={3}
							py={0}
							_hover={{ background: 'transparent' }}
							indicatorPlacement="end"
						>
							<Flex w={'100%'}>
								<Flex justify={'space-between'} w="100%" gap={10}>
									<Flex direction={'column'} flexGrow={1} gap={3} w={'100%'}>
										<Flex alignItems={'center'} gap={2}>
											<Flex
												borderWidth={'1px'}
												borderColor={'#D9d9d9'}
												color={'#757575'}
												w={'24px'}
												h={'24px'}
												justifyContent={'center'}
												alignItems={'center'}
												borderRadius={'full'}
												textStyle="small-print"
											>
												{index + 1}
											</Flex>
											<Text variant="cards-h1">{task.scorecard_title}</Text>
										</Flex>
										{task.item_goal && (
											<Flex direction={'column'} gap={3}>
												<Text variant="h4">{t('objective')}</Text>
												<Text variant="label-body">{task.item_goal}</Text>
											</Flex>
										)}
									</Flex>
								</Flex>
							</Flex>
						</AccordionItemTrigger>
						<AccordionItemContent px={0} mx={0}>
							<Separator my={3} />
							<SimpleGrid columns={2} gap={4}>
								<Flex gap={3} direction={'column'}>
									<Flex gap={2} alignItems={'center'}>
										<Flex
											bgColor={'gray.50'}
											w={'24px'}
											h={'24px'}
											justifyContent={'center'}
											alignItems={'center'}
											borderRadius={'full'}
											textStyle="small-print"
											color="primary.500"
										>
											<ThumbsUp size={15} weight="fill" />
										</Flex>
										<Text color={'primary.500'} variant="button">
											{t('dos')}
										</Text>
									</Flex>
									{task.desired_behaviour && (
										<Flex pl={'10'} direction={'column'}>
											{task.desired_behaviour.map((behaviour, i) => (
												<Flex gap={3} key={i} textStyle="body">
													<Box mt={'10px'}>
														<Circle color="#757575" size={7} weight="fill" />
													</Box>
													<Text variant="body" color="text.smallPrint">
														{behaviour.behaviour}
													</Text>
												</Flex>
											))}
										</Flex>
									)}
								</Flex>
								<Flex gap={3} direction={'column'}>
									<Flex gap={2} alignItems={'center'}>
										<Flex
											bgColor={'gray.50'}
											w={'24px'}
											h={'24px'}
											justifyContent={'center'}
											alignItems={'center'}
											borderRadius={'full'}
											textStyle="small-print"
											color="primary.500"
										>
											<ThumbsDown size={15} weight="fill" />
										</Flex>
										<Text variant="button" color="primary.500">
											{t('donts')}
										</Text>
									</Flex>
									{task.undesired_behaviour && (
										<Flex pl={'10'} direction={'column'}>
											{task.undesired_behaviour.map((behaviour, i) => (
												<Flex gap={3} key={i}>
													<Box mt={'10px'}>
														<Circle color="#757575" size={7} weight="fill" />
													</Box>
													<Text variant="body" color="text.smallPrint">
														{behaviour.behaviour}
													</Text>
												</Flex>
											))}
										</Flex>
									)}
								</Flex>
							</SimpleGrid>
						</AccordionItemContent>
					</AccordionItem>
				</AccordionRoot>
			))}
		</Flex>
	);
};
