import { SimpleGrid } from '@chakra-ui/react';
import { SchemaStoryResponseDto } from '@jam/api-sdk/api';
import { ReactElement } from 'react';
import { StoryCard } from './StoryCard';

type StoryCardCarrouselProps = {
	stories: SchemaStoryResponseDto[];
	dueDate?: Date;
	userId?: string;
	hideStatus?: boolean;
	renderStoryButton?: (storyId: string) => ReactElement;
};

export const StoryCardGrid = ({
	stories,
	dueDate,
	userId,
	hideStatus,
	renderStoryButton,
}: StoryCardCarrouselProps) => {
	return (
		<SimpleGrid
			data-testid="story-card-carousel"
			my="5"
			gap="5"
			w="full"
			columns={[2, 3, 3]}
		>
			{Array.isArray(stories) &&
				stories.map((s: SchemaStoryResponseDto) => {
					return (
						<StoryCard
							key={s.id}
							dueDate={dueDate}
							story={s}
							userId={userId}
							hideStatus={hideStatus}
							renderStoryButton={renderStoryButton}
						/>
					);
				})}
		</SimpleGrid>
	);
};
