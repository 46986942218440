import { submitPostHog } from './submitPostHog';
import {
	SidebarEntryClickedEvent,
	TrackingEventType,
} from '../types/TrackingEvent';

export function submitSidebarEntryClicked(name: string, section: string) {
	if (!name) {
		return;
	}
	submitPostHog({
		key: TrackingEventType.SIDEBAR_ENTRY_CLICKED,
		meta: {
			name,
			section,
		},
	} as SidebarEntryClickedEvent);
}
