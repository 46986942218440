import { Box, Flex, Image, Separator } from '@chakra-ui/react';
import { SchemaStoryResponseDto } from '@jam/api-sdk/api';
import {
	AccordionItem,
	AccordionItemContent,
	AccordionItemTrigger,
	AccordionRoot,
	InformationCard,
	Text,
} from '@jam/front-library';
import { Circle, Target, User } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';

type HintsAndGoalsProps = {
	story?: SchemaStoryResponseDto;
};

export const HintsAndGoals = ({ story }: HintsAndGoalsProps) => {
	const { t } = useTranslation('call');

	if (!story) {
		return null;
	}

	const goal = story.scenario.goal;
	const scenario = story.scenario.description;
	const personaBase = story.persona;

	return (
		<>
			<InformationCard
				icon={<Target size={32} />}
				title={t('your_goal')}
				description={goal}
				variant="primary"
			/>
			<AccordionRoot multiple defaultValue={['0']}>
				<AccordionItem
					w={'100%'}
					borderWidth={'1.5px'}
					borderRadius={'12px'}
					borderColor={'gray.300'}
					value="0"
				>
					<Box
						borderRadius={'24px'}
						border={'1px'}
						borderColor={'#D9D9D9'}
						px="4"
						py="6"
					>
						<AccordionItemTrigger
							_hover={{ background: 'transparent' }}
							w="100%"
						>
							<Flex
								alignItems={'center'}
								w="100%"
								justifyContent={'space-between'}
							>
								<Flex gap="2" alignItems={'center'}>
									<Box
										border={'1px'}
										p="2"
										borderRadius={'24px'}
										borderColor={'#D9D9D9'}
									>
										<Image src="/icons/scenario-list.svg" />
									</Box>
									<Text variant="h3">{t('scenario')}</Text>
								</Flex>
							</Flex>
						</AccordionItemTrigger>
						<AccordionItemContent>
							<Separator color={'#757575'} mt="2" mb="4" />
							<Flex direction={'column'} gap="2" mt="3">
								{scenario
									?.trim()
									?.split('.')
									?.filter((x) => !!x)
									?.map((sentence) => (
										<Flex alignItems={'center'} gap="2" key={sentence}>
											<Box flexShrink={0}>
												<Circle color="#757575" size={5} weight="fill" />
											</Box>
											<Text variant="label-body">{sentence}</Text>
										</Flex>
									))}
							</Flex>
						</AccordionItemContent>
					</Box>
				</AccordionItem>
				{personaBase.description && (
					<AccordionItem
						w={'100%'}
						borderWidth={'1.5px'}
						borderRadius={'12px'}
						borderColor={'gray.300'}
						mt="8"
						value="1"
					>
						<Box
							borderRadius={'24px'}
							border={'1px'}
							borderColor={'#D9D9D9'}
							px="4"
							py="6"
						>
							<AccordionItemTrigger
								_hover={{ background: 'transparent' }}
								w="100%"
							>
								<Flex
									alignItems={'center'}
									w="100%"
									justifyContent={'space-between'}
								>
									<Flex gap="2" alignItems={'center'}>
										<Box
											border={'1px'}
											p="2"
											borderRadius={'24px'}
											borderColor={'#D9D9D9'}
										>
											<User size={20} />
										</Box>
										<Text variant="h3">
											{t('about_person', { name: personaBase.name })}
										</Text>
									</Flex>
								</Flex>
							</AccordionItemTrigger>
							<AccordionItemContent>
								<Separator color={'#757575'} mt="2" mb="4" />
								<Text variant="label-body">{personaBase.description}</Text>
							</AccordionItemContent>
						</Box>
					</AccordionItem>
				)}
			</AccordionRoot>
		</>
	);
};
