import { submitPostHog } from './submitPostHog';
import { PageVisitedEvent, TrackingEventType } from '../types/TrackingEvent';

export function submitPageVisitedEvent(name: string, activeTab?: string) {
	if (!name) {
		return;
	}
	submitPostHog({
		key: TrackingEventType.PAGE_VISITED,
		meta: {
			name,
			activeTab,
		},
	} as PageVisitedEvent);
}
