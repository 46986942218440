import React from 'react';
import { AllTimeCompletions } from './components/AllTimeCompletions';
import { KeyImprovementStories } from './components/KeyImprovementStories';
import { RecentActivity } from './components/RecentActivity';

interface Props {
	userId?: string;
	view: 'manager' | 'learner';
}

export const UserDetailsOverview: React.FC<Props> = ({
	userId,
	view,
}: Props) => {
	if (!userId) return null;

	return (
		<>
			<AllTimeCompletions userId={userId} />
			<KeyImprovementStories userId={userId} />
			<RecentActivity userId={userId} seeAllLink={'?activeTab=2'} view={view} />
		</>
	);
};
