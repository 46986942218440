import {
	BasePage,
	PageContent,
	TabsContent,
	TabsIndicator,
	TabsList,
	TabsRoot,
	TabsTrigger,
} from '@jam/front-library';
import { useTranslation } from 'react-i18next';
import { PerformanceByMember } from '../features/performance/components/PerformanceByMember';
import { PerformanceByMission } from '../features/performance/components/PerformanceByMission';
import { PerformanceByTask } from '../features/performance/components/PerformanceByTask';
import { TeamPerformanceChart } from '../features/performance/components/TeamPerformanceChart';

export const PerformancePage = () => {
	const { t } = useTranslation('performance');

	return (
		<BasePage
			title={t('performance')}
			subtitle={t('performance_description') ?? ''}
			trackingKey="performance"
		>
			<TabsRoot lazyMount defaultValue="performance-chart">
				<TabsList>
					<TabsTrigger value="performance-chart">
						{t('performance_chart')}
					</TabsTrigger>
					<TabsTrigger value="by-member">{t('by_member')}</TabsTrigger>
					<TabsTrigger value="by-mission">{t('by_mission')}</TabsTrigger>
					<TabsTrigger value="by-task">{t('by_task')}</TabsTrigger>
					<TabsIndicator />
				</TabsList>
				<TabsContent value="performance-chart">
					<PageContent>
						<TeamPerformanceChart />
					</PageContent>
				</TabsContent>
				<TabsContent value="by-member">
					<PageContent>
						<PerformanceByMember />
					</PageContent>
				</TabsContent>
				<TabsContent value="by-mission">
					<PerformanceByMission />
				</TabsContent>
				<TabsContent value="by-task">
					<PerformanceByTask />
				</TabsContent>
			</TabsRoot>
		</BasePage>
	);
};
