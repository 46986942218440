import { Box, Image, useDisclosure, VStack } from '@chakra-ui/react';
import {
	Button,
	DialogBackdrop,
	DialogBody,
	DialogContent,
	DialogHeader,
	DialogRoot,
	DialogTitle,
	Text,
} from '@jam/front-library';
import { useTour } from '@reactour/tour';
import React, { useEffect } from 'react';
import { useIsOnboarding } from '../../hooks/useIsOnboarding';
import { OnboardingFeature } from '../../types/Onboarding';
import { useOnboardingStepFallback } from '../../hooks/useOnboardingStepFallback';

interface Props {
	feature: OnboardingFeature;
	title: string;
	description: string;
	cta: string;
}
export const OnboardingModal: React.FC<Props> = ({
	feature,
	title,
	description,
	cta,
}: Props) => {
	const { open: isOpen, onOpen, onClose } = useDisclosure();
	const { setIsOpen } = useTour();
	const isOnboarding = useIsOnboarding(feature);
	useOnboardingStepFallback();

	useEffect(() => {
		if (isOnboarding) {
			onOpen();
		}
	}, [isOnboarding, onOpen]);

	const onGetStarted = () => {
		onClose();
		setTimeout(() => {
			setIsOpen(true);
		}, 200);
	};

	return (
		<DialogRoot
			open={isOpen}
			onOpenChange={({ open }) => {
				if (!open) {
					onClose();
				}
			}}
			placement="center"
		>
			<DialogBackdrop />
			<DialogContent maxW="450px" py={6} borderRadius={'24px'}>
				<DialogBody p={0}>
					<VStack gap={4} align="center">
						<DialogHeader>
							<VStack gap={4} align="center">
								<Image src="/logo-color.svg" alt="Jam Logo" w="24px" h="24px" />
								<DialogTitle textStyle="h2" textAlign="center">
									{title}
								</DialogTitle>
							</VStack>
						</DialogHeader>
						<Box mx={6}>
							<Text textAlign="center">{description}</Text>
						</Box>
						<Box width={'full'} px={6}>
							<Button onClick={onGetStarted} size="full">
								{cta}
							</Button>
						</Box>
					</VStack>
				</DialogBody>
			</DialogContent>
		</DialogRoot>
	);
};
