import {
	SchemaGeneratorGetStoryDraftResponseBodyDto,
	SchemaGeneratorSaveStoryDraftRequestBodyDto,
	SchemaJourneyResponseDto,
	SchemaStoryResponseDto,
} from '@jam/api-sdk/api';
import { createApi } from '@reduxjs/toolkit/query/react';
import { buildBaseQuery } from './buildBaseQuery';

const api = createApi({
	reducerPath: 'cms-api',
	baseQuery: buildBaseQuery('/api/v1'),
	endpoints: (builder) => ({
		getStory: builder.query<SchemaStoryResponseDto, string>({
			query: (id: string) => `stories/${id}`,
		}),
		getJourneys: builder.query<SchemaJourneyResponseDto[], void>({
			query: () => `journeys`,
		}),
		getJourney: builder.query<SchemaJourneyResponseDto, string>({
			query: (journeyId: string) => `journeys/${journeyId}`,
		}),
		getStories: builder.query<SchemaStoryResponseDto[], void>({
			query: () => 'stories',
		}),
		getStoryDraft: builder.query<
			SchemaGeneratorGetStoryDraftResponseBodyDto,
			string
		>({
			query: (storyId) => ({
				url: `generator/stories/draft/${storyId}`,
				method: 'GET',
			}),
		}),
		createStoryFromDraft: builder.mutation<
			SchemaStoryResponseDto,
			SchemaGeneratorSaveStoryDraftRequestBodyDto
		>({
			query: (body) => ({
				url: `generator/stories/draft`,
				method: 'POST',
				body,
			}),
		}),
		updateStoryWithDraft: builder.mutation<
			SchemaStoryResponseDto,
			{
				storyId: string;
				body: SchemaGeneratorSaveStoryDraftRequestBodyDto;
			}
		>({
			query: ({ storyId, body }) => ({
				url: `generator/stories/draft/${storyId}`,
				method: 'PATCH',
				body,
			}),
		}),
	}),
});

export const {
	useGetJourneysQuery,
	useGetJourneyQuery,
	useGetStoryQuery,
	useLazyGetStoryQuery,
	useGetStoriesQuery,
	useGetStoryDraftQuery,
	useCreateStoryFromDraftMutation,
	useUpdateStoryWithDraftMutation,
} = api;
export default api;
