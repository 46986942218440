import { Box, Flex, Skeleton } from '@chakra-ui/react';
import { Button, Section, SkeletonText } from '@jam/front-library';
import { CaretRight } from '@phosphor-icons/react';
import { useAuthInfo } from '@propelauth/react';
import { useTranslation } from 'react-i18next';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { CallBar } from '../../../common/components/CallBar';
import { useGetFirstNotCompletedStory } from '../../../common/utils/getfirstNotCompletedStory';
import { submitMissionStarted } from '../../tracking/util/submitMissionEvents';
import { CallBarPlaceholder } from '../../../common/components/CallBarPlaceholder';

const LoadingState = () => {
	return (
		<Flex role="placeholder" direction={'column'} gap="3">
			<Box maxW="300px">
				<SkeletonText noOfLines={1} height={'30px'} />
			</Box>
			<Flex gap="4" alignItems={'center'}>
				<Skeleton borderRadius={'8px'} h="175px" w="175px" />
				<SkeletonText w={'full'} noOfLines={5} gap={'5'} />
			</Flex>
		</Flex>
	);
};

export const NextStory = () => {
	const { user } = useAuthInfo();
	const { t } = useTranslation(['home', 'story', 'mission']);
	const navigate: NavigateFunction = useNavigate();
	const { activeStory, isLoading: isLoadingActiveStory } =
		useGetFirstNotCompletedStory();

	const renderStoryButton = (storyId: string) => {
		const buttonText = t('mission:start_mission');

		return (
			<Button
				className="next-story-start-button"
				onClick={() => {
					submitMissionStarted(storyId, 'next_story_start_button');
					navigate(`/call?storyId=${storyId}`);
				}}
				icon={<CaretRight size="12px" />}
				iconPosition={'end'}
			>
				{buttonText}
			</Button>
		);
	};

	if (isLoadingActiveStory) {
		return <LoadingState />;
	}

	if (!user) {
		return null;
	}

	return (
		<Section title={t('your_next_mission').toString()}>
			{activeStory ? (
				<CallBar
					story={activeStory}
					dueDate={activeStory.dueDate}
					userId={user.userId}
					renderStoryButton={renderStoryButton}
				/>
			) : (
				<CallBarPlaceholder />
			)}
		</Section>
	);
};
