import { Box } from '@chakra-ui/react';
import { ComponentPropsWithoutRef, useEffect, useState } from 'react';

type HighlightProps = ComponentPropsWithoutRef<'div'> & {
	trigger: unknown;
	duration?: number;
};

export const Highlight = ({
	trigger,
	duration = 500,
	children,
	...props
}: HighlightProps) => {
	const serializedTrigger = JSON.stringify(trigger);
	const [previous, setPrevious] = useState<string>(serializedTrigger);
	const [didHighlight, setDidHighlight] = useState(false);

	useEffect(() => {
		const handler = setTimeout(() => {
			if (previous !== trigger) {
				setDidHighlight(true);
			}
			setPrevious(serializedTrigger);
		}, duration);

		return () => {
			clearTimeout(handler);
		};
	}, [duration, previous, serializedTrigger]);

	return (
		<Box
			flex={1}
			data-highlight={
				previous !== serializedTrigger ? 'on' : didHighlight ? 'off' : null
			}
			bg={
				previous !== serializedTrigger
					? 'red.500'
					: didHighlight
						? undefined
						: undefined
			}
			{...props}
		>
			{children}
		</Box>
	);
};
