import {
	PathsApiV1AssignmentsGetParametersQueryType,
	SchemaAssignedContentPaginatedResponseDto,
	SchemaAssignedContentResponseDto,
	SchemaCreateAssignContentRequestDto,
} from '@jam/api-sdk/api';
import { createApi } from '@reduxjs/toolkit/query/react';
import { buildBaseQuery } from './buildBaseQuery';

const api = createApi({
	reducerPath: 'content-allocation-api',
	tagTypes: ['assignments'],
	baseQuery: buildBaseQuery('/api/v1'),
	endpoints: (builder) => ({
		getCertifications: builder.query<
			SchemaAssignedContentResponseDto[],
			string
		>({
			query: (userId: string) => `certifications/${userId}`,
		}),
		getAssignedContentAllocation: builder.query<
			SchemaAssignedContentResponseDto[],
			string
		>({
			providesTags: ['assignments'],
			query: (userId: string) => `assignments/all/${userId}`,
		}),
		getAllContentAllocations: builder.query<
			SchemaAssignedContentResponseDto[],
			string
		>({
			providesTags: ['assignments'],
			query: (userId: string) => `assignments/all?userIds[]=${userId}`,
		}),
		getPaginatedCompletedAssignedContentAllocation: builder.query<
			SchemaAssignedContentPaginatedResponseDto,
			{
				type?: PathsApiV1AssignmentsGetParametersQueryType;
				userId: string;
				page: number;
				limit: number;
			}
		>({
			providesTags: ['assignments'],
			query: ({ type, userId, limit, page }) => ({
				url: 'assignments',
				params: {
					type,
					'userIds[]': [userId],
					completed: true,
					page,
					limit,
				},
			}),
		}),
		getCompletedAssignedContentAllocation: builder.query<
			SchemaAssignedContentResponseDto[],
			string
		>({
			providesTags: ['assignments'],
			query: (userId: string) =>
				`assignments/all?userIds[]=${userId}&completed=true`,
		}),
		assignContent: builder.mutation<void, SchemaCreateAssignContentRequestDto>({
			invalidatesTags: ['assignments'],
			query: ({ userIds, reference, dueDate }) => ({
				url: `assignments`,
				method: 'POST',
				body: {
					userIds,
					reference,
					dueDate,
				},
			}),
		}),
		skipAssignment: builder.mutation<void, string>({
			invalidatesTags: ['assignments'],
			query: (id: string) => ({
				url: `assignments/skip/${id}`,
				method: 'PATCH',
			}),
		}),
	}),
});

export const {
	useGetCertificationsQuery,
	useGetAllContentAllocationsQuery,
	useGetAssignedContentAllocationQuery,
	useGetPaginatedCompletedAssignedContentAllocationQuery,
	useGetCompletedAssignedContentAllocationQuery,
	useAssignContentMutation,
} = api;
export default api;
