import { ReactNode } from 'react';
import { JamTrackingProvider } from '@jam/front-library';
import { submitPageVisitedEvent } from '../features/tracking/util/submitPageEvents';
import { submitElementClickedEvent } from '../features/tracking/util/submitElementClickEvents';
import { submitSidebarEntryClicked } from '../features/tracking/util/submitSidebarEvents';

interface FrontLibraryTrackingProviderProps {
	children: ReactNode;
}

export const FrontLibraryTrackingProvider = ({
	children,
}: FrontLibraryTrackingProviderProps) => {
	// Empty tracking functions that you can implement later
	const handlePageVisited = (name: string, activeTab?: string) => {
		submitPageVisitedEvent(name, activeTab);
	};

	const handleElementClicked = (name: string) => {
		submitElementClickedEvent(name);
	};

	const handleSidebarEntryClicked = (name: string, section: string) => {
		if (!['learner', 'manager', 'insights', 'help'].includes(section)) {
			console.warn('Invalid section:', section);
			return;
		}
		submitSidebarEntryClicked(name, section);
	};

	return (
		<JamTrackingProvider
			onPageVisited={handlePageVisited}
			onElementClicked={handleElementClicked}
			onSidebarEntryClicked={handleSidebarEntryClicked}
		>
			{children}
		</JamTrackingProvider>
	);
};
