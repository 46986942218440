import { AccordionItemBody } from '@chakra-ui/react';
import { SchemaGeneratorGetStoryDraftResponseBodyDto } from '@jam/api-sdk/api';
import {
	AccordionItem,
	AccordionItemContent,
	AccordionItemTrigger,
	AccordionRoot,
	Field,
	Input,
	Textarea,
} from '@jam/front-library';
import { Highlight } from './Highlight';

type MakerScenarioViewProps = {
	draft: SchemaGeneratorGetStoryDraftResponseBodyDto;
};

export const MakerScenarioView = ({ draft }: MakerScenarioViewProps) => {
	return (
		<AccordionRoot collapsible>
			<AccordionItem value="1">
				<AccordionItemTrigger>
					<Highlight trigger={draft.scenario.description}>
						<Field>
							<Textarea
								autoresize
								maxH="50lh"
								value={draft.scenario.description}
								size="sm"
							/>
						</Field>
					</Highlight>
				</AccordionItemTrigger>
				<AccordionItemContent>
					<AccordionItemBody>
						<Highlight trigger={draft.scenario.tag}>
							<Field label="Tag">
								<Input value={draft.scenario.tag} />
							</Field>
						</Highlight>

						<Highlight trigger={draft.scenario.goal}>
							<Field label="Goal">
								<Input value={draft.scenario.goal} />
							</Field>
						</Highlight>

						<Highlight trigger={draft.scenario.duration}>
							<Field label="Duration">
								<Input value={draft.scenario.duration} />
							</Field>
						</Highlight>

						<Highlight trigger={draft.scenario.description_for_ai}>
							<Field
								label="Description for AI"
								helperText="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor."
							>
								<Textarea
									autoresize
									maxH="50lh"
									value={draft.scenario.description_for_ai}
									size="sm"
								/>
							</Field>
						</Highlight>

						{draft.scenario.conversation_items.map((item, index) => (
							<Highlight key={index} trigger={item}>
								<Field
									label={`Title ${index + 1}`}
									helperText="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor."
								>
									<Input value={item.conversation_step_title} />
								</Field>

								<Field
									label={`Type ${index + 1}`}
									helperText="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor."
								>
									<Input value={item.type} />
								</Field>

								<Field
									label={`Instructions ${index + 1}`}
									helperText="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor."
								>
									<Textarea
										autoresize
										maxH="50lh"
										value={item.conversation_step_instructions}
										size="sm"
									/>
								</Field>
							</Highlight>
						))}
					</AccordionItemBody>
				</AccordionItemContent>
			</AccordionItem>
		</AccordionRoot>
	);
};
