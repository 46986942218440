import { Flex } from '@chakra-ui/react';
import { SchemaJourneyResponseDto } from '@jam/api-sdk/api';
import { Chip } from '@jam/front-library';
import { useTranslation } from 'react-i18next';

type JourneyLabelsProps = { journey: SchemaJourneyResponseDto };

export const JourneyLabels = ({ journey }: JourneyLabelsProps) => {
	const { t } = useTranslation('story');

	if (!journey) return null;

	return (
		<Flex flexWrap="wrap" gap="2">
			{journey.conversation_topic.map((topic) => (
				<Chip
					color="#757575"
					minH="24px"
					bg="white"
					px="12px"
					borderRadius="16px"
					bgColor="#FAFAFA"
					border="1px"
					borderColor="#D9D9D9"
					key={topic}
					text={t(topic)}
					textStyle={'small-print'}
				/>
			))}
		</Flex>
	);
};
