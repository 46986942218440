import { useDisclosure } from '@chakra-ui/react';

import { SchemaStoryResponseDto } from '@jam/api-sdk/api';
import { useAuthInfo } from '@propelauth/react';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { CallBar } from '../../../common/components/CallBar';
import {
	useLazyGetStoryLastSessionQuery,
	useLazyGetStorySessionCountQuery,
} from '../../../redux/api/sessionApi';
import { setHasUserMadeCall } from '../../../redux/slice';
import { useActiveCallTabIndex } from '../hooks/useActiveCallTabIndex';
import { useActiveSession } from '../hooks/useActiveSession';
import { useShouldAskForFeedback } from '../hooks/useShouldAskForFeedback';
import { CallerButtons } from './CallerButtons';
import UserFeedbackModal from './UserFeedbackModal';

type CallerProps = {
	numberOfRounds: number;
	dueDate?: Date;
	story?: SchemaStoryResponseDto;
};

export const Caller = ({ numberOfRounds, story, dueDate }: CallerProps) => {
	const { user } = useAuthInfo();
	const { open: isOpen, onOpen, onClose } = useDisclosure();
	const { setActiveSession } = useActiveSession();
	const dispatch = useDispatch();
	const storyId = story?.id;

	const askForFeedback = useShouldAskForFeedback(user?.userId);
	const [getLastStoryQuery] = useLazyGetStoryLastSessionQuery();
	const [getStorySessionCountQuery] = useLazyGetStorySessionCountQuery();
	const { setActiveCallTabIndex } = useActiveCallTabIndex();

	useEffect(() => {
		if (askForFeedback) {
			onOpen();
		}
	}, [askForFeedback]);

	useEffect(() => {
		return () => {
			dispatch(setHasUserMadeCall(false));
		};
	}, []);

	const onFinishCallHandler = useCallback(async (): Promise<void> => {
		if (!storyId || !user?.userId) {
			return;
		}

		// remove session from the URL we need to load last one
		setActiveSession(null, 'replaceIn');
		await getLastStoryQuery({ id: storyId, userId: user?.userId });
		await getStorySessionCountQuery({
			id: storyId,
			userId: user?.userId,
		});
		dispatch(setHasUserMadeCall(true));
		setActiveCallTabIndex(2, 'replaceIn');
	}, [storyId, user]);

	const renderStoryButton = useCallback(() => {
		if (!story) {
			return <></>;
		}

		return (
			<CallerButtons
				story={story}
				numberOfRounds={numberOfRounds}
				onFinishCall={onFinishCallHandler}
			/>
		);
	}, [story, numberOfRounds, onFinishCallHandler]);

	if (!user) {
		return null;
	}

	return (
		<>
			{storyId && (
				<UserFeedbackModal
					storyId={storyId}
					isOpen={isOpen}
					onClose={onClose}
				/>
			)}
			<CallBar
				story={story}
				dueDate={dueDate}
				userId={user.userId}
				renderStoryButton={story && renderStoryButton}
			/>
		</>
	);
};
