import { submitPostHog } from './submitPostHog';
import { ElementClickedEvent, TrackingEventType } from '../types/TrackingEvent';

export function submitElementClickedEvent(name: string) {
	if (!name) {
		return;
	}
	submitPostHog({
		key: TrackingEventType.ELEMENT_CLICKED,
		meta: {
			name,
		},
	} as ElementClickedEvent);
}

export function submitDownloadCertificateClickEvent() {
	submitPostHog({
		key: TrackingEventType.ELEMENT_CLICKED,
		meta: {
			name: 'download_certificate',
		},
	} as ElementClickedEvent);
}

export function submitShareCertificateClickEvent() {
	submitPostHog({
		key: TrackingEventType.ELEMENT_CLICKED,
		meta: {
			name: 'share_certificate_linked_in',
		},
	} as ElementClickedEvent);
}

export function submitGetInspiredClickEvents(meta: Record<string, any> = {}) {
	submitPostHog({
		key: TrackingEventType.ELEMENT_CLICKED,
		meta: {
			name: 'get_inspired',
			...meta,
		},
	} as ElementClickedEvent);
}

export function submitProfileSettingsClicked(origin: 'sidebar' | 'topbar') {
	submitPostHog({
		key: TrackingEventType.ELEMENT_CLICKED,
		meta: {
			name: 'profile_settings',
			origin,
		},
	} as ElementClickedEvent);
}
