import { useAuthInfo } from '@propelauth/react';
import { skipToken } from '@reduxjs/toolkit/query';
import { format } from 'date-fns';
import { de } from 'date-fns/locale/de';
import { enGB } from 'date-fns/locale/en-GB';
import { useTranslation } from 'react-i18next';
import { UserDetails } from '../features/dashboard/components/user-details/UserDetails';
import { useGetDetailedUserActivityOverviewQuery } from '../redux/api/dashboardApi';
import { BasePage } from '@jam/front-library';
import { ProgressPageSteps } from '../common/components/onboarding/OnboardinSteps';
import { OnboardingNavigation } from '../common/components/onboarding/OnboardingNavigation';
import { OnboardingFeature } from '../common/types/Onboarding';
import { TourProvider } from '@reactour/tour';
import { OnboardingModal } from '../common/components/onboarding/OnboardingModal';

export const ProgressPage = () => {
	const { t } = useTranslation();
	const { user } = useAuthInfo();
	const { i18n } = useTranslation();
	const locale = i18n.language === 'en' ? enGB : de;

	const { data, isFetching } = useGetDetailedUserActivityOverviewQuery(
		user?.userId ?? skipToken
	);

	if (isFetching || !data) {
		return null;
	}

	const subtitle1 = data.registeredSince
		? t('common:joined_date', {
				date: format(new Date(data.registeredSince), 'd. MMM yyyy', {
					locale,
				}),
			})
		: '';

	const subtitle2 = data.lastSessionDate
		? t('common:last_session_date', {
				date: format(new Date(data.lastSessionDate ?? ''), 'd. MMM yyyy', {
					locale,
				}),
			})
		: '';

	const subtitle =
		subtitle1 && subtitle2
			? subtitle1 + ' • ' + subtitle2
			: subtitle1 ?? subtitle2;

	return (
		<TourProvider
			steps={ProgressPageSteps}
			components={{
				Navigation: () => (
					<OnboardingNavigation feature={OnboardingFeature.PROGRESS_PAGE} />
				),
			}}
		>
			<OnboardingModal
				feature={OnboardingFeature.PROGRESS_PAGE}
				title={t('common:onboarding.progress.title')}
				description={t('common:onboarding.progress.description')}
				cta={t('common:onboarding.progress.cta')}
			/>
			<BasePage title={data.name} subtitle={subtitle} trackingKey="progress">
				<UserDetails
					userId={user?.userId}
					userName={data.name}
					view="learner"
				/>
			</BasePage>
		</TourProvider>
	);
};
