import { SchemaTeamResponseDto } from '@jam/api-sdk/api';
import { TableCells } from '@jam/front-library';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const UserTeamCell: React.FC<{
	teams: SchemaTeamResponseDto[];
	row: {
		id: string;
		teams?: { id: string }[];
	};
	onTeamChange?: (data: {
		userId: string;
		currentTeamId?: string;
		newTeamId?: string;
	}) => Promise<void>;
}> = ({ teams, row, onTeamChange }) => {
	const { t } = useTranslation('team');
	const supportsClick = !!onTeamChange;
	const firstTeam = row.teams?.[0];

	if (!teams.length) {
		return null;
	}

	return (
		<TableCells.SelectionCell
			value={firstTeam?.id ?? ''}
			values={[
				{ value: '', label: t('no_team') ?? '' },
				...teams.map((team) => ({
					value: team.id,
					label: team.name,
				})),
			]}
			disabled={!supportsClick}
			onChange={(value) => {
				void onTeamChange?.({
					userId: row.id,
					currentTeamId: firstTeam?.id,
					newTeamId: value ? (value as string) : undefined,
				});
			}}
		/>
	);
};
