import { createContext, ReactNode, useContext, useState } from 'react';
import { ConfirmationDialog } from '../common/components/dialogs/ConfirmationDialog';

type ConfirmationOptions = {
	title: string;
	message: string;
	confirmText?: string;
	cancelText?: string;
};

type ConfirmationContextType = {
	confirm: (options: ConfirmationOptions) => Promise<boolean>;
};

const ConfirmationContext = createContext<ConfirmationContextType | undefined>(
	undefined
);

export const useConfirmationDialog = () => {
	const context = useContext(ConfirmationContext);
	if (!context) {
		throw new Error(
			'useConfirmation must be used within a ConfirmationDialogProvider'
		);
	}
	return context.confirm;
};

export const ConfirmationDialogProvider = ({
	children,
}: {
	children: ReactNode;
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [options, setOptions] = useState<ConfirmationOptions | null>(null);
	const [resolveRef, setResolveRef] = useState<
		((value: boolean) => void) | null
	>(null);

	const confirm = (
		confirmationOptions: ConfirmationOptions
	): Promise<boolean> => {
		setOptions(confirmationOptions);
		setIsOpen(true);

		return new Promise<boolean>((resolve) => {
			setResolveRef(() => resolve);
		});
	};

	const handleConfirm = () => {
		if (resolveRef) {
			setIsLoading(true);
			try {
				resolveRef(true);
			} finally {
				setIsLoading(false);
				setIsOpen(false);
			}
		}
	};

	const handleCancel = () => {
		if (resolveRef) {
			resolveRef(false);
			setIsOpen(false);
		}
	};

	return (
		<ConfirmationContext.Provider value={{ confirm }}>
			{children}
			{options && (
				<ConfirmationDialog
					isOpen={isOpen}
					onClose={handleCancel}
					onConfirm={handleConfirm}
					title={options.title}
					message={options.message}
					confirmText={options.confirmText}
					cancelText={options.cancelText}
					isLoading={isLoading}
				/>
			)}
		</ConfirmationContext.Provider>
	);
};
