import { Box, Flex, Stack } from '@chakra-ui/react';
import {
	Button,
	DialogBackdrop,
	DialogBody,
	DialogCloseTrigger,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogRoot,
	DialogTitle,
	Field,
	Text,
	Textarea,
} from '@jam/front-library';

import {
	CreateLearnerSessionFeedbackDTOLearnerSessionFeedbackPresets,
	SchemaCreateLearnerSessionFeedbackDto,
} from '@jam/api-sdk/api';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCreateSessionUserFeedbackMutation } from '../../../redux/api/userFeedbackApi';

type ReportAnIssueModalProps = {
	sessionId: string;
	isOpen: boolean;
	onClose: () => void;
};

const langProps = {
	bad_audio: 'options.bad_audio',
	interruptions: 'options.interruptions',
	call_ended_early: 'options.call_ended_early',
	other: 'options.other',
};

export const ReportAnIssueModal = ({
	sessionId,
	isOpen,
	onClose,
}: ReportAnIssueModalProps) => {
	const { t } = useTranslation('call', { keyPrefix: 'report_an_issue_modal' });
	const [createLearnerSessionFeedback, { isLoading }] =
		useCreateSessionUserFeedbackMutation();
	const { register, watch, setValue, handleSubmit } =
		useForm<SchemaCreateLearnerSessionFeedbackDto>({
			defaultValues: {
				sessionId,
				content: '',
				learnerSessionFeedbackPresets: [],
			},
		});
	const learnerSessionFeedbackPresets = watch('learnerSessionFeedbackPresets');

	const onPresetClicked = (
		val: CreateLearnerSessionFeedbackDTOLearnerSessionFeedbackPresets
	) => {
		if (!learnerSessionFeedbackPresets.includes(val)) {
			setValue('learnerSessionFeedbackPresets', [
				...learnerSessionFeedbackPresets,
				val,
			]);
		} else {
			setValue(
				'learnerSessionFeedbackPresets',
				learnerSessionFeedbackPresets.filter((p) => p !== val)
			);
		}
	};

	const onSubmit = async (data: SchemaCreateLearnerSessionFeedbackDto) => {
		if (learnerSessionFeedbackPresets.length === 0) return;

		if (!sessionId) return;

		await createLearnerSessionFeedback(data);

		setValue('content', '');
		setValue('learnerSessionFeedbackPresets', []);

		onClose();
	};

	return (
		<DialogRoot
			open={isOpen}
			onOpenChange={({ open }) => {
				if (!open) {
					onClose();
				}
			}}
			size="lg"
			placement="center"
		>
			<DialogBackdrop />
			<DialogContent>
				<DialogHeader>
					<DialogTitle textStyle="h4">{t('title')}</DialogTitle>
					<DialogCloseTrigger />
				</DialogHeader>
				<DialogBody pb={6}>
					<Stack gap={6}>
						<Flex flexWrap={'wrap'} gap="3">
							{Object.entries(
								CreateLearnerSessionFeedbackDTOLearnerSessionFeedbackPresets
							).map(([key, value]) => (
								<Box
									onClick={() => onPresetClicked(value)}
									key={key}
									cursor={'pointer'}
									color={
										learnerSessionFeedbackPresets.includes(value)
											? '#4241E4'
											: '#757575'
									}
									border={'1px solid'}
									borderColor={
										learnerSessionFeedbackPresets.includes(value)
											? '#4241E4'
											: '#D9D9D9'
									}
									px="2"
									bg={
										learnerSessionFeedbackPresets.includes(value)
											? 'rgba(66, 65, 228, 1, 0.08)'
											: 'transparent'
									}
									borderRadius={'24px'}
								>
									<Text variant="small-print">{t(langProps[value])}</Text>
								</Box>
							))}
						</Flex>

						<Field as="fieldset">
							<Textarea
								{...register('content')}
								placeholder={t('placeholder') ?? ''}
								size="sm"
							/>
						</Field>
					</Stack>
				</DialogBody>
				<DialogFooter>
					<Button
						type="submit"
						loading={isLoading}
						onClick={() => {
							void handleSubmit(onSubmit)();
						}}
						disabled={learnerSessionFeedbackPresets.length === 0}
					>
						{t('common:submit')}
					</Button>
				</DialogFooter>
			</DialogContent>
		</DialogRoot>
	);
};
