import { Box, Flex } from '@chakra-ui/react';
import {
	SchemaAssignedContentResponseDto,
	SchemaJourneyResponseDto,
} from '@jam/api-sdk/api';
import { Button, ProgressBar, ProgressRoot, Text } from '@jam/front-library';
import { GraduationCap } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { calculateJourneyCompletion } from '../../utils/calculateCompletion';
import { JourneyLabels } from '../JourneyLabels';
import { format } from 'date-fns';
import { enGB } from 'date-fns/locale/en-GB';
import { de } from 'date-fns/locale/de';
import { TrackCertificationModal } from './TrackCertificationModal';
import { useState } from 'react';

const DATE_FORMATS = {
	en: 'MM/dd/yyyy',
	de: 'dd.MM.yyyy',
} as const;

type TrackDetailHeaderProps = {
	journey: SchemaJourneyResponseDto;
	contentAllocation?: SchemaAssignedContentResponseDto;
	userName?: string;
};

export const TrackDetailHeader = ({
	journey,
	contentAllocation,
	userName,
}: TrackDetailHeaderProps) => {
	const { t } = useTranslation(['common', 'dashboard']);
	const { i18n } = useTranslation();
	const locale = i18n.language === 'en' ? enGB : de;
	const dateFormat = i18n.language === 'en' ? DATE_FORMATS.en : DATE_FORMATS.de;

	const [certificatioModalOpen, setCertificationModalOpen] =
		useState<boolean>(false);

	const completion = contentAllocation
		? calculateJourneyCompletion(journey, contentAllocation.completedStoryIds)
		: undefined;

	const formattedDate = contentAllocation?.lastUpdatedAt
		? format(contentAllocation?.lastUpdatedAt, dateFormat, {
				locale,
			})
		: undefined;

	return (
		<Box w={'100%'}>
			{contentAllocation && (
				<TrackCertificationModal
					userName={userName}
					contentAllocation={contentAllocation}
					journey={journey}
					isOpen={certificatioModalOpen}
					setIsOpen={setCertificationModalOpen}
				/>
			)}
			<Flex
				direction="row"
				w={'100%'}
				justifyContent={'space-between'}
				paddingBottom="12px"
			>
				<Text data-testid={`track_header_title_${journey.id}`} variant="h2">
					{journey.title}
				</Text>
				{completion && completion?.completed === completion?.total && (
					<Flex
						direction="row"
						alignItems="center"
						gap="2"
						css={{
							borderRadius: '4px',
							backgroundColor: '#F5F5F5',
						}}
						px="8px"
						py="4px"
					>
						<GraduationCap color="#147129" />
						<Text variant="label">
							{t('track_detail.header.completed')}
							{formattedDate && <>: {formattedDate}</>}
						</Text>
					</Flex>
				)}
			</Flex>
			<Flex
				direction="row"
				w={'100%'}
				justifyContent={'space-between'}
				paddingBottom="16px"
			>
				<Flex w="100%">
					<Text>{journey.description}</Text>
				</Flex>
				<Flex h="auto" />
			</Flex>

			<Flex
				direction="row"
				w="100%"
				justifyContent="space-between"
				paddingBottom="20px"
				alignItems="center"
			>
				<Flex>
					<JourneyLabels journey={journey} />
				</Flex>

				{completion &&
					(completion?.completed < completion?.total ? (
						<Flex gap={'2'} alignItems="end" direction="column" w="260px">
							<Text variant="label">
								{completion.completed}/{completion.total}{' '}
								{t('track_detail.header.missions_completed')}
							</Text>
							<ProgressRoot
								w="100%"
								h="6px"
								borderRadius={'20px'}
								size={'lg'}
								value={completion.completed}
								max={completion.total}
								colorPalette={'teal'}
							>
								<ProgressBar />
							</ProgressRoot>
						</Flex>
					) : (
						<Button onClick={() => setCertificationModalOpen(true)}>
							{t('dashboard:user_details.certification.view_certificate')}
						</Button>
					))}
			</Flex>
		</Box>
	);
};
