import { Box, Flex, Skeleton } from '@chakra-ui/react';
import {
	PathsApiV1SessionsGetParametersQueryDateFilter,
	PathsApiV1SessionsGetParametersQueryState,
	PathsApiV1SessionsGetParametersQueryType,
	SchemaFindSessionsTeamActivityReposeDto,
} from '@jam/api-sdk/api';
import {
	Table,
	TableCells,
	toaster,
	usePrepareTableServerSide,
} from '@jam/front-library';
import { createColumnHelper } from '@tanstack/react-table';
import { t } from 'i18next';
import { useEffect } from 'react';
import { useConfirmationDialog } from '../../providers/ConfirmationDialogProvider';
import { useGetRolePlayActivityQuery } from '../../redux/api/dashboardApi';
import { useDeleteSessionMutation } from '../../redux/api/sessionApi';
import { SolvedItemDots } from './SolvedItemDots';

const LoadingState = () => {
	return (
		<Flex role="placeholder" direction={'column'} gap="4">
			<Box maxW="300px">
				<Skeleton borderRadius={'8px'} h="30px" flex={1} />
			</Box>
			<Box display={'flex'} flexDirection={'column'} h="200px" w="100%" gap={2}>
				<Skeleton borderRadius={'8px'} h="30px" flex={1} />
				<Skeleton borderRadius={'8px'} h="30px" flex={1} />
				<Skeleton borderRadius={'8px'} h="30px" flex={1} />
				<Skeleton borderRadius={'8px'} h="30px" flex={1} />
				<Skeleton borderRadius={'8px'} h="30px" flex={1} />
			</Box>
		</Flex>
	);
};

export const UserSessionActivityTable = ({
	initialQuery,
	onRowClick,
	allowDelete,
}: {
	initialQuery: {
		teamId?: string;
		dateFilter?: PathsApiV1SessionsGetParametersQueryDateFilter;
		type?: PathsApiV1SessionsGetParametersQueryType;
		state?: PathsApiV1SessionsGetParametersQueryState;
	};
	onRowClick?: (row: { userId: string; sessionId: string }) => void;
	allowDelete?: boolean;
}) => {
	const { page, limit, data, query, serverSideProps, onDataUpdated } =
		usePrepareTableServerSide<
			SchemaFindSessionsTeamActivityReposeDto['data'][0]
		>();

	const {
		data: sessionData,
		isLoading,
		refetch,
	} = useGetRolePlayActivityQuery(
		{ ...initialQuery, ...query, page, limit },
		{
			refetchOnMountOrArgChange: true,
		}
	);

	useEffect(() => {
		onDataUpdated(sessionData);
	}, [sessionData]);

	const columnHelper =
		createColumnHelper<SchemaFindSessionsTeamActivityReposeDto['data'][0]>();
	const [deleteSession] = useDeleteSessionMutation();
	const confirmationDialog = useConfirmationDialog();

	if (isLoading) return <LoadingState />;

	if (!data) return null;

	const onSessionDelete = async (sessionId: string) => {
		if (!allowDelete) {
			return;
		}
		const confirmed = await confirmationDialog({
			title: t('history:table.delete.dialog_title').toString(),
			message: t('history:table.delete.dialog_message').toString(),
			confirmText: t('history:table.delete.dialog_confirm').toString(),
			cancelText: t('history:table.delete.dialog_cancel').toString(),
		});
		if (confirmed) {
			await deleteSession(sessionId);

			await refetch();

			toaster.create({
				title: t('history:table.delete.success'),
				type: 'success',
				duration: 3000,
				meta: {
					closable: true,
				},
			});
		}
	};
	const columns = [
		columnHelper.accessor('createdAt', {
			header: t('common:date').toString(),
			enableSorting: false,
			cell: ({ row }) => {
				if (!row.original.createdAt) return null;
				return <TableCells.DateCell date={row.original.createdAt} withTime />;
			},
		}),
		columnHelper.accessor('userName', {
			header: t('dashboard:team_member').toString(),
			cell: ({ row }) => (
				<TableCells.UserCell
					firstName={row.original.userName}
					lastName={''}
					pictureUrl={row.original.userPictureUrl ?? ''}
					email={row.original.teamName}
				/>
			),
			enableSorting: false,
			meta: {
				name: 'userName',
				filtering: {
					backendName: 'userName',
					variant: 'text',
					placeholder: t('common:search_by_name') ?? '',
				},
			},
		}),
		columnHelper.accessor('storyTitle', {
			header: t('common:mission').toString(),
			cell: ({ row }) => {
				return (
					<TableCells.TextCell>{row.original.storyTitle}</TableCells.TextCell>
				);
			},
			enableSorting: false,
			meta: {
				name: 'storyReferenceId',
				filtering: {
					backendName: 'storyTitle',
					variant: 'text',
					placeholder: t('history:search_by_mission').toString(),
				},
			},
		}),
		columnHelper.accessor('score', {
			header: t('common:score').toString(),
			cell: ({ row }) => (
				<TableCells.NumberCell>
					{Math.round(row.original.score)}
				</TableCells.NumberCell>
			),
			enableSorting: false,
			meta: {
				alignHeader: 'end',
			},
		}),
		columnHelper.accessor('results', {
			header: t('common:results').toString(),
			cell: ({ row }) => {
				return (
					<TableCells.BaseCell>
						<SolvedItemDots showMax={4} items={row.original.results} />
					</TableCells.BaseCell>
				);
			},
			enableSorting: false,
		}),
	];

	const deleteColumn = columnHelper.display({
		id: 'actions',
		header: '',
		enableSorting: false,
		cell: ({ row }) => (
			<TableCells.RowActionsCell
				actions={[
					{
						value: 'delete',
						label: t('history:table.delete.action'),
						onClick: () => {
							void onSessionDelete(row.original.sessionId);
						},
					},
				]}
			/>
		),
	});

	return (
		<Table<SchemaFindSessionsTeamActivityReposeDto['data'][0]>
			asServerSide
			serverSideProps={serverSideProps}
			data={data}
			columns={allowDelete ? [...columns, deleteColumn] : columns}
			enablePagination
			onRowClick={onRowClick}
		/>
	);
};
