/* eslint-disable @typescript-eslint/no-misused-promises */
import { Box, Flex, Stack } from '@chakra-ui/react';
import {
	PathsApiV1UsersGetParametersQueryRole,
	SchemaPendingInviteResponseDto,
} from '@jam/api-sdk/api';
import { SkeletonText, Table, TableCells, toaster } from '@jam/front-library';
import { createColumnHelper } from '@tanstack/react-table';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { CreatedAtCell } from '../../../../../common/components/custom-table/cells/common/CreatedAtCell';
import { InviteSendReminderCell } from '../../../../../common/components/custom-table/cells/manager/InviteSendReminderCell';
import { InviteStatusCell } from '../../../../../common/components/custom-table/cells/manager/InviteStatusCell';
import { UserRoleCell } from '../../../../../common/components/custom-table/cells/user/UserRoleCell';
import {
	useGetInvitedUsersQuery,
	useInviteUserToOrganizationMutation,
} from '../../../../../redux/api/crmApi';

const LoadingState = () => {
	return (
		<Flex role="loading" mt="5" direction={'column'} gap="6">
			<Box maxW="300px">
				<SkeletonText noOfLines={1} height={'30px'} />
			</Box>
			<SkeletonText w={'full'} noOfLines={5} gap={'5'} />
		</Flex>
	);
};

export const PendingInvites = () => {
	const { t } = useTranslation(['settings', 'common', 'team']);
	const { isLoading, data } = useGetInvitedUsersQuery();

	const [inviteUserToOrg] = useInviteUserToOrganizationMutation();
	const onInviteClickHandler = useCallback(
		async (email: string, role?: PathsApiV1UsersGetParametersQueryRole) => {
			const success = await inviteUserToOrg({
				email,
				role: role ?? PathsApiV1UsersGetParametersQueryRole.Member,
			});
			if (success) {
				toaster.create({
					title: t('user-invited-successfully'),
					type: 'success',
					duration: 3000,
					meta: {
						closable: true,
					},
				});
			}
		},
		[inviteUserToOrg]
	);

	const columnHelper = createColumnHelper<SchemaPendingInviteResponseDto>();
	const columns = [
		columnHelper.accessor('email', {
			header: t('common:email').toString(),
			cell: ({ row }) => (
				<TableCells.UserCell onlyEmail email={row.original.email} />
			),
			enableSorting: false,
			meta: {
				name: 'email',
				filtering: {
					variant: 'text',
					placeholder: t('team:member_search_placeholder') ?? '',
				},
			},
		}),
		columnHelper.accessor('role', {
			header: t('common:role').toString(),
			cell: ({ row }) => <UserRoleCell row={row.original} />,
			enableSorting: false,
		}),
		columnHelper.display({
			header: t('common:status').toString(),
			cell: ({ row }) => <InviteStatusCell row={row.original} />,
		}),
		columnHelper.accessor('createdAt', {
			header: t('team:date_invited').toString(),
			cell: ({ row }) => <CreatedAtCell row={row.original} />,
		}),
		columnHelper.display({
			id: 'sendReminder',
			enableSorting: false,
			enableMultiSort: false,
			sortingFn: undefined,
			header: '',
			cell: ({ row }) => (
				<InviteSendReminderCell
					row={row.original}
					onClick={onInviteClickHandler}
				/>
			),
		}),
	];

	if (isLoading) {
		return <LoadingState />;
	}

	const userData = data ?? [];

	return (
		<Stack direction="column" gap="5">
			{userData.length > 0 && (
				<Table<SchemaPendingInviteResponseDto>
					data={userData}
					columns={columns}
					enablePagination
				/>
			)}
		</Stack>
	);
};
