import { skipToken } from '@reduxjs/toolkit/query';
import { useGetAssignedContentAllocationQuery } from '../../redux/api/contentAllocationApi';

export enum StoryStatus {
	NOT_STARTED = 'NOT_STARTED',
	COMPLETED = 'COMPLETED',
}

export const useStoryStatus = (
	storyId?: string,
	userId?: string
): StoryStatus => {
	const { data: assignedContentAllocations } =
		useGetAssignedContentAllocationQuery(userId ?? skipToken);

	if (!storyId || !userId) {
		return StoryStatus.NOT_STARTED;
	}

	if (!assignedContentAllocations) {
		return StoryStatus.NOT_STARTED;
	}

	const completedStories = assignedContentAllocations.flatMap(
		(userJourney) => userJourney.completedStoryIds
	);

	// Check if the story is completed by checking if the storyId is in the completedStories array
	if (completedStories.includes(storyId)) {
		return StoryStatus.COMPLETED;
	}

	// If the story is not completed or in progress, it is not started
	return StoryStatus.NOT_STARTED;
};
