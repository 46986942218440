import { Flex } from '@chakra-ui/react';
import {
	SchemaCreateVapiConfigRequestDto,
	SchemaGeneratorGetStoryDraftResponseBodyDto,
} from '@jam/api-sdk/api';
import { toaster } from '@jam/front-library';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useCreateVapiConfigMutation } from '../../../redux/api/conversationApi';
import { useAppSelector } from '../../../redux/hooks';
import { selectCurrentCallStatus } from '../../../redux/slice';
import { AnimatedCallButton } from '../../call/components/AnimatedCallButton';
import { CallStatus } from '../../call/components/CallStatus';
import { useVapi } from '../../call/hooks/useVapi';

type MakeTestButtonProps = {
	draft: SchemaGeneratorGetStoryDraftResponseBodyDto;
	onFinishCall: () => Promise<void>;
};

export const MakerTestButton = ({
	draft,
	onFinishCall,
}: MakeTestButtonProps) => {
	const { t } = useTranslation(['call', 'story']);
	const callStatus =
		useAppSelector(selectCurrentCallStatus) ?? CallStatus.notStarted;
	const { start, stop, error, callDurationSec } = useVapi();
	const [createVapiConfig] = useCreateVapiConfigMutation();

	const mergeStoryDraft = (
		draftSource: SchemaGeneratorGetStoryDraftResponseBodyDto
	): SchemaCreateVapiConfigRequestDto => {
		const saga = {
			id: 'fake-id',
			name: 'Test saga',
		};
		const language = draftSource.language;

		return {
			id: 'fake-id',
			saga,
			language,
			persona: {
				description: draftSource.persona.description,
				voice: draftSource.persona.voice,
				ai_instruction: draftSource.persona.ai_instruction,
				name: draftSource.persona.name,
				role: draftSource.persona.role,
				workplace_name: draftSource.persona.workplace_name,
			},
			scenario: {
				tag: draftSource.scenario.tag,
				conversation_items: draftSource.scenario.conversation_items,
				description_for_ai: draftSource.scenario.description_for_ai,
			},
			learning_objective: draftSource.learning_objective,
			keywords: draftSource.keywords ?? [],
			firstMessageMode: draftSource.firstMessageMode,
		};
	};

	const onClickHandler = useCallback(async () => {
		if (
			callStatus === CallStatus.notStarted ||
			// user can start a new call
			callStatus === CallStatus.finished
		) {
			const config = await createVapiConfig(mergeStoryDraft(draft)).unwrap();

			void start(config);
		} else if (callStatus === CallStatus.started) {
			stop();
		}
	}, [callStatus, start, stop]);

	useEffect(() => {
		if (callStatus !== CallStatus.finished || callDurationSec === null) return;

		void onFinishCall();
	}, [onFinishCall, callStatus, callDurationSec]);

	useEffect(() => {
		if (!error) return;

		toaster.create({
			title: 'Error',
			description: t('call_error_text'),
			type: 'error',
		});
	}, [error]);

	return (
		<Flex flexShrink={0} gap="2" alignItems={'center'} h="fit-content">
			<AnimatedCallButton
				firstTime={true}
				isPrimary={true}
				callStatus={callStatus}
				onCallerClick={() => void onClickHandler()}
			/>
		</Flex>
	);
};
