import { SchemaMissionPerformanceResponseDto } from '@jam/api-sdk/api';
import { Table, TableCells } from '@jam/front-library';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { MissionDetailDots } from '../../../common/components/custom-table/cells/manager/MissionDetailDots';
import { useGetTeamPerformanceByMissionQuery } from '../../../redux/api/dashboardApi';

type TableData = SchemaMissionPerformanceResponseDto & {
	completionPercentage: number | null;
};

export const LoadingState = () => {
	return <div>Loading...</div>;
};

export const PerformanceByMission = () => {
	const { t } = useTranslation('performance');
	const { data, isLoading } = useGetTeamPerformanceByMissionQuery();
	const columnHelper = createColumnHelper<TableData>();

	if (isLoading) {
		return <LoadingState />;
	}

	if (!data || data.length === 0) {
		return <div>No data</div>;
	}

	const tracks = [...new Set(data.map((performance) => performance.trackName))];

	const columns = [
		columnHelper.accessor('missionName', {
			header: t('performance_by_mission.mission') ?? '',
			cell: (info) => {
				return (
					<TableCells.UserCell
						firstName={info.getValue()}
						email={info.row.original.personaName}
						pictureUrl={info.row.original.personaImage ?? ''}
					/>
				);
			},
			enableSorting: false,
			meta: {
				name: 'missionName',
				filtering: {
					variant: 'text',
					placeholder:
						t('performance_by_mission.search_mission_placeholder') ?? '',
				},
			},
		}),
		columnHelper.accessor('trackName', {
			header: t('performance_by_mission.track') ?? '',
			cell: (info) => (
				<TableCells.TextCell>{info.getValue() ?? '-'}</TableCells.TextCell>
			),
			enableSorting: false,
			meta: {
				name: 'trackName',
				filtering: {
					variant: 'select',
					placeholder: t('performance_by_mission.all_tracks') ?? '',
					options: [
						{
							label: t('performance_by_mission.all_tracks'),
							value: '',
						},
						...tracks.map((track) => ({
							label: track,
							value: track,
						})),
					],
				},
			},
		}),
		columnHelper.accessor('roundsPlayed', {
			header: t('performance_by_mission.rounds_played') ?? '',
			cell: (info) => (
				<TableCells.NumberCell>{info.getValue() ?? '-'}</TableCells.NumberCell>
			),
			meta: {
				alignHeader: 'end',
			},
		}),
		columnHelper.accessor('completionPercentage', {
			header: t('performance_by_mission.completions_rounds') ?? '',
			cell: (info) => (
				<TableCells.NumberCell>
					{info.row.original.roundsCompleted} / {info.row.original.roundsPlayed}
				</TableCells.NumberCell>
			),
			meta: {
				alignHeader: 'end',
			},
		}),
		columnHelper.accessor('averageScore', {
			header: t('performance_by_mission.average_score') ?? '',
			cell: (info) => (
				<TableCells.NumberCell>{info.getValue() ?? '-'}</TableCells.NumberCell>
			),
			meta: {
				alignHeader: 'end',
			},
		}),
		columnHelper.accessor('averageRoundToCompletion', {
			header: t('performance_by_mission.rounds_to_complete') ?? '',
			cell: (info) => (
				<TableCells.NumberCell>{info.getValue() ?? '-'}</TableCells.NumberCell>
			),
			meta: {
				alignHeader: 'end',
			},
		}),
		columnHelper.display({
			id: 'dots',
			cell: ({ row }) => {
				return <MissionDetailDots missionId={row.original.missionId} />;
			},
		}),
	];

	const tableData = data.map((performance) => ({
		...performance,
		completionPercentage:
			performance.roundsPlayed === 0
				? null
				: (performance.roundsCompleted / performance.roundsPlayed) * 100,
	}));

	return (
		<Table<TableData>
			data={tableData}
			defaultSorting={[
				{
					id: 'averageScore',
					desc: false,
				},
			]}
			columns={columns}
			enablePagination
		/>
	);
};
