import { AccordionRoot } from '@jam/front-library';
import { ContentAssignmentNameDateSelection } from '../steps/ContentAssignmentNameDateSelection';
import {
	SchemaJourneyResponseDto,
	SchemaTeamResponseDto,
} from '../../../../../../../packages/api-sdk/src/api';
import React from 'react';
import { ContentAssignmentTeamSelect } from '../steps/ContentAssignmentTeamSelect';
import { ContentAssignmentJourneysTable } from '../steps/ContentAssignmentJourneysTable';
import {
	ContentAssignmentStepAccordion,
	ContentAssignmentStepAccordionProps,
} from './ContentAssignmentStepAccordion';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { Separator } from '@chakra-ui/react';
import { JourneyDetailsModal } from '../dialogs/JourneyDetailsModal';
import { ContentAssignmentSuccessDialog } from '../dialogs/ContentAssignmentSuccessDialog';
import { ContentAssignmentActions } from './ContentAssignmentActions';

const createSprintScheduleRequestDTOSchemaFrontend = z.object({
	title: z.string().min(1),
	contentReference: z.array(z.string().min(1)).min(1),
	assignee: z.array(z.string().min(1)).min(1),
	dueDate: z.date(),
});

export type CreateSprintScheduleRequestDTOSchemaFrontendDTO = z.infer<
	typeof createSprintScheduleRequestDTOSchemaFrontend
>;

interface Props {
	teams?: SchemaTeamResponseDto[];
	journeys?: SchemaJourneyResponseDto[];
	loading?: boolean;
	openAll?: boolean;
}

export const ContentAssignment: React.FC<Props> = ({
	teams,
	journeys,
	loading,
	openAll = false,
}) => {
	const {
		handleSubmit,
		control,
		formState: { errors, isValid },
		setValue,
		reset,
	} = useForm<CreateSprintScheduleRequestDTOSchemaFrontendDTO>({
		resolver: zodResolver(createSprintScheduleRequestDTOSchemaFrontend),
		defaultValues: {
			title: '',
			contentReference: [],
			assignee: [],
			// A week from now
			dueDate: new Date(new Date().setDate(new Date().getDate() + 7)),
		},
	});

	const [dialogOpen, setDialogOpen] = React.useState(false);
	const [dialogJourney, setDialogJourney] =
		React.useState<SchemaJourneyResponseDto | null>(null);
	const [confirmationDialogOpen, setConfirmationDialogOpen] =
		React.useState(false);

	if (loading) {
		return null;
	}

	const steps = [
		{
			titleKey: 'tracks.title',
			subtitleKey: 'tracks.subtitle',
			stepIndex: 1,
			children: (
				<ContentAssignmentJourneysTable
					errors={errors}
					journeys={journeys ?? []}
					control={control}
					onViewDetails={(journey) => {
						setDialogJourney(journey);
						setDialogOpen(true);
					}}
				/>
			),
		},
		{
			titleKey: 'teams.title',
			subtitleKey: 'teams.subtitle',
			stepIndex: 2,
			children: (
				<ContentAssignmentTeamSelect
					teams={teams ?? []}
					control={control}
					errors={errors}
				/>
			),
		},
		{
			titleKey: 'due_date.title',
			subtitleKey: 'due_date.subtitle',
			stepIndex: 3,
			children: (
				<ContentAssignmentNameDateSelection control={control} errors={errors} />
			),
		},
	] as ContentAssignmentStepAccordionProps[];

	const onConfirmationClose = () => {
		reset();
		setConfirmationDialogOpen(false);
	};

	return (
		<>
			<JourneyDetailsModal
				isOpen={dialogOpen && !!dialogJourney}
				journey={dialogJourney as SchemaJourneyResponseDto}
				onClose={(assigned?: boolean) => {
					if (assigned) {
						setValue('contentReference', [dialogJourney?.id] as string[]);
					}
					setDialogOpen(false);
				}}
			/>
			<ContentAssignmentSuccessDialog
				isOpen={confirmationDialogOpen}
				onClose={onConfirmationClose}
			/>
			<AccordionRoot
				multiple
				defaultValue={openAll ? ['0', '1', '2'] : undefined}
			>
				{steps.map((step) => (
					<>
						<ContentAssignmentStepAccordion {...step} key={step.titleKey} />
						<Separator />
					</>
				))}
				<ContentAssignmentActions
					handleSubmit={handleSubmit}
					onDataSubmit={() => {
						setConfirmationDialogOpen(true);
					}}
					isValid={isValid}
				/>
			</AccordionRoot>
		</>
	);
};
