import { Flex, IconButton } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Inbox } from '../../../../../../common/components/icons/Inbox';
import { Text } from '@jam/front-library';

export const EmptyCertificate: React.FC = () => {
	const { t } = useTranslation('dashboard');

	return (
		<Flex
			direction="row"
			align="center"
			justify="center"
			borderWidth="1px"
			borderRadius="lg"
			p={4}
			bg="white"
		>
			<Flex direction="column" align="center" gap={4}>
				<IconButton borderRadius="full" bg="#EDEDFF" color="white">
					<Inbox size={24} color={'#6C6BFA'} />
				</IconButton>
				<Text variant="body-bold">
					{t('user_details.certification.no_certificates.title')}
				</Text>
				<Flex
					direction="row"
					alignItems="center"
					justifyContent="center"
					color="#757575"
					textStyle="label"
				>
					<Text>
						{t('user_details.certification.no_certificates.description')}
					</Text>
				</Flex>
			</Flex>
		</Flex>
	);
};
