import { Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useGetSessionActivityQuery } from '../../../../../redux/api/dashboardApi';
import { UsersList } from './UsersList';
import { Section } from '@jam/front-library';

export const MostAndLeastActiveUsers = ({
	teamId,
}: {
	teamId: string | undefined;
}) => {
	const { t } = useTranslation(['dashboard', 'common']);
	const { data, error, isLoading, isFetching } =
		useGetSessionActivityQuery(teamId);

	if (isLoading || isFetching) return <div>Loading...</div>;

	if (error) return <div>Error fetching data</div>;

	if (!data) return <div>No data available</div>;

	return (
		<Section title={t('most_and_least_active_users').toString()}>
			<Flex direction={{ base: 'column', lg: 'row', md: 'row' }} gap="5">
				{data.mostActive && (
					<UsersList
						title={t('most_rounds')}
						headers={[t('common:last_week'), t('dashboard:total_rounds')]}
						usersActivity={data.mostActive}
					/>
				)}
				{data.leastActive && (
					<UsersList
						title={t('least_rounds')}
						headers={[t('common:last_week'), t('dashboard:total_rounds')]}
						usersActivity={data.leastActive}
					/>
				)}
			</Flex>
		</Section>
	);
};
