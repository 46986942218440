import { skipToken } from '@reduxjs/toolkit/query';
import { useTranslation } from 'react-i18next';
import { useGetAssignedContentAllocationQuery } from '../../../../../redux/api/contentAllocationApi';
import { VerticalAssignmentList } from './components/VerticalAssignmentList';
import { Section } from '@jam/front-library';
import { ReactElement } from 'react';

export interface CurrentAssignmentsProps {
	userId?: string;
	renderStoryCardButton?: (storyId: string) => ReactElement;
}

export const CurrentAssignments = ({
	userId,
	renderStoryCardButton,
}: CurrentAssignmentsProps) => {
	const { t } = useTranslation('common');
	const { data: contentAllocations } = useGetAssignedContentAllocationQuery(
		userId ?? skipToken
	);

	return (
		<Section title={t('your_next_todos').toString()}>
			<VerticalAssignmentList
				userId={userId}
				contentAllocations={contentAllocations}
				renderStoryCardButton={renderStoryCardButton}
			/>
		</Section>
	);
};
