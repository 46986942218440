import { SchemaSessionResponseDto } from '@jam/api-sdk/api';
import { useGetSessionsByUserIdQuery } from '../../../redux/api/sessionApi';

export const useGetUserLatestSessions = (
	userId: string | undefined,
	limit = 3
): SchemaSessionResponseDto[] | undefined | null => {
	const { data, isLoading } = useGetSessionsByUserIdQuery(
		{ userId: userId ?? '' },
		{ skip: !userId }
	);

	if (isLoading) return undefined;
	if (!data?.data.length) return null;

	return data.data.slice(0, limit);
};
