import { CompletionAcrossUsers } from './CompletionAcrossUsers';
import { WeeklyActivity } from './WeeklyActivity';
import { MostAndLeastActiveUsers } from '../user-activity/MostAndLeastActiveUsers';

export const TeamActivity = ({
	selectedTeamId,
}: {
	selectedTeamId: string | undefined;
}) => {
	return (
		<>
			<CompletionAcrossUsers teamId={selectedTeamId} />
			<WeeklyActivity teamId={selectedTeamId} />
			<MostAndLeastActiveUsers teamId={selectedTeamId} />
		</>
	);
};
