import {
	PageContent,
	TabsContent,
	TabsIndicator,
	TabsList,
	TabsRoot,
	TabsTrigger,
} from '@jam/front-library';
import { useTranslation } from 'react-i18next';
import { useActiveTab } from '../../../../common/hooks/useActiveTab';
import { UserDetailsOverview } from './overview/UserDetailsOverview';
import { UserPerformanceChart } from './performance/UserPerformanceChart';
import { UserPerformanceTable } from './performance/UserPerformanceTable';
import { CurrentAssignments } from './progress/CurrentAssignments';
import { Certifications } from './progress/Certifications';
import { ViewStoryButton } from '../../../../common/components/story-detail/buttons/ViewStoryButton';
import { StartStoryButton } from '../../../../common/components/story-detail/buttons/StartStoryButton';
import { History } from '../../../user-history/components/History';

interface UserDetailsProps {
	userId?: string;
	userName?: string;
	view: 'learner' | 'manager';
}
export const UserDetails = ({ userId, userName, view }: UserDetailsProps) => {
	const { t } = useTranslation(['dashboard', 'common']);
	const { activeTab, setActiveTab } = useActiveTab();

	return (
		<TabsRoot
			lazyMount
			value={activeTab === null ? 'overview' : `tab-${activeTab}`}
			onValueChange={({ value }) => {
				const index =
					value === 'overview' ? null : parseInt(value.split('-')[1]);
				setActiveTab(index, 'replaceIn');
			}}
			variant="line"
		>
			<TabsList>
				<TabsTrigger value="overview">
					{t('dashboard:user_details.overview')}
				</TabsTrigger>
				<TabsTrigger value="tab-1" className="todos-tab">
					{t('dashboard:user_details.todo')}
				</TabsTrigger>
				<TabsTrigger value="tab-2" className="history-tab">
					{t('dashboard:user_details.history.tab')}
				</TabsTrigger>
				<TabsTrigger value="tab-3" className="certification-tab">
					{t('dashboard:user_details.certification.tab')}
				</TabsTrigger>
				<TabsTrigger value="tab-4" className="performance-tab">
					{t('dashboard:user_details.performance')}
				</TabsTrigger>
				<TabsIndicator />
			</TabsList>
			<TabsContent value="overview">
				<PageContent>
					<UserDetailsOverview userId={userId} view={view} />
				</PageContent>
			</TabsContent>
			<TabsContent value="tab-1">
				<PageContent>
					<CurrentAssignments
						userId={userId}
						renderStoryCardButton={(storyId: string) => {
							if (view === 'manager') {
								return <ViewStoryButton storyId={storyId} />;
							} else {
								return (
									<StartStoryButton label="start_mission" storyId={storyId} />
								);
							}
						}}
					/>
				</PageContent>
			</TabsContent>
			<TabsContent value="tab-2">
				<PageContent>
					<History userId={userId} view={view} />
				</PageContent>
			</TabsContent>
			<TabsContent value="tab-3">
				<PageContent>
					<Certifications
						userName={userName}
						userId={userId}
						renderStoryCardButton={(storyId: string) => {
							if (view === 'manager') {
								return <ViewStoryButton storyId={storyId} />;
							} else {
								return (
									<StartStoryButton label="restart_mission" storyId={storyId} />
								);
							}
						}}
					/>
				</PageContent>
			</TabsContent>
			<TabsContent value="tab-4">
				<PageContent>
					{userId && (
						<UserPerformanceChart userId={userId} userName={userName} />
					)}
					<UserPerformanceTable />
				</PageContent>
			</TabsContent>
		</TabsRoot>
	);
};
