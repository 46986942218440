import { Section, Text } from '@jam/front-library';
import { useTranslation } from 'react-i18next';
import { useGetCertificationsQuery } from '../../../../../redux/api/contentAllocationApi';
import { skipToken } from '@reduxjs/toolkit/query';
import React, { ReactElement } from 'react';
import { TrackAssignment } from './components/TrackAssignment';
import { EmptyCertificate } from './components/EmptyCertificate';

export interface Props {
	userId?: string;
	userName?: string;
	renderStoryCardButton: (storyId: string) => ReactElement;
}
export const Certifications: React.FC<Props> = ({
	userId,
	userName,
	renderStoryCardButton,
}: Props) => {
	const { t } = useTranslation('dashboard');
	const { data: certifications, isLoading } = useGetCertificationsQuery(
		userId ?? skipToken
	);

	return (
		<Section title={t('user_details.certification.title').toString()}>
			<Text variant="summary">{t('user_details.certification.subtitle')}</Text>
			{isLoading && <div>Loading...</div>}
			{certifications?.length === 0 && <EmptyCertificate />}
			{certifications?.map((cert) => (
				<TrackAssignment
					key={cert.id}
					userName={userName}
					journeyId={cert.reference.id}
					contentAllocation={cert}
					renderStoryButton={renderStoryCardButton}
				/>
			))}
		</Section>
	);
};
