import { useNavigate } from 'react-router-dom';
import { StoryCardButton } from '../StoryCardButton';
import { submitMissionStarted } from '../../../../features/tracking/util/submitMissionEvents';
import { useTranslation } from 'react-i18next';

export interface StartStoryButtonProps {
	storyId: string;
	label: 'start_mission' | 'resume_mission' | 'restart_mission';
	origin?:
		| 'course_catalog_missions'
		| 'course_catalog_tracks'
		| 'next_story_start_button'
		| 'keep_learning';
}

export const StartStoryButton = ({
	storyId,
	label,
	origin,
}: StartStoryButtonProps) => {
	const { t } = useTranslation('story');

	const navigate = useNavigate();

	return (
		<StoryCardButton
			label={t(label)}
			onClick={() => {
				if (origin) {
					submitMissionStarted(storyId, origin);
				}
				navigate(`/call?storyId=${storyId}`);
			}}
		/>
	);
};
