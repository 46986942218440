import { Box, Flex, HStack } from '@chakra-ui/react';
import {
	Comment,
	PaginationItems,
	PaginationRoot,
	PaginationNextTrigger,
	PaginationPrevTrigger,
	Text,
} from '@jam/front-library';
import React from 'react';
import {
	useCreateCommentMutation,
	useDeleteCommentMutation,
	useEditCommentMutation,
	useFindCommentsQuery,
} from '../../../redux/api/commentApi';
import {
	CreateCommentRequestDTOReferenceType,
	SchemaCommentResponseDto,
} from '@jam/api-sdk/api';
import { useAuthInfo } from '@propelauth/react';
import { useTranslation } from 'react-i18next';

interface Props {
	sessionId: string;
}

const FIXED_LIMIT = 5;

export const SessionComments: React.FC<Props> = ({ sessionId }: Props) => {
	const { t } = useTranslation('common');
	const user = useAuthInfo();
	const [currentPage, setCurrentPage] = React.useState(1);
	const { data } = useFindCommentsQuery({
		referenceId: sessionId,
		page: currentPage,
		limit: FIXED_LIMIT,
	});
	const [createComment] = useCreateCommentMutation();
	const [editComment] = useEditCommentMutation();
	const [deleteComment] = useDeleteCommentMutation();

	const handlePageChange = (newPage: number) => {
		setCurrentPage(newPage);
	};

	const handleSubmit = async (content: string) => {
		try {
			await createComment({
				reference: {
					id: sessionId,
					type: CreateCommentRequestDTOReferenceType.SESSION,
				},
				content,
			}).unwrap();

			setCurrentPage(1);
		} catch (error) {
			throw new Error('Failed to create comment');
		}
	};

	const handleEdit = async (commentId: string, editedContent: string) => {
		try {
			await editComment({
				commentId,
				content: editedContent,
			}).unwrap();
		} catch (error) {
			throw new Error('Failed to edit comment');
		}
	};

	const handleDelete = async (commentId: string) => {
		try {
			await deleteComment({ commentId }).unwrap();
		} catch (error) {
			throw new Error('Failed to delete comment');
		}
	};

	return (
		<Flex direction="column" gap={6}>
			<Text variant="h3">{t('comment.add.title')}</Text>
			<Comment
				pictureUrl={user.user?.pictureUrl}
				input={{
					label: t('comment.add.submit') ?? '',
					pictureUrl: user.user?.pictureUrl,
					placeholder: t('comment.add.placeholder') ?? '',
					onSubmit: (value) => void handleSubmit(value),
				}}
			/>
			{(!data || data?.meta.total === 0) && (
				<Text variant="h3">{t('comment.list.no_comments')}</Text>
			)}

			{data && data.meta.total > 0 && (
				<>
					<Text variant="h3">
						{t('comment.list.title')} ({data.meta.total})
					</Text>

					{data.data.map((comment: SchemaCommentResponseDto) => (
						<Comment
							key={comment.id}
							comment={{
								commentId: comment.id,
								pictureUrl: comment.user.pictureUrl ?? undefined,
								firstName: comment.user.firstName ?? '',
								lastName: comment.user.lastName ?? '',
								createdAt: comment.createdAt,
								createdAtLabel: t('comment.created_at') ?? '',
								lastUpdatedAt: comment.lastUpdatedAt,
								lastUpdatedAtLabel: t('comment.last_edited_at') ?? '',
								content: comment.content,
								locale: user.user?.properties?.language as string,
								editable:
									user.user?.userId === comment?.user.id
										? {
												editButtonLabel: t('comment.edit.edit'),
												cancelButtonLabel: t('comment.edit.cancel'),
												submitButtonLabel: t('comment.edit.submit'),
												onEdit: (commentId: string, editedContent: string) =>
													void handleEdit(commentId, editedContent),
											}
										: undefined,
								deleteable:
									user.user?.userId === comment?.user.id
										? {
												deleteButtonLabel: t('comment.delete.delete'),
												onDelete: (commentId) => void handleDelete(commentId),
											}
										: undefined,
							}}
						/>
					))}

					<Box ml="auto">
						<PaginationRoot
							data-testid="pagination"
							variant="outline"
							onPageChange={({ page }) => handlePageChange(page)}
							count={data?.meta.total}
							pageSize={FIXED_LIMIT}
							page={currentPage}
						>
							<HStack wrap="wrap">
								<PaginationPrevTrigger />
								<PaginationItems />
								<PaginationNextTrigger />
							</HStack>
						</PaginationRoot>
					</Box>
				</>
			)}
		</Flex>
	);
};
