import { SchemaTaskPerformanceResponseDto } from '@jam/api-sdk/api';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { useGetTeamPerformanceByTaskQuery } from '../../../redux/api/dashboardApi';
import { Table, TableCells } from '@jam/front-library';

type TableData = SchemaTaskPerformanceResponseDto & {
	totalAttempts: number;
	successfulAttempts: number;
};

export const LoadingState = () => {
	return <div>Loading...</div>;
};

export const PerformanceByTask = () => {
	const { t } = useTranslation('performance');
	const { data, isLoading } = useGetTeamPerformanceByTaskQuery();
	const columnHelper = createColumnHelper<TableData>();

	if (isLoading) {
		return <LoadingState />;
	}

	if (!data || data.length === 0) {
		return <div>No data</div>;
	}

	const missions = [
		...new Set(data.map((performance) => performance.missionName)),
	];

	const columns = [
		columnHelper.accessor('taskName', {
			header: t('performance_by_task.task').toString(),
			cell: (info) => (
				<TableCells.TextCell>{info.getValue()}</TableCells.TextCell>
			),
			enableSorting: false,
		}),
		columnHelper.accessor('missionName', {
			header: t('performance_by_task.from_mission').toString(),
			cell: (info) => (
				<TableCells.TextCell>{info.getValue()}</TableCells.TextCell>
			),
			enableSorting: false,
			meta: {
				name: 'missionName',
				filtering: {
					variant: 'select',
					placeholder: t('performance_by_task.all_missions') ?? '',
					options: [
						{
							label: t('performance_by_task.all_missions'),
							value: '',
						},
						...missions.map((mission) => ({
							label: mission,
							value: mission,
						})),
					],
				},
			},
		}),
		columnHelper.accessor('totalAttempts', {
			header: t('performance_by_task.total_attempts').toString(),
			cell: (info) => (
				<TableCells.NumberCell>{info.getValue()}</TableCells.NumberCell>
			),
			meta: {
				alignHeader: 'end',
			},
		}),
		columnHelper.accessor('successfulAttempts', {
			header: t('performance_by_task.successful_attempts').toString(),
			cell: (info) => {
				return (
					<TableCells.NumberCell>
						{info.row.original.successfulAttempts} /{' '}
						{info.row.original.totalAttempts}
					</TableCells.NumberCell>
				);
			},
			meta: {
				alignHeader: 'end',
			},
		}),
	];

	const tableData = data.map((performance) => ({
		...performance,
		totalAttempts:
			performance.undetectedCount +
			performance.notSolvedCount +
			performance.partiallySolvedCount +
			performance.solvedCount,
		successfulAttempts:
			performance.solvedCount + performance.partiallySolvedCount,
	}));

	return (
		<Table<TableData> enablePagination data={tableData} columns={columns} />
	);
};
