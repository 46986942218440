import {
	PathsApiV1SessionsGetParametersQueryDateFilter,
	PathsApiV1SessionsGetParametersQueryState,
} from '@jam/api-sdk/api';
import { useNavigate } from 'react-router-dom';
import { UserSessionActivityTable } from '../../../../../common/components/UserSessionActivityTable';

export const RolePlayAcrossUsersTable = ({
	selectedDateFilter,
	teamId,
}: {
	teamId: string | undefined;
	selectedDateFilter:
		| PathsApiV1SessionsGetParametersQueryDateFilter
		| undefined;
}) => {
	const navigate = useNavigate();

	return (
		<UserSessionActivityTable
			initialQuery={{
				teamId,
				dateFilter:
					selectedDateFilter ||
					PathsApiV1SessionsGetParametersQueryDateFilter.all_time,
				state: PathsApiV1SessionsGetParametersQueryState.DONE,
			}}
			onRowClick={(row) => {
				navigate(
					`/dashboard/user/${row.userId}/session/${row.sessionId}?activeCallTabIndex=2`
				);
			}}
		/>
	);
};
