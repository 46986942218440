import { Box, Flex } from '@chakra-ui/react';
import { Text, Tooltip as ChakraTooltip } from '@jam/front-library';
import { Info } from '@phosphor-icons/react';
import React, { PureComponent, ReactNode } from 'react';
import {
	Bar,
	BarChart,
	BarProps,
	CartesianGrid,
	ResponsiveContainer,
	XAxis,
	YAxis,
} from 'recharts';
import { ActiveShape } from 'recharts/types/util/types';

interface LegendItem {
	color: string;
	label: string;
}

interface BarConfiguration {
	dataKey: string;
	name: string;
	fill: string;
	fillOpacity?: number;
	shape?: ActiveShape<BarProps, SVGPathElement> | undefined;
}

interface DashboardChartProps {
	title: string;
	hint: string;
	data: any[];
	xAxisKey: string;
	// yAxisLabelFormatter?: (value: any) => string;
	bars: BarConfiguration[];
	tooltipFormatter?: (payload: any) => ReactNode;
	legendItems?: LegendItem[];
}

class CustomizedAxisTick extends PureComponent<{
	x?: number;
	y?: number;
	payload?: { value: string };
}> {
	render() {
		const { x, y, payload } = this.props ?? {};

		return (
			<g transform={`translate(${x},${y})`}>
				<text
					x={0}
					y={0}
					dy={10}
					dx={30}
					textAnchor="end"
					fill="#666"
					style={{
						fontFamily: 'Inter',
						fontSize: '14px',
						fontStyle: 'normal',
						fontWeight: '400',
					}}
				>
					{payload?.value ?? ''}
				</text>
			</g>
		);
	}
}

const DashboardChart: React.FC<DashboardChartProps> = ({
	title,
	hint,
	data,
	xAxisKey,
	bars,
	legendItems,
}) => {
	return (
		<Box
			border="1px solid"
			borderColor="gray.300"
			borderRadius="24px"
			padding={'24px'}
		>
			<Flex direction="column" gap="3">
				<Flex justify="space-between" alignItems="center">
					<Flex direction="column" gap="3">
						<Flex gap="1" alignItems="center">
							<Text variant="label-body">{title}</Text>
							<ChakraTooltip content={hint}>
								<Info color="#D9D9D9" size={20} />
							</ChakraTooltip>
						</Flex>
					</Flex>
					{legendItems && (
						<Flex aria-label="legend" gap="4" alignItems="center">
							{legendItems.map((item, index) => (
								<Flex key={index} alignItems="center" gap="1">
									<Box w="12px" h="12px" bg={item.color} borderRadius="2px" />
									<Text variant="small-print">{item.label}</Text>
								</Flex>
							))}
						</Flex>
					)}
				</Flex>
				<ResponsiveContainer width="100%" height={250}>
					<BarChart data={data}>
						<CartesianGrid vertical={false} />
						<XAxis
							dataKey={xAxisKey}
							axisLine={false}
							tickLine={false}
							tick={<CustomizedAxisTick />}
						/>
						<YAxis
							allowDecimals={false}
							width={50}
							axisLine={false}
							tickLine={false}
						/>
						{bars.map((barConfig, index) => (
							<Bar
								key={index}
								barSize={30}
								stackId="a"
								dataKey={barConfig.dataKey}
								name={barConfig.name}
								fill={barConfig.fill}
								fillOpacity={barConfig.fillOpacity}
								shape={barConfig.shape}
							/>
						))}
					</BarChart>
				</ResponsiveContainer>
			</Flex>
		</Box>
	);
};

export default DashboardChart;
