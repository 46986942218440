import { Skeleton } from '@chakra-ui/react';
import { SchemaUserPerformanceResponseDto } from '@jam/api-sdk/api';
import { Table, TableCells } from '@jam/front-library';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetDetailedUserPerformanceQuery } from '../../../../../redux/api/dashboardApi';
import { useActiveMission } from '../../../hooks/useActiveMission';

const LoadingSkeleton = () => {
	return <Skeleton role="placeholder" height="20px" />;
};

export const UserPerformanceTable = () => {
	const { t } = useTranslation('dashboard');
	const { setActiveMission } = useActiveMission();
	const { userId } = useParams();

	const { data: performanceData, isLoading } =
		useGetDetailedUserPerformanceQuery(userId as string, {
			skip: !userId,
		});

	if (isLoading) {
		return <LoadingSkeleton />;
	}

	if (!performanceData) {
		return null;
	}

	const columnHelper = createColumnHelper<SchemaUserPerformanceResponseDto>();

	const onRowClick = (row: SchemaUserPerformanceResponseDto) => {
		setActiveMission(row.missionId);
		document.getElementById('performance-chart')?.scrollIntoView({
			behavior: 'smooth',
		});
	};

	const tracks = [
		...new Set(performanceData.map((performance) => performance.trackName)),
	];

	const columns = [
		columnHelper.accessor('missionName', {
			enableSorting: false,
			header: t('user_details.performance_table.mission') ?? '',
			cell: (info) => {
				return (
					<TableCells.UserCell
						pictureUrl={info.row.original.personaImage ?? ''}
						firstName={info.getValue()}
						email={info.row.original.personaName}
					/>
				);
			},
			meta: {
				name: 'missionName',
				filtering: {
					variant: 'text',
					placeholder:
						t('user_details.performance_table.search_mission_placeholder') ??
						'',
				},
			},
		}),
		columnHelper.accessor('trackName', {
			header: t('user_details.performance_table.track') ?? '',
			enableSorting: false,
			cell: (info) => (
				<TableCells.TextCell>{info.getValue()}</TableCells.TextCell>
			),
			meta: {
				name: 'trackName',
				filtering: {
					variant: 'select',
					placeholder: t('user_details.performance_table.all_tracks') ?? '',
					options: [
						{
							label: t('user_details.performance_table.all_tracks'),
							value: '',
						},
						...tracks.map((track) => ({
							label: track,
							value: track,
						})),
					],
				},
			},
		}),
		columnHelper.accessor('roundsPlayed', {
			header: t('user_details.performance_table.rounds_played') ?? '',
			enableSorting: false,
			cell: (info) => (
				<TableCells.NumberCell>{info.getValue()}</TableCells.NumberCell>
			),
			meta: {
				alignHeader: 'end',
			},
		}),
		columnHelper.accessor('completions', {
			header: t('user_details.performance_table.completions_rounds') ?? '',
			enableSorting: false,
			cell: (info) => (
				<TableCells.NumberCell>{`${info.getValue()} / ${info.row.original.roundsPlayed}`}</TableCells.NumberCell>
			),
			meta: {
				alignHeader: 'end',
			},
		}),
		columnHelper.accessor('averageScore', {
			header: t('user_details.performance_table.average_score') ?? '',
			enableSorting: false,
			cell: (info) => (
				<TableCells.NumberCell>{info.getValue()}</TableCells.NumberCell>
			),
			meta: {
				alignHeader: 'end',
			},
		}),
		columnHelper.accessor('numberOfRoundsToComplete', {
			header: t('user_details.performance_table.rounds_to_complete') ?? '',
			enableSorting: false,
			cell: (info) => (
				<TableCells.NumberCell>{info.getValue()}</TableCells.NumberCell>
			),
			meta: {
				alignHeader: 'end',
			},
		}),
	];

	return (
		<Table<SchemaUserPerformanceResponseDto>
			enablePagination
			data={performanceData}
			columns={columns}
			onRowClick={onRowClick}
		/>
	);
};
