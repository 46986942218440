import { Box, Flex, Separator, Stack } from '@chakra-ui/react';
import { SchemaCompletedUserActivityReposeDto } from '@jam/api-sdk/api';
import { Avatar, Button, Text, Tooltip } from '@jam/front-library';
import { Info } from '@phosphor-icons/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

type Props = {
	title: string;
	tooltip?: string;
	headers: string[];

	usersActivity:
		| SchemaCompletedUserActivityReposeDto['mostActive']
		| SchemaCompletedUserActivityReposeDto['leastActive'];
};

const LIMIT = 3;

export const UsersList = ({
	title,
	tooltip,
	headers,
	usersActivity,
}: Props) => {
	const { t } = useTranslation(['dashboard', 'common']);
	const [seeAllExpanded, setSeeAllExpanded] = useState<boolean>(false);
	const navigate = useNavigate();

	const onToggleSeeAll = () => {
		setSeeAllExpanded((prev) => !prev);
	};

	const showExpandButton = usersActivity.length > LIMIT;
	return (
		<Box
			border={'1px solid'}
			borderColor="gray.300"
			borderRadius={'16px'}
			w="100%"
			p="6"
		>
			<Stack gap={'3'} separator={<Separator />}>
				<Flex alignItems="center" w="100%" pr="3">
					<Box flexShrink={1} flex="0.6" flexDirection="row">
						<Text variant="label-bold">{title}</Text>
						{tooltip && (
							<Tooltip content={tooltip}>
								<Info size={20} />
							</Tooltip>
						)}
					</Box>
					{headers.map((header) => {
						return (
							<Box flex="0.2" key={header} textAlign={'center'}>
								<Text key={header} variant="small-print">
									{header}
								</Text>
							</Box>
						);
					})}
				</Flex>
				{usersActivity
					.slice(0, seeAllExpanded ? usersActivity.length : LIMIT)
					.map((userActivity) => {
						return (
							<Flex
								onClick={() => {
									navigate(`/dashboard/user/${userActivity.userId}`);
								}}
								cursor={'pointer'}
								alignItems="center"
								w="100%"
								key={userActivity.userId}
							>
								<Flex alignItems={'center'} flexShrink={1} flex="0.6" gap="3">
									<Avatar
										size="sm"
										name={userActivity.userName ?? 'N/A'}
										src={userActivity.userPictureUrl}
										bg="gray"
									/>

									<Stack direction="column" alignItems="start" gap={'4px'}>
										<Text>{userActivity.userName ?? 'Unknown User'}</Text>
									</Stack>
								</Flex>
								<Box flexShrink={0} flex="0.2">
									<Text textAlign={'center'} variant="label">
										{userActivity.sessionLastWeek}
									</Text>
								</Box>
								<Flex justifyContent={'center'} flexShrink={0} flex="0.2">
									<Box
										w="fit-content"
										bg="#F5F5F5"
										px="2"
										py="1"
										borderRadius={'20px'}
									>
										<Text variant="label">{userActivity.sessionsTotal}</Text>
									</Box>
								</Flex>
							</Flex>
						);
					})}
				{showExpandButton && (
					<Button onClick={onToggleSeeAll} size="full" variant="tertiary">
						{seeAllExpanded ? t('dashboard:hide_all') : t('dashboard:see_all')}
					</Button>
				)}
			</Stack>
		</Box>
	);
};
