import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { loadSelectedOrg } from '../../common/components/admin/SelectOrg';
import { RootState } from '../store';

export function buildBaseQuery(path: string) {
	return fetchBaseQuery({
		baseUrl: `${
			import.meta.env.VITE_SALES_ENABLEMENT_PLATFORM_NEST_API_BASE_URL as string
		}${path}`,
		prepareHeaders: (headers, { getState }) => {
			const token = (getState() as RootState).applicationState.accessToken;
			const selectedOrg = loadSelectedOrg();

			if (token) {
				headers.set('Authorization', `Bearer ${token}`);
			}

			if (selectedOrg) {
				headers.set('x-tenant-org-id', selectedOrg.id);
			}

			if (selectedOrg?.sagaIds) {
				headers.set('x-tenant-saga-ids', selectedOrg.sagaIds.join(','));
			}

			if (selectedOrg?.writeSagaIds) {
				headers.set(
					'x-tenant-write-saga-ids',
					selectedOrg.writeSagaIds.join(',')
				);
			}

			return headers;
		},
	});
}
