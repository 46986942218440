import { TableCells } from '@jam/front-library';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface Props {
	missionId: string;
}

export const MissionDetailDots: React.FC<Props> = ({ missionId }: Props) => {
	const { t } = useTranslation('performance');
	const navigate = useNavigate();

	const handleSeeMission = () => {
		navigate(`/mission/${missionId}`);
	};

	const handleTestMission = () => {
		navigate(`/call?storyId=${missionId}`);
	};

	return (
		<TableCells.RowActionsCell
			actions={[
				{
					value: 'see_mission',
					label: t('performance_by_mission.see_mission'),
					onClick: handleSeeMission,
				},
				{
					value: 'test_mission',
					label: t('performance_by_mission.test_mission'),
					onClick: handleTestMission,
				},
			]}
		/>
	);
};
