import {
	Section,
	TabsContent,
	TabsList,
	TabsRoot,
	TabsTrigger,
} from '@jam/front-library';
import { HistorySessionTable } from './HistorySessionTable';
import { useTranslation } from 'react-i18next';
import { HistoryContentAllocationTable } from './HistoryContentAllocationTable';

interface Props {
	userId?: string;
	view?: 'learner' | 'manager';
}

export const History: React.FC<Props> = ({ userId, view }: Props) => {
	const { t } = useTranslation('history');

	return (
		<Section fullWidth>
			<TabsRoot defaultValue="all_rounds_played">
				<TabsList
					bg={'#EDF2F7'}
					h={'fit-content'}
					borderRadius={'9px'}
					p={'3px'}
					minH={'32px'}
					minW="240px"
					maxW="396px"
				>
					<TabsTrigger
						value="all_rounds_played"
						borderRadius={'7px'}
						_selected={{
							bg: 'white',
							boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
						}}
						flex="1"
						py={0}
						px={1}
						minH={'28px'}
						css={{
							display: 'flex',
							justifyContent: 'center',
						}}
						textStyle="label-body"
					>
						{t('all_rounds_played')}
					</TabsTrigger>
					<TabsTrigger
						value="completed_to_dos"
						borderRadius={'7px'}
						_selected={{
							bg: 'white',
							boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
						}}
						flex="1"
						py={0}
						minH={'28px'}
						px={1}
						css={{
							display: 'flex',
							justifyContent: 'center',
						}}
						textStyle="label-body"
					>
						{t('completed_to_dos')}
					</TabsTrigger>
				</TabsList>
				<TabsContent value="all_rounds_played" pt="6" pb={0} px={0}>
					<HistorySessionTable userId={userId} view={view} />
				</TabsContent>
				<TabsContent value="completed_to_dos" pt="6" pb={0} px={0}>
					<HistoryContentAllocationTable userId={userId} />
				</TabsContent>
			</TabsRoot>
		</Section>
	);
};
