import { Flex, Image } from '@chakra-ui/react';
import { SchemaStoryResponseDto } from '@jam/api-sdk/api';
import { CallToActionCard, Chip, Text } from '@jam/front-library';
import { Article, Clock } from '@phosphor-icons/react';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useDifficultyIcon } from '../../../features/home/hooks/useDifficultyIcon';
import { useGetJourneysQuery } from '../../../redux/api/cmsApi';
import { useStoryStatus } from '../../hooks/useStoryStatus';
import {
	getFutureRelativeDueDate,
	getFutureRelativeDueDateAsText,
} from '../../utils/dateUtil';
import { StoryCardImageHeader } from './StoryCardImageHeader';

export type StoryCardProps = {
	story: SchemaStoryResponseDto;
	dueDate?: Date;
	userId?: string;
	hideStatus?: boolean;
	renderStoryButton?: (storyId: string, sagaId: string) => ReactElement;
};

export const StoryCard = ({
	story,
	dueDate,
	userId,
	hideStatus,
	renderStoryButton,
}: StoryCardProps) => {
	const { t } = useTranslation(['story', 'history', 'common']);
	const { data: journeys } = useGetJourneysQuery();
	const difficultyIcon = useDifficultyIcon(story.difficulty);
	const status = useStoryStatus(story.id, userId);

	const image =
		story.persona.avatar_file.sizes.thumbnail?.url ??
		story.persona.avatar_file.url;
	const journey = journeys?.find((j) =>
		j.stories.some((s) => s.id === story.id)
	);

	const relativeDueDate = dueDate ? getFutureRelativeDueDate(dueDate) : null;

	const dueDateText = relativeDueDate
		? getFutureRelativeDueDateAsText(relativeDueDate, t)
		: null;

	const journeyTitle = journey?.title ?? '';

	return (
		<Flex data-testid="story-card" w="col-3">
			<CallToActionCard
				height="100%"
				width="100%"
				image={image}
				title={story.title}
				description={journeyTitle}
				descriptionIcon={Article}
				descriptionSubContent={<Text variant="small-print">{dueDateText}</Text>}
				storyActionButton={
					renderStoryButton
						? renderStoryButton(story.id, story.saga.id)
						: undefined
				}
				imageHeader={
					<StoryCardImageHeader status={status} hideStatus={hideStatus} />
				}
				imageFooter={
					<Flex width="100%" justifyContent="space-between">
						{story.scenario.duration && (
							<Chip
								px="1"
								py="2px"
								h="24px"
								bg={'#F5F5F5'}
								border={0}
								borderRadius={'4px'}
								gap={'4px'}
								icon={<Clock size={14} color="#757575" />}
								text={t('duration_value', {
									duration: story.scenario.duration,
								})}
								aria-label={`Story duration: ${story.scenario.duration}`}
								textStyle="small-print"
							/>
						)}

						{story.difficulty && (
							<Chip
								px="1"
								py="2px"
								h="24px"
								bg={'#F5F5F5'}
								borderRadius={'4px'}
								gap={'4px'}
								border={0}
								icon={difficultyIcon ? <Image src={difficultyIcon} /> : null}
								text={t(story.difficulty)}
								aria-label={`Story difficulty: ${t(story.difficulty)}`}
								textStyle="small-print"
							/>
						)}
					</Flex>
				}
			/>
		</Flex>
	);
};
