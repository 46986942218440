import React from 'react';
import { Button, Field, Table, TableCells } from '@jam/front-library';
import { Flex } from '@chakra-ui/react';
import { SchemaJourneyResponseDto } from '@jam/api-sdk/api';
import { createColumnHelper, Updater } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { Control, Controller, FieldErrors, useWatch } from 'react-hook-form';
import { CreateSprintScheduleRequestDTOSchemaFrontendDTO } from '../content-assignment/ContentAssignment';
import { CaretRight } from '@phosphor-icons/react';

interface Props {
	journeys: SchemaJourneyResponseDto[];
	control: Control<CreateSprintScheduleRequestDTOSchemaFrontendDTO>;
	errors: FieldErrors<CreateSprintScheduleRequestDTOSchemaFrontendDTO>;
	onViewDetails: (journey: SchemaJourneyResponseDto) => void;
}

export const ContentAssignmentJourneysTable: React.FC<Props> = ({
	journeys,
	control,
	errors,
	onViewDetails,
}) => {
	const { t } = useTranslation(['coaching']);

	// Watch contentReference directly from the form
	const contentReference = useWatch({
		control,
		name: 'contentReference',
		defaultValue: [],
	});

	// Convert contentReference IDs to row selection format for the Table component
	const getSelectedRows = (): Record<string, boolean> => {
		if (!contentReference || !contentReference.length) {
			return {};
		}

		return journeys.reduce(
			(acc, journey, index) => ({
				...acc,
				[index]: contentReference.includes(journey.id),
			}),
			{}
		);
	};

	// Handler for row selection changes
	// Handler for row selection changes with proper type signature
	const handleRowSelectionChange = (
		selectedRows: Record<string, boolean> | Updater<Record<string, boolean>>,
		field: {
			onChange: (value: string[]) => void;
		}
	) => {
		// Convert to an actual Record<string, boolean> if it's an updater function
		const finalSelectedRows =
			typeof selectedRows === 'function'
				? selectedRows(getSelectedRows())
				: selectedRows;

		// Extract the selected journey IDs from the rows
		const selectedJourneyIds = Object.keys(finalSelectedRows)
			.filter((index) => finalSelectedRows[index])
			.map((index) => journeys[parseInt(index, 10)].id);

		field.onChange(selectedJourneyIds);
	};

	const columnHelper = createColumnHelper<SchemaJourneyResponseDto>();

	const columns = [
		columnHelper.accessor('title', {
			header: t('coaching:tracks.table.track_name').toString(),
			enableSorting: false,
			cell: ({ row }) => {
				return <TableCells.TextCell>{row.original.title}</TableCells.TextCell>;
			},
			size: 65,
		}),
		columnHelper.display({
			id: 'missions',
			header: t('coaching:tracks.table.missions_in_track').toString(),
			cell: ({ row }) => (
				<TableCells.NumberCell>
					{row.original.stories.length}
				</TableCells.NumberCell>
			),
			enableSorting: false,
		}),
		columnHelper.display({
			id: 'actions',
			header: '',
			cell: ({ row }) => {
				return (
					<TableCells.BaseCell align="start">
						<Button
							size="sm"
							icon={
								<CaretRight
									style={{
										height: '16px',
										width: '16px',
									}}
								/>
							}
							variant="link"
							iconPosition="end"
							onClick={(e: React.MouseEvent) => {
								e.stopPropagation();
								onViewDetails(row.original);
							}}
							textStyle="label"
						>
							{t('coaching:tracks.table.view_details')}
						</Button>
					</TableCells.BaseCell>
				);
			},
			enableSorting: false,
		}),
	];

	if (!journeys?.length) return null;

	return (
		<Flex w="100%">
			<Controller
				name="contentReference"
				control={control}
				render={({ field }) => (
					<Field
						invalid={!!errors?.contentReference}
						errorText={t('tracks.error')}
						errorPosition="top"
					>
						<Table<SchemaJourneyResponseDto>
							width="100%"
							data={journeys}
							columns={columns}
							enablePagination
							enableSelection
							singleSelection
							onRowSelectionChange={(change) =>
								handleRowSelectionChange(change, field)
							}
							selectedRows={getSelectedRows()}
						/>
					</Field>
				)}
			/>
		</Flex>
	);
};
