import { Flex, Spinner, Stack } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
	Avatar,
	Button,
	Field,
	Input,
	Section,
	Text,
	toaster,
} from '@jam/front-library';
import { useAuthInfo } from '@propelauth/react';
import { ChangeEvent, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z, ZodType } from 'zod';
import {
	useChangeAccountEmailMutation,
	useChangeAccountInfoMutation,
} from '../../../redux/api/crmApi';

const authUrl = (import.meta.env.VITE_AUTH_URL as string) ?? '';

type AccountInfo = {
	firstName?: string;
	lastName?: string;
	email: string;
	pictureUrl?: string;
};

const AccountInfoSchema: ZodType<AccountInfo> = z.object({
	firstName: z.string().min(2, 'First name must be at least 2 characters long'),
	lastName: z.string().min(2, 'Last name must be at least 2 characters long'),
	email: z.string().email('Invalid email format').min(1),
});

export const AccountDataForm = (props: AccountInfo) => {
	const { t } = useTranslation('settings');
	const { refreshAuthInfo } = useAuthInfo();
	const [changeAccountInfo] = useChangeAccountInfoMutation();
	const [changeAccountEmail] = useChangeAccountEmailMutation();

	const hiddenInputRef = useRef<HTMLInputElement | null>(null);

	const {
		register,
		handleSubmit,
		reset,
		formState: { isDirty, errors, isSubmitting },
	} = useForm<AccountInfo>({
		resolver: zodResolver(AccountInfoSchema),
		defaultValues: props,
	});

	const [loading, setLoading] = useState<boolean>(false);

	const uploadFileToServer = async (file: File) => {
		const formData = new FormData();
		formData.append('file', file);
		const response = await new Promise<{
			success: boolean;
		}>((resolve) => {
			const request = new XMLHttpRequest();
			request.onreadystatechange = function () {
				if (request.readyState === XMLHttpRequest.DONE) {
					if (request.status >= 200 && request.status < 300) {
						resolve({
							success: true,
						});
					} else if (400 === request.status) {
						resolve({
							success: false,
						});
					} else {
						resolve({
							success: false,
						});
					}
				}
			};
			request.open('post', `${authUrl}/api/fe/v1/update_profile_image`);
			request.withCredentials = true;
			request.setRequestHeader('X-CSRF-Token', '-.-');
			request.send(formData);
		});

		return response;
	};

	const handleUploadedFile = async (event: ChangeEvent<HTMLInputElement>) => {
		if (!event.target.files) return;
		const file = event.target.files[0];
		setLoading(true);
		const { success } = await uploadFileToServer(file);

		if (success) {
			await refreshAuthInfo();
		}
		setLoading(false);
		toaster.create({
			title: success ? t('picture-uploaded') : t('error'),
			type: success ? 'success' : 'error',
			duration: 3000,
			meta: {
				closable: true,
			},
		});
		if (hiddenInputRef.current) {
			hiddenInputRef.current.value = '';
		}
	};

	const onUpload = () => {
		hiddenInputRef.current?.click();
	};

	const onSubmit = async (data: AccountInfo) => {
		await changeAccountInfo({
			firstName: data.firstName ?? '',
			lastName: data.lastName ?? '',
		});
		await changeAccountEmail({
			newEmail: data.email,
		});

		toaster.create({
			title: t('account_info_updated'),
			type: 'success',
			duration: 3000,
			meta: {
				closable: true,
			},
		});
	};

	return (
		<Section title={t('account-title').toString()}>
			<Flex alignItems={'center'} gap="5">
				{loading ? (
					<Flex
						justifyContent={'center'}
						alignItems={'center'}
						height={'96px'}
						width={'96px'}
						bgColor="grey"
					>
						<Spinner color="white" size="lg" />
					</Flex>
				) : (
					<Avatar
						icon={<Spinner size="xl" />}
						size={'xl'}
						src={props.pictureUrl ?? ''}
						name={props.firstName ?? ''}
						bg="grey"
					/>
				)}
				<Input
					role="file-upload"
					type="file"
					ref={hiddenInputRef}
					onChange={(e) => void handleUploadedFile(e)}
					hidden
				/>
				<Text
					onClick={onUpload}
					cursor={'pointer'}
					textDecoration={'underline'}
					color="#757575"
				>
					Edit
				</Text>
			</Flex>
			<Stack gap={6} maxW={'500px'}>
				<Field
					invalid={!!errors.firstName}
					label={t('first-name')}
					errorText={errors.firstName?.message}
				>
					<Input {...register('firstName')} />
				</Field>
				<Field
					invalid={!!errors.lastName}
					label={t('last-name')}
					errorText={errors.lastName?.message}
				>
					<Input {...register('lastName')} />
				</Field>
				<Field
					invalid={!!errors.email}
					label={t('email-address')}
					errorText={errors.email?.message}
				>
					<Input {...register('email')} />
				</Field>
				<Flex gap="5">
					<Button
						id="save-user-info-button"
						type="submit"
						loading={isSubmitting}
						disabled={!isDirty}
						size="sm"
						onClick={() => {
							if (!isDirty) return;
							void handleSubmit(onSubmit)();
						}}
					>
						{t('save')}
					</Button>
					<Button
						variant="tertiary"
						type="reset"
						size="sm"
						onClick={() => {
							reset();
						}}
					>
						{t('cancel')}
					</Button>
				</Flex>
			</Stack>
		</Section>
	);
};
