import { StepType } from '@reactour/tour';
import StepContent from './StepContent';

const getCommonStepConfig = (popoverStyles = {}) => ({
	position: 'right' as const,
	styles: {
		popover: (props: {
			backgroundColor?: string;
			borderRadius?: string;
			color?: string;
		}) => ({
			...props,
			backgroundColor: '#4241E4',
			borderRadius: '16px',
			color: 'white',
			maxWidth: '370px',
			padding: '12px 14px',
			...popoverStyles,
		}),
		badge: () => ({
			display: 'none',
		}),
	},
});

export const OnboardingSteps: StepType[] = [
	{
		selector: '.next-story-start-button',
		content: <StepContent translationKey="onboarding.start_mission" />,
		...getCommonStepConfig(),
	},
];

export const CallPageSteps: StepType[] = [
	{
		selector: '.scenario-tab',
		content: <StepContent translationKey="onboarding.scenario" />,
		...getCommonStepConfig(),
	},
	{
		selector: '.roleplay-button',
		content: <StepContent translationKey="onboarding.roleplay" />,
		...getCommonStepConfig(),
	},
];

export const CallFeedbackSteps: StepType[] = [
	{
		selector: '.feedback-tab',
		content: <StepContent translationKey="onboarding.feedback" />,
		...getCommonStepConfig(),
	},
	{
		selector: '.thumbs-container',
		content: <StepContent translationKey="onboarding.analysis_thumbs" />,
		...getCommonStepConfig(),
	},
];

export const ProgressPageSteps: StepType[] = [
	{
		selector: '.todos-tab',
		content: <StepContent translationKey="onboarding.todos" />,
		...getCommonStepConfig(),
	},
	{
		selector: '.achievements-tab',
		content: <StepContent translationKey="onboarding.achievements" />,
		...getCommonStepConfig(),
	},
	{
		selector: '.history-tab',
		content: <StepContent translationKey="onboarding.history" />,
		...getCommonStepConfig(),
	},
	{
		selector: '.performance-tab',
		content: <StepContent translationKey="onboarding.performance" />,
		...getCommonStepConfig(),
	},
];
