import { SchemaSessionResponseDto } from '@jam/api-sdk/api';
import { useGetStoryLastSessionQuery } from '../../../redux/api/sessionApi';

export const useGetLearnerLastSessionPerStory = (
	storyId: string | undefined,
	userId: string | null
): SchemaSessionResponseDto | undefined | null => {
	const { data: lastSession } = useGetStoryLastSessionQuery(
		{ id: storyId ?? '', userId: userId ?? '' },
		{ skip: !storyId || !userId }
	);

	return lastSession;
};
