import { SchemaMemberPerformanceResponseDto } from '@jam/api-sdk/api';
import { Table, TableCells } from '@jam/front-library';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGetTeamPerformanceByMemberQuery } from '../../../redux/api/dashboardApi';

type TableData = SchemaMemberPerformanceResponseDto & {
	completionPercentage: number | null;
};

export const LoadingState = () => {
	return <div>Loading...</div>;
};

export const PerformanceByMember = () => {
	const { t } = useTranslation('performance');
	const navigate = useNavigate();
	const { data, isLoading } = useGetTeamPerformanceByMemberQuery();
	const columnHelper = createColumnHelper<TableData>();

	const columns = [
		columnHelper.accessor('userName', {
			header: t('performance_by_member.name').toString(),
			cell: (info) => {
				return (
					<TableCells.UserCell
						firstName={info.getValue()}
						pictureUrl={info.row.original.userPictureUrl ?? ''}
					/>
				);
			},
			enableSorting: false,
			meta: {
				name: 'userName',
				filtering: {
					variant: 'text',
					placeholder: t('search_user_placeholder').toString(),
				},
			},
		}),
		columnHelper.accessor('roundsPlayed', {
			header: t('performance_by_member.rounds_played').toString(),
			cell: (info) => (
				<TableCells.NumberCell>{info.getValue()}</TableCells.NumberCell>
			),
			meta: {
				alignHeader: 'end',
			},
		}),
		columnHelper.accessor('completionPercentage', {
			header: t('performance_by_member.missions_completed').toString(),
			cell: (info) => (
				<TableCells.NumberCell>
					{info.row.original.missionsCompleted} /{' '}
					{info.row.original.roundsPlayed}
				</TableCells.NumberCell>
			),
			meta: {
				alignHeader: 'end',
			},
		}),
		columnHelper.accessor('averageScore', {
			header: t('performance_by_member.average_score').toString(),
			cell: (info) => (
				<TableCells.NumberCell>{info.getValue() ?? '-'}</TableCells.NumberCell>
			),
			meta: {
				alignHeader: 'end',
			},
		}),
		columnHelper.accessor('lastSessionDate', {
			header: t('performance_by_member.last_time_played').toString(),
			cell: (info) => {
				if (!info.row.original.lastSessionDate) return '-';
				return <TableCells.DateCell date={info.row.original.lastSessionDate} />;
			},
		}),
	];

	if (isLoading) {
		return <LoadingState />;
	}

	if (!data || data.length === 0) {
		return <div>No data</div>;
	}

	const tableData = data.map((performance) => ({
		...performance,
		completionPercentage:
			(performance.missionsCompleted / performance.roundsPlayed) * 100,
	}));

	return (
		<Table<TableData>
			defaultSorting={[
				{
					id: 'averageScore',
					desc: false,
				},
			]}
			enablePagination
			data={tableData}
			columns={columns}
			onRowClick={(row) => {
				navigate(`/dashboard/user/${row.userId}`);
			}}
		/>
	);
};
