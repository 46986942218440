import posthog from 'posthog-js';
import { TrackingEvent } from '../types/TrackingEvent';

export function submitPostHog(event: TrackingEvent) {
	if (!posthog) {
		return;
	}

	if (!posthog.__loaded) {
		return;
	}

	if (!event) {
		return;
	}
	posthog.capture(event.key, {
		...event.meta,
		location: window.location.href,
	});
}
