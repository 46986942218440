import { Flex, IconButton, Link } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import { Text } from '@jam/front-library';
import { Inbox } from './icons/Inbox';

export const CallBarPlaceholder = () => {
	const { t } = useTranslation();
	return (
		<Flex
			direction="row"
			align="center"
			justify="center"
			borderWidth="1px"
			borderRadius="lg"
			p={4}
			bg="white"
		>
			<Flex direction="column" align="center" gap={4}>
				<IconButton borderRadius="full" bg="#EDEDFF" color="white">
					<Inbox size={24} color={'#6C6BFA'} />
				</IconButton>
				<Text variant="body-bold">{t('story:no_assignments.title')}</Text>
				<Flex
					direction="row"
					alignItems="center"
					justifyContent="center"
					color="#757575"
					textStyle="label"
				>
					<Trans i18nKey="story:no_assignments.description">
						Visit your
						<Link
							href="/course-catalog"
							px={1}
							color="#757575"
							textDecoration="underline"
						>
							course library
						</Link>
						to pick a new track.
					</Trans>
				</Flex>
			</Flex>
		</Flex>
	);
};
