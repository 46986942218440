import { BasePage, PageContent } from '@jam/front-library';
import { useTranslation } from 'react-i18next';
import { ContentAssignment } from '../../features/coaching/components/content-assignment/ContentAssignment';
import { useGetOrgTeamsQuery } from '../../redux/api/crmApi';
import { useGetJourneysQuery } from '../../redux/api/cmsApi';

export const CoachingPage = () => {
	const { t } = useTranslation('coaching');
	const { data: teams, isLoading: teamsLoading } = useGetOrgTeamsQuery();
	const { data: journeys, isLoading: journeysLoading } = useGetJourneysQuery();

	return (
		<BasePage
			title={t('title') ?? ''}
			subtitle={t('assign_tracks').toString()}
			trackingKey="manager_dashboard"
		>
			<PageContent>
				<ContentAssignment
					teams={teams}
					journeys={journeys}
					loading={teamsLoading || journeysLoading}
				/>
			</PageContent>
		</BasePage>
	);
};
