import { Flex, Skeleton } from '@chakra-ui/react';
import { useGetStoriesQuery } from '../../../../../redux/api/cmsApi';
import { useTranslation } from 'react-i18next';
import { useGetUserPerformanceByRoundQuery } from '../../../../../redux/api/dashboardApi';
import { useActiveMission } from '../../../hooks/useActiveMission';
import { PerformanceChart } from '../../../../../common/components/PerformanceChart';
import { Section } from '@jam/front-library';
import { useAuthInfo } from '@propelauth/react';

const LoadingSkeleton = () => {
	return <Skeleton role="placeholder" height="20px" />;
};
interface UserPerformanceChartProps {
	userId: string;
	userName: string | undefined;
}

export const UserPerformanceChart = ({
	userId,
	userName,
}: UserPerformanceChartProps) => {
	const { activeMission, setActiveMission } = useActiveMission();
	const allSelectedUsers = [userId];
	const { t } = useTranslation('dashboard');

	const { user } = useAuthInfo();

	const { data: missions } = useGetStoriesQuery();

	const { data: apiPerformanceData } = useGetUserPerformanceByRoundQuery(
		{
			users: allSelectedUsers.filter((uid) => uid !== null),
			missionId: activeMission ?? '',
		},
		{
			skip: !userId,
		}
	);

	if (!apiPerformanceData || !missions) return <LoadingSkeleton />;

	// either active mission or first mission
	const selectedMission =
		missions.find((mission) => mission.id === activeMission) ?? missions[0];

	const allEmpty = apiPerformanceData.every((x) => x[userId]?.score === null);

	// Transform API performance data into chart-ready format
	// - Replace null scores with -1 to indicate no data
	// - Add entries for other selected users with -1 scores if they haven't played that round
	const data = apiPerformanceData.slice().map((x) => ({
		round: x[userId]?.round ?? 0,
		[userId]: {
			...x[userId],
			score: x[userId]?.score ?? -1,
		},
		...Object.fromEntries(
			allSelectedUsers
				.filter((uid) => uid !== userId && uid !== null) // Exclude main user
				.map((uid) => [
					uid,
					{
						...x[uid],
						score: x[uid]?.score ?? -1,
					},
				]) // Use existing score if available, otherwise -1
		),
	}));

	if (!user) return null;

	return (
		<Flex id="performance-chart">
			<Section
				title={t('user_details.compare_performance', { userName }).toString()}
			>
				<PerformanceChart
					data={data}
					alwaysShownUserId={userId}
					shownUsers={allSelectedUsers}
					teamMembers={[
						{
							id: userId,
							firstName: userName,
							lastName: '',
						},
					]}
					selectedMission={selectedMission}
					missions={missions ?? []}
					onMissionChange={setActiveMission}
					allEmpty={allEmpty}
					getSessionUrl={(_: string, sessionId: string) =>
						`/user/self/session/${sessionId}?activeCallTabIndex=2`
					}
				/>
			</Section>
		</Flex>
	);
};
