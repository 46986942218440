import { format } from 'date-fns';
import { de } from 'date-fns/locale/de';
import { enGB } from 'date-fns/locale/en-GB';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { UserSwitcher } from '../../features/dashboard/components/user-details/common/UserSwitcher';
import { UserDetails } from '../../features/dashboard/components/user-details/UserDetails';
import { useGetDetailedUserActivityOverviewQuery } from '../../redux/api/dashboardApi';
import { BasePage } from '@jam/front-library';

const usePageData = () => {
	const { i18n } = useTranslation();
	const locale = i18n.language === 'en' ? enGB : de;
	const { userId } = useParams<{ userId: string }>();
	const { data, isFetching } = useGetDetailedUserActivityOverviewQuery(
		userId as string,
		{
			skip: !userId,
		}
	);

	return { locale, userId, data, isFetching };
};

export const ManagerDashboardUserDetails = () => {
	const { t } = useTranslation(['dashboard', 'common']);
	const { userId, data, isFetching, locale } = usePageData();

	if (isFetching || !data) {
		return null;
	}

	const subtitle1 = data.registeredSince
		? t('common:joined_date', {
				date: format(new Date(data.registeredSince), 'd. MMM yyyy', {
					locale,
				}),
			})
		: '';

	const subtitle2 = data.lastSessionDate
		? t('common:last_session_date', {
				date: format(new Date(data.lastSessionDate ?? ''), 'd. MMM yyyy', {
					locale,
				}),
			})
		: '';

	const subtitle =
		subtitle1 && subtitle2
			? subtitle1 + ' • ' + subtitle2
			: subtitle1 ?? subtitle2;
	return (
		<BasePage
			title={data.name}
			subtitle={subtitle}
			titleActions={<UserSwitcher />}
			trackingKey="manager_user_details"
		>
			<UserDetails userId={userId} userName={data.name} view="manager" />
		</BasePage>
	);
};
