import { Stack, useDisclosure } from '@chakra-ui/react';
import { PencilSimple } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetStoryDetails } from '../../common/hooks/useGetStoryDetails';
import { MissionDetail } from '../../features/manager-journeys/components/MissionDetail';
import { RequestEditsModal } from '../../features/manager-journeys/components/RequestEditsModal';
import { BasePage, Button } from '@jam/front-library';

export const ManagerMissionDetailPage = () => {
	const { t } = useTranslation('common');
	const { missionId } = useParams();

	const mission = useGetStoryDetails(missionId);
	const { open: isOpen, onOpen, onClose } = useDisclosure();

	if (!mission) {
		return null;
	}

	return (
		<BasePage
			title={mission.title}
			titleActions={[
				<Stack maxWidth="380px" key={'requestEdits'}>
					<Button
						key={'requestEdits'}
						onClick={() => onOpen()}
						icon={<PencilSimple size={16} />}
						size="full"
					>
						{t('requestEdits')}
					</Button>
				</Stack>,
			]}
			trackingKey="manager_mission_details"
		>
			<MissionDetail />
			<RequestEditsModal isOpen={isOpen} onClose={onClose} />
		</BasePage>
	);
};
